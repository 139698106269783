import React from "react";
import DataGrid from '../../Components/DataGridView/DataGridView';
import ScrollTop from '../ScrollButton';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import SearchEmployees from '../Employees/SearchEmployees';
import Dropdown from "../../Components/Dropdown";
import Messages from "../../Components/Messages";
import Checkbox from '../../Components/Checkbox';
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import Calendar from "../../Components/Calendar";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Print from "../../Print";

const $ = window.$;

export default class GeneralHolidayRpt extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDesktop: window.innerWidth > 1250,
            fromCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            toCode: !Util.checkISHR() ? localStorage.getItem("useridHR") : '',
            fromDate: new Date(new Date().getFullYear(), 0, 1),
            toDate: new Date(),
            workersOnly: true,
            fromSection: undefined,
            toSection: undefined,
            sectionsList: [],
            data: []

        };
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }

        if (Util.checkISHR()) {
            if (!Util.checkRights(719)) {
                Util.goTo('/Access');
                return;
            }
        }
        else if (Util.checkISManager()) {
            if (!Util.checkRights(664)) {
                Util.goTo('/Access');
                return;
            }
        }
        else {
            Util.goTo('/Access');
            return;
        }

        let _isDesktop = this.updatePredicate();
        let sectionsPromise = this.getSections();
        let sectionsList = await sectionsPromise;

        this.setState({
            sectionsList: sectionsList,
            fromSection: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            toSection: sectionsList && sectionsList.length > 0 ? sectionsList[0] : undefined,
            isDesktop: _isDesktop, loading: false
        })

    }
    updatePredicate = () => {
        return window.innerWidth > 1250
    }
    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    render = () => {
        const gridStyle = { minHeight: this.state.isDesktop ? '40vh' : '70vh' }
        let siteMapItems = [
            { label: $.strings.links.reports.menu },
            { label: $.strings.generalHolidayRpt.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            <SearchEmployees ref={e => this.dlgSearchFromEmployee = e} onSuccess={this.showFromEmp} />
            <SearchEmployees ref={e => this.dlgSearchToEmployee = e} onSuccess={this.showToEmp} />
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
                <ButtonsBarLookupList onRefresh={this.getData} onExcelExport={this.excelExport} onPrint={this.print} />
                <Messages innerRef={(el) => this.messages = el} style={{ paddingBottom: '10px' }} />
            </Card>

            <Accordion multiple activeIndex={[0, 1]} >
                <AccordionTab header={$.strings.filterCaption}>
                    <div className="p-grid">
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtFromCode = el}
                                type="text"
                                id="fromCode"
                                value={this.state.fromCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.generalHolidayRpt.fromCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getFromCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-6">
                            <Input
                                innerRef={(el) => this.txtToCode = el}
                                type="text"
                                id="toCode"
                                value={this.state.toCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.generalHolidayRpt.toCode}
                                withgroup={Util.checkISHR() || Util.checkISManager()}
                                btn1icon="search"
                                btn1event={this.getToCode}
                                disabled={!Util.checkISHR() && !Util.checkISManager()}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="fromDate"
                                innerRef={(el) => this.dtpFromDate = el}
                                value={this.state.fromDate}
                                caption={$.strings.generalHolidayRpt.fromDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-6 p-lg-1 p-sm-12">
                            <Calendar
                                id="toDate"
                                innerRef={(el) => this.dtpToDate = el}
                                value={this.state.toDate}
                                caption={$.strings.generalHolidayRpt.toDate}
                                onChange={this.handleUserInput}
                                readOnlyInput={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showHowHasVacation" caption={$.strings.generalHolidayRpt.workersOnly}
                                onChange={e => this.setState({ workersOnly: e.checked })} checked={this.state.workersOnly} />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="fromSection"
                                value={this.state.fromSection}
                                options={this.state.sectionsList}
                                caption={$.strings.generalHolidayRpt.fromSection}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-2 p-sm-12">
                            <Dropdown
                                id="toSection"
                                value={this.state.toSection}
                                options={this.state.sectionsList}
                                caption={$.strings.generalHolidayRpt.toSection}
                                onChange={this.handleUserInput}
                                optionLabel="Name"
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            <Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <DataGrid
                            ref={e => this.gridData = e}
                            isReport={true}
                            theme="default-light"
                            idProperty="serial"
                            style={gridStyle}
                            scheme={this.getScheme()}
                            exportFileName={$.strings.generalHolidayRpt.title}
                            dataSource={this.state.data}
                        />
                    </div>
                </div>
            </Card>

            <ScrollTop />
        </div>
    }

    getYearsList = () => {
        let yearsList = [];
        let currentYear = new Date().getFullYear();
        for (let i = currentYear - 5; i <= currentYear; i++) {
            yearsList.push({ id: i, name: i })
        }
        return yearsList;
    }

    getFromCode = async () => {
        await this.dlgSearchFromEmployee.show();
    }
    showFromEmp = (obj) => {
        if (obj) {
            this.setState({ fromCode: obj.code })
        }
    }
    getToCode = async () => {
        await this.dlgSearchToEmployee.show();
    }
    showToEmp = (obj) => {
        if (obj) {
            this.setState({ toCode: obj.code })
        }
    }

    getSections = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: localStorage.getItem("useridHR")
        }
        let res = await http.getJsonAxios(http.actions.Sections.urlGetSections, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }
        return dataTemp
    }

    getData = async () => {
        if (!Util.compareCode(this.state.fromCode, this.state.toCode)) {
            Util.showErrorMsg(this.messages, $.strings.compareCodeError)
            return;
        }
        
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            fromCode: this.state.fromCode || '',
            toCode: this.state.toCode || '',
            fromDate: this.state.fromDate && this.state.fromDate.length > 0 ? this.state.fromDate : new Date(new Date().getFullYear(), 0, 1),
            toDate: this.state.toDate && this.state.toDate.length > 0 ? this.state.toDate : new Date(),
            fromSection: this.state.fromSection && this.state.fromSection.ID >= 0 ? this.state.fromSection.ID : "-1",
            toSection: this.state.toSection && this.state.toSection.ID >= 0 ? this.state.toSection.ID : "-1",
            workerStaffOnly: this.state.workersOnly,
            isHR: Util.checkISHR(),
            userId: localStorage.getItem("useridHR") + ""
        }

        let res = await http.getJsonAxios(http.actions.HolidayPersonalRpt.urlGetGeneralHolidayRpt, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
            if (dataTemp && dataTemp.length > 0) {
                dataTemp.forEach(function (obj) {
                    obj.appDate = new Date(obj.appDate);
                });
            }
        }

        this.setState({ data: dataTemp, loading: false }, () => this.printFilters = this.state);
    }
    excelExport = () => {
        this.gridData.exportExcel();
    }

    print = async () => {

        if (!this.state.data || this.state.data.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }

        let obj = Print.getReportPrintObject($.strings.generalHolidayRpt.title, this.gridData, this.state.data);
        if (!obj || !obj.data || obj.data.columns.length <= 0) {
            Util.showErrorMsg(this.messages, $.strings.noRecordsToPrint)
            return;
        }
        let listOfFilters = [];
        if (this.printFilters) {
            let codeFilter = "", dateFilter = "";
            // Code Filter
            if (this.printFilters.fromCode) {
                codeFilter = $.strings.generalHolidayRpt.fromCode + ": " + this.printFilters.fromCode;
            }
            if (this.printFilters.toCode) {
                if (codeFilter !== "")
                    codeFilter += " - ";
                codeFilter += $.strings.generalHolidayRpt.toCode + ": " + this.printFilters.toCode;
            }
            if (codeFilter !== "") {
                listOfFilters.push(codeFilter);
            }

            // Date Filter
            if (this.printFilters.fromDate) {
                dateFilter = $.strings.generalHolidayRpt.fromDate + ": " + Util.getDateFormat(this.printFilters.fromDate);
            }
            if (this.printFilters.toDate) {
                if (dateFilter !== "")
                    dateFilter += " - ";
                else
                    dateFilter = $.strings.generalHolidayRpt.fromDate + ": ";
                dateFilter += Util.getDateFormat(this.printFilters.toDate);
            }
            if (dateFilter !== "")
                listOfFilters.push(dateFilter)

            if (this.printFilters.workersOnly) {
                listOfFilters.push($.strings.generalHolidayRpt.workersOnly + ": " + (this.printFilters.workersOnly ? $.strings.yes : $.strings.no));
            }

            if (this.printFilters.fromSection) {
                listOfFilters.push($.strings.generalHolidayRpt.fromSection + ": " + this.printFilters.fromSection.Name);
            }
            if (this.printFilters.toSection) {
                listOfFilters.push($.strings.generalHolidayRpt.toSection + ": " + this.printFilters.toSection.Name);
            }
        }
        obj.headers = Print.getPrintingHeaderStrings(listOfFilters, obj.headers)

        Print.doPrintReport(obj);
    };

    getScheme = () => {
        return {
            name: "generalHolidaysRptScheme_Table",
            filter: true,
            showFooter: true,
            sortable: true,
            allowGrouping: false,
            responsiveColumnIndex: 2,
            columns: [
                { name: 'rowNumber', header: '##', width: 80, dataType: 'Number', aggregate: 'Cnt', columnWidth: '1' },
                { name: 'empCode', header: $.strings.generalHolidayRpt.empCode, width: 120, columnWidth: '2' },
                { name: 'empName', header: $.strings.generalHolidayRpt.empName, width: 250, columnWidth: '*' },
                { name: 'appDate', header: $.strings.generalHolidayRpt.appDate, width: 120, columnWidth: '3',dataType: 'Date', format: Util.gridDateFormat() },
                { name: 'previousBalance', header: $.strings.generalHolidayRpt.previousBalance, width: 120, columnWidth: '2' },
                { name: 'yearMaturity', header: $.strings.generalHolidayRpt.yearMaturity, width: 120, columnWidth: '2' },
                { name: 'daysNo', header: $.strings.generalHolidayRpt.daysNo, width: 120, columnWidth: '2' },
                { name: 'dueDays', header: $.strings.generalHolidayRpt.dueDays, width: 120, columnWidth: '2' },
                { name: 'takenDays', header: $.strings.generalHolidayRpt.takenDays, width: 120, columnWidth: '2' },
                { name: 'currentBalance', header: $.strings.generalHolidayRpt.currentBalance, width: 120, columnWidth: '2' },
                { name: 'yearBalance', header: $.strings.generalHolidayRpt.yearBalance, width: 120, columnWidth: '2' },
                { name: 'overtimeBalance', header: $.strings.generalHolidayRpt.overtimeBalance, width: 155, columnWidth: '2' }
            ]
        }
    }
}