import React from "react";
import styles from "./AttendanceTrans.module.scss";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import InputTextArea from "../../Components/InputTextArea";
import InputTime from "../../Components/InputTime/InputTime";
import { Menu } from "primereact/menu";
import AlertBox from "../../Components/AlertBox";

const $ = window.$;

export default class AttendanceTrans extends React.Component {

    constructor(props) {
        super(props);
        this.periodsList = Util.getPeriodList();
        this.sickPercentList = [];
        this.fromTime = new Date();
        this.fromTime.setHours(8, 0, 0)
        this.toTime = new Date();
        this.toTime.setHours(16, 0, 0)
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: {},
            isDesktop: window.innerWidth > 800,
            visibleDialog: false,
            empCode: localStorage.getItem("useridHR"),
            transTypesList: [],
            subTypesList: [],

            dataObject: {
                code: undefined,
                empCode: undefined,
                empName: undefined,
                transType: undefined,
                subType: undefined,
                overTimePercent: 150,
                onYear: new Date().getFullYear(),
                period: this.periodsList[0],
                fromDate: new Date(),
                toDate: new Date(),
                calcDate: new Date(),
                fromTime: this.fromTime,
                toTime: this.toTime,
                daysCount: 1,
                hoursCount: '08:00',
                annualBalance: '',
                currentBalance: '',
                sickVacationBalance: '',
                overTimeBalance: '',
                empClass: '',
                annualPeriodBalance1: '',
                specialLeaveBalance: '',
                sickTakenBalance: 0,
                notes: '',
                fileName: '',
                fileData: null
            }
        }
    }

    componentDidMount = async () => {
        window.addEventListener("resize", this.updatePredicate)
        this.setState({ loading: false })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    };

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }
    handleSickPercent = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value, overTimePercent: value.id } })
    }
    handleTransType = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let period = this.state.dataObject.period;
        let sickVacationPercent = 0;
        let daysCount = 1;
        if (value.pos === 3 || value.pos === 4 || value.pos === 6 || value.pos === 7 || value.pos === 8 || value.pos === 9) {
            period = this.periodsList[1];
            if (value.pos === 6) {
                sickVacationPercent = this.calculateSickVacationPercent();
                sickVacationPercent = this.sickPercentList.find(e => e.id === sickVacationPercent);
            }
        }
        else if (value.pos === 10 || value.pos === 11) {
            period = this.periodsList[3];
        }
        else {
            period = this.periodsList[0];
        }

        this.setState({
            dataObject: {
                ...this.state.dataObject, [name]: value, period: period,
                sickPercent: value.pos === 6 ? sickVacationPercent : this.state.dataObject.sickPercent,
                overTimePercent: value.pos === 6 ? sickVacationPercent.id : this.state.dataObject.sickPercent,
                daysCount: daysCount
            }
        },
            () => {
                this.calculateDaysNo()
            })
    }

    calculateSickVacationPercent = () => {
        let result = 0;
        let takenBalanceWithRequestDays = this.state.dataObject.sickTakenBalance + this.state.dataObject.daysCount;
        if (this.sickPercentList[0].id >= takenBalanceWithRequestDays)
            result = this.sickPercentList[0].id; // نسبة 1

        if ((this.sickPercentList[0].id + this.sickPercentList[1].id) >= takenBalanceWithRequestDays)
            result = this.sickPercentList[1].id; // نسبة 2

        if ((this.sickPercentList[0].id + this.sickPercentList[1].id + this.sickPercentList[2].id) >= takenBalanceWithRequestDays)
            result = this.sickPercentList[2].id; // نسبة 3

        if (takenBalanceWithRequestDays > (this.sickPercentList[0].id + this.sickPercentList[1].id + this.sickPercentList[2].id + this.sickPercentList[3].id))
            result = 100;

        return result;
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;
        let result = true;

        let empCode = this.state.dataObject.empCode;
        fieldValidationErrors.empCode = "";
        if (!this.state.dataObject.empCode) {
            result = false;
            fieldValidationErrors.empCode = $.strings.requiredFiled;
        }

        fieldValidationErrors.empName = "";
        if (!this.state.dataObject.empName) {
            result = false;
            fieldValidationErrors.empName = $.strings.requiredFiled;
        }

        let transType = this.state.dataObject.transType;
        fieldValidationErrors.transType = "";
        if (!transType) {
            result = false;
            fieldValidationErrors.transType = $.strings.requiredFiled;
        }

        fieldValidationErrors.subType = "";
        if (transType && transType.pos === 8) {
            if (!this.state.dataObject.subType || this.state.dataObject.subType.pos < 0) {
                fieldValidationErrors.subType = $.strings.requiredFiled;
                result = false;
            }
        }
        fieldValidationErrors.overTimePercent = "";
        if (transType && transType.pos === 5) {
            if (!this.state.dataObject.overTimePercent || this.state.dataObject.overTimePercent <= 0) {
                fieldValidationErrors.overTimePercent = $.strings.requiredFiled;
                result = false;
            }
        }
        fieldValidationErrors.onYear = "";
        if (transType && (this.state.dataObject.transType.pos === 7 ||
            this.state.dataObject.transType.pos === 11 || this.state.dataObject.transType.pos === 17)) {
            if (!this.state.dataObject.onYear || this.state.dataObject.onYear <= 2020) {
                fieldValidationErrors.onYear = $.strings.requiredFiled;
                result = false;
            }
        }

        let _fromDate = this.state.dataObject.fromDate;
        fieldValidationErrors.fromDate = "";
        if (!this.state.dataObject.fromDate) {
            result = false;
            fieldValidationErrors.fromDate = $.strings.requiredFiled;
        }

        fieldValidationErrors.toDate = "";
        if (!this.state.dataObject.toDate) {
            result = false;
            fieldValidationErrors.toDate = $.strings.requiredFiled;
        }

        fieldValidationErrors.calcDate = "";
        if (!this.state.dataObject.calcDate) {
            result = false;
            fieldValidationErrors.calcDate = $.strings.requiredFiled;
        }

        fieldValidationErrors.fromTime = "";
        if (!this.state.dataObject.fromTime) {
            result = false;
            fieldValidationErrors.fromTime = $.strings.requiredFiled;
        }

        let _toTime = this.state.dataObject.toTime
        fieldValidationErrors.toTime = _toTime ? '' : $.strings.requiredFiled;

        fieldValidationErrors.overTimePercent = "";
        if (this.state.dataObject.transType && (this.state.dataObject.transType.pos === 5 ||
            this.state.dataObject.transType.pos === 6 || this.state.dataObject.transType.pos === 18)) {
            if (!this.state.dataObject.overTimePercent) {
                result = false;
                fieldValidationErrors.overTimePercent = $.strings.requiredFiled;
            }

        }

        if ((this.state.dataObject.daysCount + "").includes('-')) {
            Util.showErrorMsg(this.messages, $.strings.attendanceTrans.daysCountError)
            result = false;
        }

        if (!this.state.dataObject.transType.has_right) {
            result = false;
            Util.showErrorMsg(this.messages, $.strings.attendanceTrans.noRights)
        }

        if (!result)
            this.setState({ formErrors: fieldValidationErrors });

        return result;
    }

    render() {
        const buttonsBar = (
            <div className="p-grid">
                <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6">
                    <Button tooltip={$.strings.new} icon="pi pi-plus" onClick={this.onNew}>
                    </Button>
                    <Button tooltip={$.strings.save} icon="pi pi-save" className="p-button-success" onClick={e => this.onSave(e, false, true, true, true)}>
                    </Button>
                    <Button onClick={this.showSplitButtonsDot} tooltip={$.strings.more} icon="pi pi-bars" />
                </div>
                <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-6" style={{ direction: $.strings.dir === "rtl" ? 'ltr' : 'rtl' }}>

                    <Button tooltip={$.strings.cancel} icon="pi pi-times" className="p-button-danger" onClick={this.onHide}>
                    </Button>

                    <Button tooltip={$.strings.saveAndClose} icon="pi pi-check" className="p-button-success" onClick={e => this.onSave(e, true, true, true, true)}>
                    </Button>

                </div>
            </div>
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>

                <Dialog header={$.strings.attendanceTrans.title} icons={myIcon} visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '1000px' : '85vw', direction: $.strings.dir }}
                    modal onHide={this.onHide}>
                    <Input id="file-upload" type="file" accept="image/*" onChange={this.handleImageChange} />
                    <ProgressSpinner loading={this.state.loading} />
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                {buttonsBar}
                            </div>
                        </div>
                        <div className="p-grid" style={{ width: '100%' }}>
                            <div className="p-lg-12 p-sm-12" style={{ width: '100%' }}>
                                <Messages innerRef={(el) => this.messages = el} />
                            </div>
                        </div>
                        {
                            this.state.alertBoxVisible &&
                            <AlertBox ref={e => this.alertBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.Yes} yesCaption={$.strings.ok} />
                        }
                        <Menu model={this.splitButtonItemsDot().filter((element) => element.visible === true)} popup={true} ref={(ref) => (this.menuDot = ref)} />
                        <div className="p-grid">
                            {/* right side*/}
                            <div className="p-col-12 p-xl-9 p-lg-9 p-md-12 p-sm-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                        <Input
                                            innerRef={(el) => this.txtCode = el}
                                            id="code"
                                            maxLength="16"
                                            value={this.state.dataObject.code || ''}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.code}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">

                                    </div>
                                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                        <Input
                                            innerRef={(el) => this.txtClassification = el}
                                            id="empClass"
                                            value={this.state.dataObject.empClass || ''}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.classification}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                        <Input
                                            id="empCode"
                                            maxLength="16"
                                            value={this.state.dataObject.empCode}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.empCode}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <Input
                                            id="empName"
                                            value={this.state.dataObject.empName}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.empName}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-2 p-lg-2 p-md-6 p-sm-12">
                                        <Input
                                            id="age"
                                            maxLength="16"
                                            value={this.state.dataObject.age}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.age}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Dropdown
                                            id="transType"
                                            autoFocus
                                            value={this.state.dataObject.transType}
                                            options={this.state.transTypesList}
                                            caption={$.strings.attendanceTrans.transType}
                                            onChange={this.handleTransType}
                                            optionLabel="name"
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            filter
                                        />
                                    </div>
                                    {
                                        this.state.dataObject.transType && this.state.dataObject.transType.pos === 8 &&
                                        <div className="p-col-12 p-lg-4 p-sm-12">
                                            <Dropdown
                                                id="subType"
                                                value={this.state.dataObject.subType}
                                                options={this.state.subTypesList}
                                                caption={$.strings.attendanceTrans.subType}
                                                onChange={this.handleUserInput}
                                                optionLabel="name"
                                                filter
                                                filterBy="name"
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.dataObject.transType && (this.state.dataObject.transType.pos === 5 ||
                                            this.state.dataObject.transType.pos === 6 || this.state.dataObject.transType.pos === 18) &&
                                        <div className="p-col-12 p-lg-4 p-sm-12">
                                            <Input
                                                id="overTimePercent"
                                                keyfilter="pnum"
                                                value={this.state.dataObject.overTimePercent}
                                                autocomplete="off"
                                                caption={this.state.dataObject.transType.pos === 5 ? $.strings.attendanceTrans.overTimePercent : $.strings.attendanceTrans.discountPercent}
                                                onChange={this.handleUserInput}
                                                isRequired={true}
                                                formErrors={this.state.formErrors}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.dataObject.transType && this.state.dataObject.transType.pos === 6 &&
                                        <div className="p-col-12 p-lg-4 p-sm-12">
                                            <Dropdown
                                                id="sickPercent"
                                                value={this.state.dataObject.sickPercent}
                                                options={this.sickPercentList}
                                                caption={$.strings.attendanceTrans.sickPercent}
                                                onChange={this.handleSickPercent}
                                                optionLabel="name"
                                                isRequired={true}
                                                formErrors={this.state.formErrors}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.dataObject.transType && (this.state.dataObject.transType.pos === 7 ||
                                            this.state.dataObject.transType.pos === 11 || this.state.dataObject.transType.pos === 17) &&
                                        <div className="p-col-12 p-lg-4 p-sm-12">
                                            <Input
                                                id="onYear"
                                                keyfilter="pnum"
                                                value={this.state.dataObject.onYear}
                                                autocomplete="off"
                                                caption={$.strings.attendanceTrans.onYear}
                                                onChange={this.handleUserInput}
                                                isRequired={true}
                                                formErrors={this.state.formErrors}
                                            />
                                        </div>
                                    }
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Dropdown
                                            id="period"
                                            value={this.state.dataObject.period}
                                            options={this.periodsList}
                                            caption={$.strings.attendanceTrans.period}
                                            onChange={this.handlePeriod}
                                            optionLabel="name"
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Calendar
                                            id="fromDate"
                                            innerRef={(el) => this.dtpFromDate = el}
                                            value={this.state.dataObject.fromDate}
                                            caption={$.strings.attendanceTrans.fromDate}
                                            onChange={this.handleFromDate}
                                            readOnlyInput={true}
                                            readOnly={true}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Calendar
                                            id="toDate"
                                            innerRef={(el) => this.dtpToDate = el}
                                            value={this.state.dataObject.toDate}
                                            caption={$.strings.attendanceTrans.toDate}
                                            onChange={this.handleToDate}
                                            readOnlyInput={true}
                                            readOnly={true}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            disabled={this.state.dataObject.period && (this.state.dataObject.period.id === 2 || this.state.dataObject.period.id === 3) ? false : true}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Input
                                            id="daysCount"
                                            keyfilter="pnum"
                                            value={this.state.dataObject.daysCount}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.daysCount}
                                            onChange={this.handleUserInput}
                                            disabled={!this.state.dataObject.period || this.state.dataObject.period.id + '' !== '3'}
                                        // disabled={(this.state.dataObject.transType && this.state.dataObject.transType.pos !== 10 &&
                                        //     this.state.dataObject.transType.pos !== 11) || !this.state.dataObject.transType}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <InputTime
                                            id="fromTime"
                                            value={this.state.dataObject.fromTime}
                                            caption={$.strings.attendanceTrans.fromTime}
                                            valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.value } }, () => this.calculateHoursNo())}
                                            isRequired={true}
                                            enabled={this.state.dataObject.period.id !== 3}
                                            formErrors={this.state.formErrors}
                                            isDisabled={this.state.dataObject.period.id === 3}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <InputTime
                                            id="toTime"
                                            value={this.state.dataObject.toTime}
                                            caption={$.strings.attendanceTrans.toTime}
                                            valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, toTime: e.value } }, () => this.calculateHoursNo())}
                                            enabled={this.state.dataObject.period.id !== 3}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                            isDisabled={this.state.dataObject.period.id === 3}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <InputTime
                                            id="hoursCount"
                                            value={this.state.dataObject.hoursCount}
                                            caption={$.strings.attendanceTrans.hoursCount}
                                            valueChanged={e => this.setState({ dataObject: { ...this.state.dataObject, hoursCount: e.value } }, () => this.calculateHoursNo())}
                                            isDisabled={this.state.dataObject.period.id !== 3}
                                        />
                                    </div>
                                    <div className="p-col-12 p-lg-4 p-sm-12">
                                        <Calendar
                                            id="calcDate"
                                            innerRef={(el) => this.dtpCalcDate = el}
                                            value={this.state.dataObject.calcDate}
                                            caption={$.strings.attendanceTrans.calcDate}
                                            onChange={this.handleUserInput}
                                            readOnlyInput={true}
                                            readOnly={true}
                                            isRequired={true}
                                            formErrors={this.state.formErrors}
                                        />
                                    </div>
                                </div>
                                <div className="p-grid">
                                    <div className="p-col-12 p-md-12">
                                        <InputTextArea
                                            innerRef={ref => this.txtNotes = ref}
                                            type="text"
                                            id="notes"
                                            maxLength="80"
                                            value={this.state.dataObject.notes}
                                            caption={$.strings.note}
                                            onChange={this.handleUserInput}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* left side*/}
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-12 p-sm-12">
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="currentBalance"
                                            value={this.state.dataObject.currentBalance}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.currentBalance}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="annualBalance"
                                            value={this.state.dataObject.annualBalance}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.annualBalance}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="annualPeriodBalance1e"
                                            value={this.state.dataObject.annualPeriodBalance1}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.annualPeriodBalance1e}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="sickVacationBalance"
                                            value={this.state.dataObject.sickVacationBalance}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.sickVacationBalance}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="overTimeBalance"
                                            value={this.state.dataObject.overTimeBalance}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.overTimeBalance}
                                            disabled
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                        <Input
                                            id="specialLeaveBalance"
                                            value={this.state.dataObject.specialLeaveBalance}
                                            autocomplete="off"
                                            caption={$.strings.attendanceTrans.specialLeaveBalance}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </Dialog >
            </div >
        );
    }
    splitButtonItemsDot = () => {
        let items = [
            {
                label: (
                    <Button label={$.strings.attendanceTrans.addAttach} onClick={this.onAddAttach} className={styles.menuItem} />
                ),
                visible: true
            },
            {
                label: (
                    <Button label={$.strings.attendanceTrans.viewAttach} onClick={this.onViewAttach} className={styles.menuItem} />
                ),
                visible: this.state.dataObject.fileData ? true : false
            },
            {
                label: (
                    <Button label={$.strings.attendanceTrans.deleteAttach} onClick={() => this.onDeleteAttach(true)} className={styles.menuItem} />
                ),
                visible: this.state.dataObject.fileData ? true : false
            },
        ]

        return items
    }
    showSplitButtonsDot = (event) => {
        this.menuDot.toggle(event);
    }
    onAddAttach = (e) => {
        document.getElementById('file-upload').click();
    }
    onViewAttach = () => {

        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = "data:application/octet-stream;base64," + this.state.dataObject.fileData;
        downloadLink.target = '_blank';
        downloadLink.setAttribute('download', this.state.dataObject.fileName)
        downloadLink.download = this.state.dataObject.fileName;
        downloadLink.click();
        this.setState({ loading: false })
    }
    onDeleteAttach = async (showMsg) => {
        if (showMsg) {
            Util.confirmDialog($.strings.attendanceTrans.attachDeleteConfirm, 'pi pi-question-circle',
                async () => await this.onDeleteAttach(false),
                () => { }
            );
            return;
        }

        let obj = {
            code: this.state.dataObject.code,
            fileName: this.state.dataObject.fileName
        }
        let res = await http.getJsonAxios(http.actions.Absence.urlDeleteAbsenceTransAttach, obj, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                Util.showSuccessMsg(this.messages);
                this.setState({ dataObject: { ...this.state.dataObject, fileName: '', fileData: null } })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.errorText)
                    msg = res.data.errorText;
                Util.showErrorMsg(this.messages, msg);
            }
        }
        else {
            Util.showErrorMsg(this.messages)
        }
    }
    handleImageChange = (event) => {
        this.setState(
            {
                dataObject: {
                    ...this.state.dataObject,
                    fileName: event.target.files[0].name,
                    fileData: event.target.files[0],
                },
            },
            () => {
                event.target.value = '';
            }
        );
    }


    handlePeriod = (e) => {
        let period = e.target.value;
        let daysNo = 1;
        this.setState({
            dataObject: {
                ...this.state.dataObject, period: e.target.value, daysCount: daysNo,
                toDate: period.id !== 2 ? this.state.dataObject.fromDate : this.state.dataObject.toDate
            }
        }, () => this.calculateDaysNo());
    }
    handleFromDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromDate: e.target.value } }, () => this.setToDate())
    }
    setToDate = () => {
        if (this.state.dataObject.period.id !== 2) {
            this.setState({ dataObject: { ...this.state.dataObject, toDate: this.state.dataObject.fromDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo()
        }
    }
    handleToDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toDate: e.target.value } }, () => this.setFromDate())
    }
    setFromDate = () => {
        if (this.state.dataObject.period.id !== 2) {
            this.setState({ dataObject: { ...this.state.dataObject, fromDate: this.state.dataObject.toDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo()
        }
    }
    calculateDaysNo = () => {
        try {
            let fromDate = this.state.dataObject.fromDate
            let toDate = this.state.dataObject.toDate

            const _fromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
            const _toDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 0, 0, 0);
            let temp = 1000 * 3600 * 24;

            let daysNo = Math.floor((_toDate - _fromDate) / temp);

            daysNo += 1;

            if (this.state.dataObject.period.id === 0) {
                daysNo = 0;
                this.calculateHoursNo();
            }
            else if (this.state.dataObject.period.id === 1) {
                daysNo = 1;
                this.setState({ dataObject: { ...this.state.dataObject, daysNo: daysNo } }, async () => await this.getVacationDaysCount())
            }
            else {
                this.setState({ dataObject: { ...this.state.dataObject, daysNo: daysNo === 0 ? 1 : daysNo } }, async () => await this.getVacationDaysCount())
            }
        }
        catch (e) {

        }
    }

    getVacationDaysCount = async () => {
        let daysCount = this.state.dataObject.daysCount;
        if (!this.state.dataObject.transType)
            return;
        this.setState({ loading: true })
        let obj = {
            empCode: this.state.dataObject.empCode,
            reason: this.state.dataObject.transType.pos,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate
        }

        let period = this.state.dataObject.period
        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlGetVacationDaysCount, obj, 0);

        if (res.status === 200) {
            if (res.data && res.data.success) {
                daysCount = res.data.daysCount;
                if (daysCount > 1 && this.state.dataObject && (this.state.dataObject.id + '' === '0' || this.state.dataObject.id + '' === '1')) {
                    period = this.periodsList[2]
                }
                if (res.data && res.data.errorText) {
                    Util.showErrorMsg(this.messages, res.data.errorText);
                }
            }
        }

        this.setState({ dataObject: { ...this.state.dataObject, daysCount: daysCount, period: period }, loading: false })
    }

    handleFromTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromTime: e.target.value } }, () => this.calculateHoursNo())
    }
    setToTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let toTimeTemp = Util.getCurrentTimePlusHour(this.state.dataObject.fromTime)
            this.setState({ dataObject: { ...this.state.dataObject, toTime: toTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }
    handleToTime = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toTime: e.target.value } }, () => this.calculateHoursNo())
    }
    setFromTime = () => {
        if (this.state.dataObject.toTime <= this.state.dataObject.fromTime) {
            let fromTimeTemp = Util.getCurrentTimeMinusHour(this.state.dataObject.toTime)
            this.setState({ dataObject: { ...this.state.dataObject, fromTime: fromTimeTemp } }, () => this.calculateHoursNo())
        }
        else {
            this.calculateHoursNo()
        }
    }
    calculateHoursNo = () => {
        try {
            let fromDate = this.state.dataObject.fromTime;
            let toDate = this.state.dataObject.toTime;

            var hoursNo = ((new Date(1, 1, 1, toDate.getHours(), toDate.getMinutes(), 0) -
                new Date(1, 1, 1, fromDate.getHours(), fromDate.getMinutes(), 0)) / 60000);
            let obj = Util.getNumberByTimeFormat(hoursNo);
            let hours = obj.hours + "";
            if (hours.length === 1)
                hours = "0" + hours;

            let minutes = obj.minutes + "";
            if (minutes.length === 1)
                minutes = "0" + minutes;

            let result = hours + ":" + minutes;


            this.setState({ dataObject: { ...this.state.dataObject, hoursNo: result } })
        }
        catch (e) {

        }
    }
    getAttendanceTransTypes = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 22
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }
    getPaidVacations = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }
    show = async (transId, empCode, empName, fromDate, toDate) => {
        let transTypesList = await this.getAttendanceTransTypes();
        let subTypesList = await this.getPaidVacations();
        if (!fromDate) {
            fromDate = new Date();
        }
        if (!toDate) {
            toDate = new Date();
        }
        let objInfo = await this.getEmployeeInfo(empCode);
        if (objInfo) {
            this.sickPercentList = objInfo.list;
        }
        this.setState({
            transTypesList: transTypesList, subTypesList: subTypesList, visibleDialog: true,
            dataObject: {
                ...this.state.dataObject, empCode: empCode, empName: empName, fromDate: fromDate, toDate: toDate,
                age: objInfo.age, annualBalance: objInfo.annualBalance, currentBalance: objInfo.currentBalance, overTimeBalance: objInfo.overTimeBalance,
                sickVacationBalance: objInfo.sickVacationBalance, empClass: objInfo.empClass, annualPeriodBalance1: objInfo.annualPeriodBalance1,
                specialLeaveBalance: objInfo.specialLeaveBalance, sickTakenBalance: objInfo.sickTakenBalance
            }, formErrors: {}, loading: false
        }, async () => {
            setTimeout(() => { this.txtCode.focus() }, 10);
            await this.getData(transId);
        });
    }

    onHide = () => {
        this.setState({
            dataObject: {
                code: undefined,
                empCode: undefined,
                empName: undefined,
                transType: undefined,
                subType: undefined,
                overTimePercent: 150,
                onYear: new Date().getFullYear(),
                period: this.periodsList[0],
                fromDate: new Date(),
                toDate: new Date(),
                calcDate: new Date(),
                fromTime: this.fromTime,
                toTime: this.toTime,
                daysCount: 1,
                hoursCount: '08:00',
                annualBalance: '',
                currentBalance: '',
                sickVacationBalance: '',
                overTimeBalance: '',
                empClass: '',
                annualPeriodBalance1: '',
                specialLeaveBalance: '',
                sickTakenBalance: 0,
                notes: '',
                fileName: '',
                fileData: null
            },
            transTypesList: [], subTypesList: [], visibleDialog: false,
            formErrors: {}, loading: false
        })
        if (this.props.onClose)
            this.props.onClose();
    }

    checkHaveAbsence = async () => {
        let objResult = { haveAbsence: false, message: '' }
        let obj = {
            empCode: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            periodType: this.state.dataObject.period.id,
            reason: this.state.dataObject.transType.pos,
            fromTime: this.state.dataObject.fromTime,
            toTime: this.state.dataObject.toTime,
            userId: localStorage.getItem("useridHR"),
        }

        let res = await http.postMultipartAxios(http.actions.Absence.urlCheckHasAbsence, obj, 0);
        if (res.status === 200) {
            if (res.data && !res.data.success) {
                objResult.haveAbsence = true;
                objResult.message = res.data.errorText;
            }
        }
        return objResult;
    }

    onSave = async (e, saveAndClose, checkOverTimePercent, checkHaveAbsence, checkShiftVacationDays) => {
        if (!this.validateField()) {
            return;
        }

        if (checkOverTimePercent) {
            if (this.state.dataObject.transType.pos === 18 && this.state.dataObject.overTimePercent > 0) {
                Util.confirmDialog($.strings.attendanceTrans.overTimePercentConfirm, 'pi pi-question-circle',
                    async () => await this.onSave(e, saveAndClose, false, checkHaveAbsence, checkShiftVacationDays),
                    () => { }
                );
                return;
            }
        }
        if (checkHaveAbsence) {
            let objResult = await this.checkHaveAbsence();
            if (objResult.haveAbsence) {
                Util.confirmDialog(objResult.message, 'pi pi-question-circle',
                    async () => await this.onSave(e, saveAndClose, false, false, false, checkShiftVacationDays),
                    () => { }
                );
                return;
            }
        }

        if (checkShiftVacationDays && this.state.dataObject.transType.pos === 7) {
            // فحص عدد أيام اجازة الفترة
            let objResult = await this.checkPeriodVacationDays(this.state.dataObject.daysCount, this.state.dataObject.empCode, this.state.dataObject.fromDate);
            if (objResult.result) {
                this.popupHasCalled();
                this.setState({ loading: false, alertBoxVisible: true }, () => {
                    this.alertBox.show(
                        async () => {
                            this.popupHasClosed();
                            let res = await this.onSave(e, false, false, false, false);
                            this.setState({ alertBoxVisible: false });
                            return res;
                        },
                        undefined, undefined,
                        objResult.errorText
                    );
                })
                return;
            }
        }

        this.messages.clear();
        this.setState({ loading: true });

        let obj = {
            code: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.fromDate,
            serial: this.state.dataObject.code ? this.state.dataObject.code : "",
            toDate: this.state.dataObject.toDate,
            doDate: this.state.dataObject.calcDate,
            fromTime: this.state.dataObject.fromTime,
            toTime: this.state.dataObject.toTime,
            reason: this.state.dataObject.transType.pos,
            leavType: this.state.dataObject.subType ? this.state.dataObject.subType.pos : 0,
            oVal: this.state.dataObject.overTimePercent,
            year: this.state.dataObject.onYear,
            rem: this.state.dataObject.notes ? this.state.dataObject.notes : '',
            userId: localStorage.getItem("useridHR"),
            perType: this.state.dataObject.period.id,
        }

        let res = await http.postMultipartAxios(http.actions.Absence.urlSaveAbsenceTrans, obj, this.state.dataObject.fileData, [], 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.daysChanged) {
                    Util.showInfoMsg(this.messages, res.data.message);
                }
                else {
                    if (res.data.message)
                        Util.showSuccessMsg(this.messages, res.data.message);
                    else
                        Util.showSuccessMsg(this.messages);
                }
                if (saveAndClose) {
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                    this.onHide();
                }
                else {
                    this.resetFields()
                    this.setState({ loading: false })
                }
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data.message + "" !== "") {
                    msg = res.data.message;
                }
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false });
            }
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data.message + "" !== "") {
                msg = res.data.message;
            }
            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false });
        }
    }

    checkPeriodVacationDays = async (daysCount, empCode, fromDate) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            fromDate: fromDate,
            daysCount: daysCount,
            empCode: empCode,
        }
        let res = await http.postMultipartAxios(http.actions.HandleRequests.urlCheckPeriodVacationDays, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }

    
    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    resetFields = () => {
        let obj = {
            ...this.state.dataObject,
            code: undefined,
            // empCode: undefined,
            // empName: undefined,
            transType: undefined,
            subType: undefined,
            overTimePercent: 150,
            onYear: new Date().getFullYear(),
            period: this.periodsList[0],
            fromDate: new Date(),
            toDate: new Date(),
            calcDate: new Date(),
            fromTime: this.fromTime,
            toTime: this.toTime,
            daysCount: 1,
            hoursCount: '08:00',
            annualBalance: '',
            currentBalance: '',
            sickVacationBalance: '',
            overTimeBalance: '',
            empClass: '',
            annualPeriodBalance1: '',
            specialLeaveBalance: '',
            notes: '',
            fileName: '',
            fileData: null
        }
        this.setState({ dataObject: obj });
    }
    onNew = () => {
        this.messages.clear();
        this.resetFields();
    }
    getData = async (id) => {
        if (!id || id <= 0) {
            return;
        }
        this.setState({ loading: true });
        let objTrans = undefined;
        let obj = {
            vch: id
        }
        let res = await http.getJsonAxios(http.actions.Absence.urlGetAbsenceTrans, obj, 0);
        if (res.status === 200) {
            objTrans = res.data.absentObj;
        }
        if (objTrans) {
            let transType = this.state.transTypesList.find(element => element.pos + "" === objTrans.reason + "");
            let subType = this.state.subTypesList.find(element => element.pos + "" === objTrans.leav_type + "");
            let fromTime = new Date(this.fromTime.toLocaleDateString() + ' ' + objTrans.f_time);
            let toTime = new Date(this.toTime.toLocaleDateString() + ' ' + objTrans.t_time);
            let sickPercent = this.sickPercentList.find(e => e.id + '' === objTrans.o_val + '');

            this.setState({
                dataObject: {
                    code: objTrans.vch,
                    empCode: objTrans.code,
                    empName: objTrans.empName,
                    transType: transType,
                    subType: subType,
                    overTimePercent: objTrans.o_val,
                    sickPercent: sickPercent,
                    onYear: objTrans.year,
                    period: this.periodsList.find(e => e.id + "" === objTrans.per_type + ""),
                    fromDate: new Date(objTrans.f_date),
                    toDate: new Date(objTrans.t_date),
                    calcDate: new Date(objTrans.r_date),
                    fromTime: fromTime,
                    toTime: toTime,
                    daysCount: objTrans.days,
                    hoursCount: objTrans.time,
                    notes: objTrans.rem,
                    fileName: objTrans.attach,
                    fileData: res.data.fileData
                }
                , loading: false
            })
        }
    }
    getEmployeeInfo = async (empCode) => {
        this.setState({ loading: true });
        let objTrans = { dob: undefined, age: undefined, annualBalance: undefined };
        let obj = {
            empCode: Util.encryptStringToHexa(empCode)
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmpInfoForAttendanceTrans, obj, 0);
        if (res.status === 200) {
            objTrans = res.data;
        }
        return objTrans;
    }
}