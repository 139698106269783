import React from "react";
import Input from "../../Components/Input";
import Button from '../../Components/Button'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import Dialog from '../../Components/Dialog';
import Util from '../../Util'
import ClockTransEdit from "../ClockTrans/ClockTransEdit";
import Dropdown from "../../Components/Dropdown";
import Calendar from "../../Components/Calendar";
import AlertBox from "../../Components/AlertBox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Approval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mode: 'new',
            formErrors: { id: '', notes: '' },
            isDesktop: window.innerWidth > 800,
            visibleDialog: false,
            acceptedType: undefined,
            acceptedSubType: undefined,
            mainTypesList: [],
            subTypesList: [],
            dataObject: {

            }
        }
    }

    updatePredicate = () => {
        this.setState({ isDesktop: window.innerWidth > 800 });
    }

    componentDidMount = async () => {

        window.addEventListener("resize", this.updatePredicate)
        this.setState({ loading: false })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate)
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    handleAcceptedSubType = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value });
    }

    handleUserInputAcceptedType = async (e) => {
        let subTypesList = [];
        let age = undefined, empClass = undefined, yearBalance = undefined, currentBalance = undefined, overTimeBalance = undefined;
        if (Util.checkISHR() && this.state.dataObject.Type + "" === "1" && (e.target.value.pos + "" === "6" || e.target.value.pos + "" === "7")) {
            let VacBalacnes = await this.getVacationsBalances(Util.encryptStringToHexa(this.state.dataObject.EmpCode));
            let overTimeBalances = await this.getOverTimeBalance(Util.encryptStringToHexa(this.state.dataObject.EmpCode));
            let objEmpInfo = await this.getEmployeeInfo(this.state.dataObject.EmpCode);
            age = objEmpInfo.age ? objEmpInfo.age : "";
            empClass = objEmpInfo.age ? objEmpInfo.empClass : "";

            if (e.target.value.pos + "" === "7") {
                yearBalance = VacBalacnes.yearlyBalance;
                currentBalance = VacBalacnes.currentBalance;
                overTimeBalance = overTimeBalances.overTimeBalance;
            }
            else if (e.target.value.pos + "" === "6") {
                yearBalance = VacBalacnes.sickBalance;
                currentBalance = "";
                overTimeBalance = "";
            }
            else {
                yearBalance = VacBalacnes.sickBalance;
                currentBalance = "";
                overTimeBalance = "";
            }
        }

        this.setState({
            acceptedType: e.target.value, yearBalance: yearBalance, currentBalance: currentBalance, overTimeBalance: overTimeBalance,
            age: age, empClass: empClass, loading: false, subTypesList: subTypesList
        })
    }

    validateField(isReject) {

        let fieldValidationErrors = this.state.formErrors;

        let _id = this.state.dataObject.ID;
        fieldValidationErrors.id = _id ? '' : $.strings.requiredFiled;

        let _approvalNote = this.state.dataObject.approvalNote;
        fieldValidationErrors.approvalNote = _approvalNote ? '' : $.strings.requiredFiled;

        if (!isReject) {
            _approvalNote = 'done';
            fieldValidationErrors.approvalNote = '';
        }
        else {
            if (fieldValidationErrors.approvalNote && fieldValidationErrors.approvalNote.length > 0) {
                this.txtApprovalNotes.focus();
            }
        }
        let _empCode = this.state.dataObject.EmpCode;
        fieldValidationErrors.empCode = _empCode ? '' : $.strings.requiredFiled;

        this.setState({
            formErrors: fieldValidationErrors,
            idValid: _id,
            noteValid: _approvalNote,
            empCodeValid: _empCode
        }, this.validateForm);

        return _id && _approvalNote && _empCode;
    }
    validateForm() {
        let isFormValid = this.state.idValid && this.state.noteValid && this.state.empCodeValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {
        const footer = (
            <div className="p-grid" style={{ textAlign: 'center' }}>

                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12" style={{ textAlign: 'center' }}>
                    {
                        this.state.dataObject && this.state.dataObject.ViewOnly && this.state.dataObject.ViewOnly === "0" &&
                        <Button label={$.strings.approval.accept} icon="" className="p-button-success" onClick={() => this.onApprove(true, true, true)}>
                            &nbsp;&nbsp;
                            <i className="pi pi-check" />
                        </Button>
                    }
                    {
                        this.state.dataObject && this.state.dataObject.ViewOnly && this.state.dataObject.ViewOnly === "1" &&
                        <Button label={$.strings.approval.viewOnly} icon="" onClick={this.onView} >
                            &nbsp;&nbsp;
                            <i className="pi pi-eye" />
                        </Button>
                    }
                </div>

                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12" style={{ textAlign: 'center' }}>
                    <Button label={$.strings.view} icon="" className="p-button-info" onClick={e => this.viewRequest(this.state.dataObject)} >
                        &nbsp;&nbsp;
                        <i className="pi pi-eye" />
                    </Button>
                </div>

                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12" style={{ textAlign: 'center' }}>
                    {
                        this.state.dataObject && this.state.dataObject.ViewOnly && this.state.dataObject.ViewOnly === "0" &&
                        <Button label={$.strings.approval.reject} icon="" className="p-button-danger" onClick={this.onReject} >
                            &nbsp;&nbsp;
                            <i className="pi pi-times" />
                        </Button>
                    }
                </div>
            </div >
        );

        const myIcon = (
            <button className="p-dialog-titlebar-icon p-link">
            </button>
        )
        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <ClockTransEdit ref={e => this.dlgClockTransEdit = e} onSuccess={this.onClockTransEditSuccess} />
                <Dialog
                    header={$.strings.approval.title}
                    footer={footer}
                    icons={myIcon}
                    visible={this.state.visibleDialog}
                    style={{ width: this.state.isDesktop ? '800px' : '90vw', direction: $.strings.dir }}
                    modal
                    onHide={this.onHide}>
                    {
                        this.state.confirmBoxVisible &&
                        <AlertBox ref={e => this.confirmBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.Yes} />
                        
                    }
                    {
                        this.state.alertBoxVisible &&
                        <AlertBox ref={e => this.alertBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.Yes} yesCaption={$.strings.ok} />
                    }
                    <div ref="divMain" >
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="id"
                                    value={this.state.dataObject.ID}
                                    autocomplete="off"
                                    caption={$.strings.approval.code}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="typeName"
                                    value={this.state.dataObject.typeName}
                                    autocomplete="off"
                                    caption={$.strings.approval.mainType}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="transType"
                                    value={this.state.dataObject.TransType}
                                    autocomplete="off"
                                    caption={$.strings.approval.subType}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="empCode"
                                    value={this.state.dataObject.EmpCode}
                                    autocomplete="off"
                                    caption={$.strings.approval.empCode}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="empName"
                                    value={this.state.dataObject.EmpName}
                                    autocomplete="off"
                                    caption={$.strings.approval.empName}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                <Input
                                    id="branch"
                                    value={this.state.branch}
                                    autocomplete="off"
                                    caption={$.strings.approval.branch}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Calendar
                                    id="fromDate"
                                    value={this.state.dataObject.FromDate}
                                    autocomplete="off"
                                    caption={$.strings.approval.fromDate}
                                    disable={true}
                                    readOnly={true}
                                    readOnlyInput={true}
                                    showOnFocus={false}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Calendar
                                    id="toDate"
                                    value={this.state.dataObject.ToDate}
                                    autocomplete="off"
                                    caption={$.strings.approval.toDate}
                                    disable={true}
                                    readOnly={true}
                                    readOnlyInput={true}
                                    showOnFocus={false}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Input
                                    id="fromTime"
                                    value={this.state.dataObject.FromTime}
                                    autocomplete="off"
                                    caption={$.strings.approval.fromTime}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Input
                                    id="toTime"
                                    value={this.state.dataObject.ToTime}
                                    autocomplete="off"
                                    caption={$.strings.approval.toTime}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>

                        </div>

                    </div >
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                            <Input
                                id="totalTime"
                                value={this.state.dataObject.TotalTime}
                                autocomplete="off"
                                caption={$.strings.approval.totalTime}
                                disable={true}
                                readOnly={true}
                            />
                        </div>
                        <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                            <Input
                                id="section"
                                value={this.state.section}
                                autocomplete="off"
                                caption={$.strings.approval.section}
                                disable={true}
                                readOnly={true}
                            />
                        </div>
                        {
                            (Util.checkISHR() || Util.checkISManager()) && this.state.dataObject.Type === "1" && this.state.yearBalance &&
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Input
                                    id="yearBalance"
                                    value={this.state.yearBalance}
                                    autocomplete="off"
                                    caption={$.strings.approval.yearBalance}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        }
                        {
                            (Util.checkISHR() || Util.checkISManager()) && this.state.dataObject.Type === "1" && this.state.currentBalance && <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">

                                <Input
                                    id="currentBalance"
                                    value={this.state.currentBalance}
                                    autocomplete="off"
                                    caption={$.strings.approval.currentBalance}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.dataObject.TransTypeNo === "5" && <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">


                                <Input
                                    id="firstIn"
                                    value={this.state.firstIn}
                                    autocomplete="off"
                                    caption={$.strings.approval.firstIn}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.dataObject.TransTypeNo === "5" &&
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Input
                                    id="lastOut"
                                    value={this.state.lastOut}
                                    autocomplete="off"
                                    caption={$.strings.approval.lastOut}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.age &&
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                <Input
                                    id="age"
                                    value={this.state.age}
                                    autocomplete="off"
                                    caption={$.strings.approval.age}
                                    disable={true}
                                    readOnly={true}
                                />
                            </div>
                        }
                        <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                            {
                                Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.empClass && //(this.state.dataObject.TransTypeNo === "7" || this.state.dataObject.TransTypeNo === "6") &&
                                <Input
                                    id="age"
                                    value={this.state.empClass}
                                    autocomplete="off"
                                    caption={$.strings.approval.empClass}
                                    disable={true}
                                    readOnly={true}
                                />
                            }
                        </div>

                    </div>
                    <div className="p-grid">
                        {
                            Util.checkISHR() && this.state.dataObject.Type === "1" &&
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>

                                <Dropdown
                                    id="acceptedType"
                                    innerRef={e => this.drpAcceptedType = e}
                                    value={this.state.acceptedType}
                                    options={this.state.mainTypesList}
                                    caption={$.strings.handleRequests.acceptedType}
                                    onChange={this.handleUserInputAcceptedType}
                                    optionLabel="name"
                                    filter={true}
                                    filterBy="pos,name"
                                    filterMatchMode="contains"
                                />
                            </div>
                        }
                        {
                            Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.dataObject.TransTypeNo === "8" &&
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <Dropdown
                                    id="acceptedSubType"
                                    innerRef={e => this.drpAcceptedType = e}
                                    value={this.state.acceptedSubType}
                                    options={this.state.subTypesList}
                                    caption={$.strings.handleRequests.acceptedSubType}
                                    onChange={this.handleAcceptedSubType}
                                    optionLabel="name"
                                    filter={true}
                                    filterBy="pos,name"
                                    filterMatchMode="contains"
                                />
                            </div>
                        }
                        <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                            {
                                Util.checkISHR() && this.state.dataObject.Type === "1" && this.state.overTimeBalance && // this.state.dataObject.TransTypeNo === "7" &&
                                <Input
                                    id="overTimeBalance"
                                    value={this.state.overTimeBalance}
                                    autocomplete="off"
                                    caption={$.strings.vacations.overTimeBalance}
                                    disable={true}
                                    readOnly={true}
                                />
                            }
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <Input
                                id="requestNote"
                                value={this.state.dataObject.RequestNote}
                                autocomplete="off"
                                caption={$.strings.approval.requestNotes}
                                disable={true}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                            <Input
                                innerRef={e => this.txtApprovalNotes = e}
                                id="approvalNote"
                                maxLength="70"
                                value={this.state.dataObject.approvalNote}
                                onChange={this.handleUserInput}
                                autocomplete="off"
                                caption={$.strings.approval.approvalNote}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                    </div>
                </Dialog >
            </div >
        );
    }
    onClockTransEditSuccess = () => {
        alert($.strings.operationDone);
        this.setState({ loading: false, visibleDialog: false }, () => this.props.onSuccess ? this.props.onSuccess() : undefined)
    }
    viewRequest = (data) => {
        let id = Util.encryptStringToHexa(data.TransID);
        switch (data.Type + "") {
            case "1": {
                // طلبات الاجازات والمغادرات
                if (data.TransTypeNo + "" === "6" || data.TransTypeNo + "" === "7" || data.TransTypeNo + "" === "8" || data.TransTypeNo + "" === "9") {
                    // اجازات
                    if (data.PerType + "" === "0") {
                        Util.goToNewTab("../Requests/VacLessDay/" + id)
                    }
                    else {
                        Util.goToNewTab("../Requests/Vacations/" + id)
                    }
                }
                else if (data.TransTypeNo + "" === "5") {
                    // عمل اضافي
                    Util.goToNewTab("../Requests/OverTime/" + id)
                }
                else if (data.TransTypeNo + "" === "12") {
                    //  مغادرة خاصة مع خصم
                    Util.goToNewTab("../Requests/SpecialLeave/" + id)
                }
                else if (data.TransTypeNo + "" === "2") {
                    // مغادرة رسمية بدون خصم 
                    Util.goToNewTab("../Requests/WorkLeave/" + id)
                }
                break;
            }
            case "2": {
                // اخلاء الطرف
                break;
            }
            case "3": {
                // اقرار عودة
                break;
            }
            case "4": {
                // تذاكر السفر
                break;
            }
            case "5": {
                // الاجراءات الادارية
                break;
            }
            case "6": {
                // تصريح تنقل
                break;
            }
            case "7": {
                //المراسلات والمذكرات الادارية
                Util.goToNewTab("../Requests/Memos/" + id)
                break;
            }
            case "8": {
                // الاجازات المقترحة
                Util.goToNewTab("../Requests/ExpectedVacations/" + id)
                break;
            }
            case "9": {
                // التدريب
                break;
            }
            case "10": {
                // رحلة عمل
                break;
            }
            case "11": {
                // مخالصة رحلة عمل
                break;
            }
            case "12": {
                // عدم وجود بصمة
                Util.goToNewTab("../Requests/FingerPrint/" + id)
                break;
            }
            default: {
                break;
            }
        }
    }

    show = async (rowData) => {
        let obj = await this.getEmpInfo(rowData.EmpCode);
        let yearBalance = "", currentBalance = "", overTimeBalance = "", age = "", empClass = "";
        let mainTypesList = [], subTypesList = [];
        mainTypesList = await this.getAttendanceType();
        subTypesList = await this.getPaidVacationsTypes();
        let defaultAcceptedType = undefined, defaultAcceptedSubType = undefined;
        if (mainTypesList) {
            defaultAcceptedType = mainTypesList.find(e => e.pos + "" === rowData.TransTypeNo + "");
        }
        if (subTypesList) {
            defaultAcceptedSubType = subTypesList.find(e => e.pos + "" === rowData.LeaveType + "");
        }
        if ((Util.checkISHR() || Util.checkISManager()) && rowData.Type + "" === "1" && (rowData.TransTypeNo === "7" || rowData.TransTypeNo + "" === "6")) {
            let VacBalacnes = await this.getVacationsBalances(Util.encryptStringToHexa(rowData.EmpCode));
            let overTimeBalances = await this.getOverTimeBalance(Util.encryptStringToHexa(rowData.EmpCode));
            let objEmpInfo = await this.getEmployeeInfo(rowData.EmpCode);
            age = objEmpInfo.age ? objEmpInfo.age : "";
            empClass = objEmpInfo.age ? objEmpInfo.empClass : "";

            if (rowData.TransTypeNo === "7") {
                yearBalance = VacBalacnes.yearlyBalance;
                currentBalance = VacBalacnes.currentBalance;
                overTimeBalance = overTimeBalances.overTimeBalance;
            }
            else if (rowData.TransTypeNo === "6") {
                yearBalance = VacBalacnes.sickBalance;
                currentBalance = "";
                overTimeBalance = "";
            }
            else {
                yearBalance = VacBalacnes.sickBalance;
                currentBalance = "";
                overTimeBalance = "";
            }
        }
        if (Util.checkISHR() && rowData.Type === "1" && rowData.TransTypeNo === "12") {
            if (mainTypesList) {
                defaultAcceptedType = mainTypesList.find(e => e.pos + "" === Util.getSettingById(256) + "")
            }
        }
        let firstIn = "", lastOut = "";
        if (Util.checkISHR() && rowData.Type === "1" && rowData.TransTypeNo === "5") {
            let dataTemp = await this.getFirstInLastOut(rowData);

            if (dataTemp && dataTemp.length > 0) {
                firstIn = dataTemp[0].Time1;
                lastOut = dataTemp[0].Time2;
            }
        }
        this.setState({
            dataObject: rowData, yearBalance: yearBalance, currentBalance: currentBalance, overTimeBalance: overTimeBalance, firstIn: firstIn, lastOut: lastOut,
            visibleDialog: true, formErrors: { notes: '' }, branch: obj.branch, section: obj.section, loading: false, mainTypesList: mainTypesList,
            acceptedType: defaultAcceptedType, subTypesList: subTypesList, acceptedSubType: defaultAcceptedSubType,
            age: age, empClass: empClass
        }, async () => { setTimeout(() => { this.txtApprovalNotes.focus() }, 10); })
    }
    getVacationsBalances = async (empCode, typeId) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.empCode,
            typeId: typeId,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetVacationBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    getPaidVacationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }
    getEmployeeInfo = async (empCode) => {
        this.setState({ loading: true });
        let objTrans = { dob: undefined, age: undefined, annualBalance: undefined };
        let obj = {
            empCode: Util.encryptStringToHexa(empCode)
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetAnnualBalanceAndAge, obj, 0);
        if (res.status === 200) {
            objTrans = res.data._obj;
        }
        return objTrans;
    }
    getOverTimeBalance = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.dataObject.empCode,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetOverTimeBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    getAttendanceType = async () => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: 22
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }
    getFirstInLastOut = async (rowData) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            sort: '0',
            chkWhichNotSignIn: false,
            chkWhichNotExit: false,
            chkWhichLateOnly: false,
            chkGoBeforeEndTime: false,
            empCode: rowData.EmpCode,
            chkEditedTransOnly: false,
            userId: localStorage.getItem("useridHR") + "",
            fromDate: rowData.FromDate,
            toDate: rowData.ToDate,
            empCodeFrom: rowData.EmpCode,
            empCodeTo: rowData.EmpCode,
            sectionValue: 0,
            selectedBranchValue: "-1",
            selectedDepartment: "-1",
            DDL_shift: "-1",
            allTrans: 0
        }

        let res = await http.getJsonAxios(http.actions.AttReports.urlGetEntryAndExitReport, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.list;
        }

        return dataTemp;
    }
    getEmpInfo = async (empCode) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            empCode: empCode
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeBranchSection, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp;
    }
    getFingerPrint = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.FingerPrint.urlGetFingerPrint, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    onApprove = async (checkVacationExist, checkSickVacationPercent, checkShiftVacationDays) => {
        if (!this.validateField(false)) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })

        if (Util.checkISHR()) {
            // HR
            if (checkVacationExist && this.state.dataObject.Type === "12" && Util.getSettingById(260) + '' === '1') {
                let objResult = await this.checkVacationFound(this.state.dataObject.FromDate, this.state.dataObject.EmpCode);
                if (objResult.result) {
                    //this.setState({ loading: false })
                    // Util.confirmDialog(objResult.errorText, 'pi pi-question-circle',
                    //     async () => this.onApprove(false, true), () => { });

                    this.popupHasCalled();
                    this.setState({ loading: false, confirmBoxVisible: true }, () => {
                        this.confirmBox.show(
                            async () => {
                                this.popupHasClosed();
                                let res = await this.onApprove(false, true, checkShiftVacationDays);
                                this.setState({ confirmBoxVisible: false });
                                return res;
                            },
                            () => {
                                this.popupHasClosed();
                                this.setState({ confirmBoxVisible: false });
                            }, undefined,
                            objResult.errorText
                        );
                    })
                    return;
                }
            }
            if (checkSickVacationPercent && this.state.dataObject.Type === "1" && this.state.dataObject.TransTypeNo === "6") {
                // فحص نسب الاجازة المرضية
                let objResult = await this.checkSickVacationPercent(this.state.dataObject.FromDate, this.state.dataObject.ToDate, this.state.dataObject.EmpCode, this.state.dataObject.TotalTime);
                if (objResult.result) {
                    this.popupHasCalled();
                    this.setState({ loading: false, confirmBoxVisible: true }, () => {
                        this.confirmBox.show(
                            async () => {
                                this.popupHasClosed();
                                let res = await this.onApprove(false, false, checkShiftVacationDays);
                                this.setState({ confirmBoxVisible: false });
                                return res;
                            },
                            () => {
                                this.popupHasClosed();
                                this.setState({ confirmBoxVisible: false });
                            }, undefined,
                            objResult.errorText
                        );
                    })
                    return;
                }
            }

            if (checkShiftVacationDays && this.state.dataObject.Type === "1" && this.state.dataObject.TransTypeNo === "7") {
                // فحص عدد أيام اجازة الفترة
                let objResult = await this.checkPeriodVacationDays(this.state.dataObject.TotalTime, this.state.dataObject.EmpCode, this.state.dataObject.FromDate);
                if (objResult.result) {
                    this.popupHasCalled();
                    this.setState({ loading: false, alertBoxVisible: true }, () => {
                        this.alertBox.show(
                            async () => {
                                this.popupHasClosed();
                                let res = await this.onApprove(false, false, false);
                                this.setState({ alertBoxVisible: false });
                                return res;
                            },
                            undefined, undefined,
                            objResult.errorText
                        );
                    })
                    return;
                }
            }

            //if (checkVacationExist)
            if (this.state.dataObject.Type === "12") {
                let code = Util.encryptStringToHexa(this.state.dataObject.ID);
                let approvalNote = this.state.dataObject.approvalNote;
                let dataFP = await this.getFingerPrint(code);
                if (dataFP && dataFP.id > 0) {
                    const empCode = dataFP.empCode;
                    const trasDate = dataFP.transDate;
                    const id = dataFP.id;
                    const fpTransType1 = dataFP.transType + "";
                    const fpTransTime1 = dataFP.transTime;
                    const fpTransType2 = dataFP.transType2 + "";
                    const fpTransTime2 = dataFP.transTime2;
                    this.dlgClockTransEdit.show(empCode, trasDate, id, fpTransType1, fpTransTime1, fpTransType2, fpTransTime2, approvalNote);
                }
                else {
                    alert($.strings.clockTrans.fpGetDataError);
                }
                this.setState({ loading: false });
                return;
            }

            await this.onApproveHR();
        }
        else {
            await this.onApproveManager();
        }

    }
    onApproveHR = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            checkAbsent: true,
            Type: this.state.dataObject.Type,
            TransID: this.state.dataObject.TransID,
            TransTypeNo: this.state.dataObject.TransTypeNo,
            lastStepAction: this.state.dataObject.LastStepAction,
            isHR: Util.checkISHR(),
            userId: localStorage.getItem("useridHR"),
            userName: localStorage.getItem("userNameHR"),
            perType: this.state.dataObject.PerType,
            acceptedType: this.state.acceptedType && this.state.acceptedType.pos >= 0 ? this.state.acceptedType.pos : '-1',
            acceptedSubType: this.state.acceptedSubType && this.state.acceptedSubType.pos >= 0 ? this.state.acceptedSubType.pos : ""
        }

        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetAcceptFromHR, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
            else {
                if (res.data.cpConfirm === "1") {
                    if (window.confirm(res.data.errorText)) {
                        return await this.onApproveHRWithOutCheck();

                    }
                    else {
                        return await this.ignoreHR();
                    }
                }
                else {
                    alert(res.data.errorText || $.strings.operationFailed);
                    this.setState({ loading: false })
                    return saveResult = false
                }
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
    onApproveHRWithOutCheck = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            userId: localStorage.getItem("useridHR"),
            acceptedType: this.state.acceptedType && this.state.acceptedType.pos >= 0 ? this.state.acceptedType.pos : '-1',
            acceptedSubType: this.state.acceptedSubType && this.state.acceptedSubType.pos >= 0 ? this.state.acceptedSubType.pos : ""
        }
        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetAcceptFromHRWithOutCheck, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
    checkVacationFound = async (transDate, empCode) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            transDate: transDate,
            empCode: empCode,
            isHR: true
        }
        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlCheckVacationOrRequestFound, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }
    checkSickVacationPercent = async (fromDate, toDate, empCode, vacDaysCount) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            fromDate: fromDate,
            toDate: toDate,
            empCode: empCode,
            vacDaysCount: vacDaysCount
        }
        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlCheckSickVacationPercent, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }

    checkPeriodVacationDays = async (daysCount, empCode, fromDate) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            fromDate: fromDate,
            daysCount: daysCount,
            empCode: empCode,
        }
        let res = await http.postMultipartAxios(http.actions.HandleRequests.urlCheckPeriodVacationDays, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }

    ignoreHR = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            userId: localStorage.getItem("useridHR")
        }
        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetIgnoreFromHR, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
    onApproveManager = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            isManager: Util.checkISManager(),
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            Type: this.state.dataObject.Type,
            TransID: this.state.dataObject.TransID,
            TransTypeNo: this.state.dataObject.TransTypeNo,
            Steps: this.state.dataObject.Steps,
            lastStepAction: this.state.dataObject.LastStepAction,
            userName: localStorage.getItem("empLanguageHR") + "" === '1' ? localStorage.getItem("userNameHR") : localStorage.getItem("userNameEnHR"),
            perType: this.state.dataObject.PerType
        }
        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetAcceptFromManager, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    onReject = async () => {
        if (!this.validateField(true)) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })

        if (Util.checkISHR()) {
            // HR
            await this.onRejectHR();
        }
        else {
            await this.onRejectManager();
        }

    }
    onRejectHR = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            Type: this.state.dataObject.Type,
            TransID: this.state.dataObject.TransID,
            TransTypeNo: this.state.dataObject.TransTypeNo,
            Steps: this.state.dataObject.Steps && this.state.dataObject.Steps >= 0 ? this.state.dataObject.Steps : -1,
            lastStepAction: this.state.dataObject.LastStepAction,
            userName: localStorage.getItem("empLanguageHR") + "" === '1' ? localStorage.getItem("userNameHR") : localStorage.getItem("userNameEnHR"),
            userCode: localStorage.getItem("useridHR")
        }
        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetRejectFromHR, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }
    onRejectManager = async () => {
        let obj = {
            reqID: this.state.dataObject.ID,
            empCode: this.state.dataObject.EmpCode,
            approvalNote: this.state.dataObject.approvalNote,
            isManager: Util.checkISManager(),
            TransType: this.state.dataObject.TransType,
            empName: this.state.dataObject.EmpName,
            fDate: this.state.dataObject.FromDate,
            tDate: this.state.dataObject.ToDate,
            fTime: this.state.dataObject.FromTime ? this.state.dataObject.FromTime : '',
            tTime: this.state.dataObject.ToTime ? this.state.dataObject.ToTime : '',
            Type: this.state.dataObject.Type,
            TransID: this.state.dataObject.TransID,
            TransTypeNo: this.state.dataObject.TransTypeNo,
            Steps: this.state.dataObject.Steps,
            lastStepAction: this.state.dataObject.LastStepAction,
            userName: localStorage.getItem("empLanguageHR") + "" === '1' ? localStorage.getItem("userNameHR") : localStorage.getItem("userNameEnHR"),
            perType: this.state.dataObject.PerType
        }
        let saveResult
        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetRejectFromManager, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                if (res.data.errorText) {
                    alert(res.data.errorText);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return saveResult = true
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return saveResult = false
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return saveResult = false
        }
    }

    onView = async () => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.setState({ loading: true })

        let obj = {
            reqID: this.state.dataObject.ID,
            note: this.state.dataObject.approvalNote && this.state.dataObject.approvalNote.length > 0 ? this.state.dataObject.approvalNote : '',
            Type: this.state.dataObject.Type,
            TransTypeNo: this.state.dataObject.TransTypeNo,
            TransID: this.state.dataObject.TransID,
            empCode: this.state.dataObject.EmpCode,
            steps: this.state.dataObject.Steps
        }

        let res = await http.getJsonAxios(http.actions.HandleRequests.urlGetRequestWatched, obj, 0);
        if (res.status === 200) {
            if (res.data) {
                if (res.data.message) {
                    alert(res.data.message);
                }
                else {
                    alert($.strings.operationDone);
                }
                this.setState({ loading: false, visibleDialog: false })
                this.props.onSuccess();
                return true;
            }
        }
        if (res.data.errorText !== "") {
            alert(res.data.errorText);
            this.setState({ loading: false })
            return false;
        }
        else {
            alert($.strings.operationFailed);
            this.setState({ loading: false })
            return false;
        }
    }

    onHide = () => {
        this.setState({ visibleDialog: false, formErrors: { oldPwd: '', newPwd: '', confirmPwd: '' } })
    }


    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }
}