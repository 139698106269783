import React from "react";
import styles from './Vacations.module.scss'
import Input from "../../Components/Input";
import ButtonsBarLookup from '../../Components/ButtonsBarLookup'
import Messages from '../../Components/Messages'
import http from "../../Api/http";
import ProgressSpinner from '../../Components/ProgressSpinner'
import MessageBox from '../../Components/MessageBox'
import DeleteNoteDialog from '../../Components/DeleteNoteDialog'
import Util from '../../Util'
import Button from '../../Components/Button';
import Dropdown from '../../Components/Dropdown';
import Card from '../../Components/Card'
import InputTextArea from '../../Components/InputTextArea';
import Calendar from '../../Components/Calendar'
import SiteMap from '../../Components/SiteMap';
import ScrollTop from '../ScrollButton';
import WFTrans from '../WFTrans';
import Attachments from '../Attachments';
import { FileUpload } from 'primereact/fileupload';
import DMVacDecision from "./DMVacDecision";
import VacEmpDetails from "./VacEmpDetails";
import AlertBox from "../../Components/AlertBox";

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class Vacations extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.addressAndPhoneRequired = "0";
        this.disAllowVacationBefore3Days = "0";
        this.requestAnnualVacationBefore = "0";
        this.printVacationForm = "0";
        this.state = {
            loading: true,
            mode: this.props.match.params.id ? 'edit' : 'new',
            formErrors: { empCode: '', empName: '', vacType: '', paidVacType: '', fromDate: '', toDate: '', notes: '' },
            deleteErrors: { deleteNote: '' },
            vacTypesList: Util.getVacationsTypes(),
            paidVacTypeList: [],
            isDesktop: window.innerWidth > 1250,
            dataObject: {
                id: 0,
                absentCode: '',
                empCode: this.props.match.params.empCode || localStorage.getItem("useridHR") || '',
                empName: Util.getEmployeeInfo() || '',
                requestDate: new Date(),
                vacType: { id: 7, name: $.strings.vacations.annualVac },
                paidVacType: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                daysNo: '1',
                address: '',
                phone: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1,
                currentBalance: '',
                overTimeBalance: '',
                yearBalance: '',
                actualFromDate: null,
                actualToDate: null,
                actualDays: '',

                showAlteEmp: false,
                showAlteEmp2: false
            }
        }
    }

    updatePredicate = () => {
        return window.innerWidth > 1250
    }

    componentDidMount = async () => {
        if (!$.license.SelfService_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkRights(601) && !Util.checkRights(602) && !Util.checkRights(603) && !Util.checkRights(604)) {
            Util.goTo('/Access');
            return;
        }
        let _isDesktop = this.updatePredicate();
        let paidVacTypesPromise = this.getPaidVacationsTypes();
        let vacationBalancesPromise = this.getVacationsBalances(this.props.match.params.empCode);
        let overTimeBalancesPromise = this.getOverTimeBalance(this.props.match.params.id);

        let vacationBalances = await vacationBalancesPromise;
        let paidVacTypes = await paidVacTypesPromise;
        this.addressAndPhoneRequired = await Util.GetSettingById(214);
        this.disAllowVacationBefore3Days = await Util.GetSettingById(222);
        this.requestAnnualVacationBefore = await Util.GetSettingById(221);
        this.altEmpRequired = await Util.GetSettingById(214);
        this.attachmentsRequiredInSickVacations = await Util.GetSettingById(212);
        this.printVacationForm = await Util.GetSettingById(209);
        let overTimeBalances = await overTimeBalancesPromise;
        let altEmpList = [], altEmp2List = [];
        if (this.altEmpRequired + "" === "1") {
            let obj = await this.getAltEmployees();
            if (obj) {
                altEmpList = obj.firstAltEmployee || [];
                altEmp2List = obj.secondAltEmployee || [];
            }
        }

        if (!this.props.match.params.id) {
            let empCode = this.props.match.params.empCode || localStorage.getItem("useridHR") + "";
            let empName = Util.getEmployeeInfo() || ''
            if (empCode !== localStorage.getItem("useridHR") + "") {
                let obj = await this.getEmployeeInfo(empCode);
                empCode = obj.Code;
                empName = obj.Name;
            }
            this.setState({
                isDesktop: _isDesktop, paidVacTypeList: paidVacTypes, loading: false,
                altEmpList: altEmpList, altEmp2List: altEmp2List,
                dataObject: {
                    ...this.state.dataObject, empCode: empCode, empName: empName, currentBalance: vacationBalances.currentBalance + '',
                    yearBalance: vacationBalances.yearlyBalance + '', overTimeBalance: overTimeBalances.overTimeBalance + ''
                }
            })
        }
        else {
            this.setState({
                isDesktop: _isDesktop, paidVacTypeList: paidVacTypes,
                altEmpList: altEmpList, altEmp2List: altEmp2List
            })
        }

        if (this.props.match.params.id) {
            let data = await this.showVacationInfo(this.props.match.params.id)
            this.loadData(data)
        }

        setTimeout(() => {
            if (this.drpVacType && this.drpVacType.focusInput) {
                this.drpVacType.focusInput.focus()
            }
        }, 10);

    }


    popupHasCalled = () => {
        document.removeEventListener('keydown', this.onPageKeyDown);
    }
    popupHasClosed = () => {
        document.addEventListener('keydown', this.onPageKeyDown);
    }

    hashState = () => {
        let newHash = stringHash(JSON.stringify(this.state.dataObject))
        return newHash
    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } })
    }

    handleVacation = async (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let altEmpList = [], altEmp2List = [];
        if (this.altEmpRequired + "" === "1") {
            let obj = await this.getAltEmployees(value);
            if (obj) {
                altEmpList = obj.firstAltEmployee || [];
                altEmp2List = obj.secondAltEmployee || [];
            }
        }
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value }, altEmpList: altEmpList, altEmp2List: altEmp2List })
    }

    handlePaidVacationSubType = async (e) => {
        const name = e.target.id;
        const value = e.target.value;
        let altEmpList = [], altEmp2List = [];
        if (this.altEmpRequired + "" === "1") {
            let obj = await this.getAltEmployees(this.state.dataObject.vacType.id, value);
            if (obj) {
                altEmpList = obj.firstAltEmployee || [];
                altEmp2List = obj.secondAltEmployee || [];
            }
        }
        this.setState({ dataObject: { ...this.state.dataObject, [name]: value }, altEmpList: altEmpList, altEmp2List: altEmp2List })
    }

    handleFromDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, fromDate: e.target.value } }, () => this.setToDate())
    }
    setToDate = () => {
        if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
            this.setState({ dataObject: { ...this.state.dataObject, toDate: this.state.dataObject.fromDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo()
        }
    }
    handleToDate = (e) => {
        this.setState({ dataObject: { ...this.state.dataObject, toDate: e.target.value } }, async () => {
            this.setFromDate();
        })
    }
    setFromDate = () => {
        if (this.state.dataObject.toDate < this.state.dataObject.fromDate) {
            this.setState({ dataObject: { ...this.state.dataObject, fromDate: this.state.dataObject.toDate } }, () => this.calculateDaysNo())
        }
        else {
            this.calculateDaysNo()
        }
    }

    calculateDaysNo = () => {
        try {
            let fromDate = this.state.dataObject.fromDate
            let toDate = this.state.dataObject.toDate

            const _fromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
            const _toDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 0, 0, 0);
            let temp = 1000 * 3600 * 24;

            let daysNo = Math.floor((_toDate - _fromDate) / temp);

            daysNo += 1;

            this.setState({ dataObject: { ...this.state.dataObject, daysNo: daysNo } }, async () => await this.getVacationDaysCount())
        }
        catch (e) {

        }
    }

    validateField() {

        let fieldValidationErrors = this.state.formErrors;

        let _empCode = this.state.dataObject.empCode
        fieldValidationErrors._empCode = _empCode ? '' : $.strings.requiredFiled;

        let _empName = this.state.dataObject.empName;
        fieldValidationErrors.empName = _empName ? '' : $.strings.requiredFiled;

        let _vacType = this.state.dataObject.vacType;
        fieldValidationErrors.vacType = _vacType ? '' : $.strings.requiredFiled;

        let _paidVacType = true
        if (_vacType && _vacType.id === 8) {
            _paidVacType = this.state.dataObject.paidVacType;
            fieldValidationErrors.paidVacType = _paidVacType ? '' : $.strings.requiredFiled;
        }

        let _fromDate = this.state.dataObject.fromDate
        fieldValidationErrors.fromDate = _fromDate ? '' : $.strings.requiredFiled;

        let _toDate = this.state.dataObject.toDate

        fieldValidationErrors.toDate = _toDate ? '' : $.strings.requiredFiled;

        let _address = true, _phone = true;
        fieldValidationErrors.address = "";
        fieldValidationErrors.phone = "";
        if (this.addressAndPhoneRequired === "1") {
            if (!this.state.dataObject.address || this.state.dataObject.address.length <= 0) {
                fieldValidationErrors.address = $.strings.requiredFiled;
                _address = false;
            }
            if (!this.state.dataObject.phone || this.state.dataObject.phone.length <= 0) {
                fieldValidationErrors.phone = $.strings.requiredFiled;
                _phone = false;
            }
        }

        if (_vacType && _vacType.id === 6 && this.attachmentsRequiredInSickVacations === "1" &&
            (!this.state.dataObject.uploadedFile)) {
            _paidVacType = this.state.dataObject.paidVacType;
            fieldValidationErrors.vacType = $.strings.vacations.selectAFile;
            Util.showErrorMsg(this.messages, $.strings.vacations.selectAFile)
        }

        let _daysPeriod = true;
        if (_vacType && _vacType.id === 7 && this.disAllowVacationBefore3Days === "1") {
            let daysCount = Util.calculateDaysNo(this.state.dataObject.fromDate, new Date());

            if (daysCount < this.requestAnnualVacationBefore && _empCode + "" === localStorage.getItem("useridHR") + "") {
                Util.showErrorMsg(this.messages, $.strings.vacations.before3DaysError + " " + this.requestAnnualVacationBefore + " " + $.strings.days)
                _daysPeriod = false;
            }
        }

        let _notes = this.state.dataObject.notes;
        fieldValidationErrors.notes = _notes ? '' : $.strings.requiredFiled;

        this.setState({
            formErrors: fieldValidationErrors,
            empCodeValid: _empCode,
            empNameValid: _empName,
            vacTypeValid: _vacType,
            paidVacTypeValid: _paidVacType,
            fromDateValid: _fromDate,
            toDateValid: _toDate,
            addressValid: _address,
            phoneValid: _phone,
            notesValid: _notes,
            daysPeriodValid: _daysPeriod

        }, this.validateForm);

        return _empCode && _empName && _vacType && _paidVacType && _fromDate && _toDate && _address && _phone && _notes && _daysPeriod;
    }
    validateForm() {
        let isFormValid = this.state.empCodeValid && this.state.empNameValid && this.state.vacTypeValid
            && this.state.paidVacTypeValid && this.state.fromDateValid && this.state.toDateValid &&
            this.state.addressValid && this.state.phoneValid && this.state.notesValid && this.state.daysPeriodValid
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    render() {

        let siteMapItems = [
            { label: $.strings.links.requests.menu },
            {
                label: <div>
                    {$.strings.links.requests.vacation + " "}
                    {(this.state.mode === "new" ? <span style={{ color: 'red' }}>{" (" + $.strings.newMode + ")"}</span> : '')}
                </div>
            }
        ];

        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return (
            <div ref="divMain" className={styles.addVacations}>
                {/* {screenHeader} */}
                <Card>
                    <SiteMap model={siteMapItems} home={siteMapHome} />
                </Card>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-md-12" >
                        <ButtonsBarLookup
                            onNew={!this.props.match.params.id ? this.resetFields : undefined}
                            onSave={!this.props.match.params.id ? e => this.onSave(true, true) : undefined}
                            onTransDetails={this.props.match.params.id ? this.wfTransDetails : undefined}
                            onShowAttachment={this.props.match.params.id && this.state.dataObject.file_id > 0 ? this.onShowAttachment : undefined}
                            onPrint={this.props.match.params.id ? e => this.onPrint(e) : undefined}
                        />
                    </div>
                </div>
                <ProgressSpinner loading={this.state.loading} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-12 p-sm-12">
                        <Messages innerRef={(el) => this.messages = el} />
                    </div>
                </div>
                {
                    this.state.confirmBoxVisible &&
                    <AlertBox ref={e => this.confirmBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.Yes} />
                }
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNewVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNew(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnPrevVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onPrevious(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnNextVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onNext(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnFirstVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onFirst(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnLastVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onLast(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnDeleteVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onDelete(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloneVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.onClone(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />
                <MessageBox header={$.strings.appName} visible={this.state.dlgOnCloseVisible} onYes={e => this.onSaveWithoutClose(e, false)} toDo={e => this.btnClose(e, false)} onCancel={this.onCancel}
                    appendTo={this.props.parent} />

                <DeleteNoteDialog ref={ref => this.deleteNoteDlg = ref} visible={this.state.dlgDeleteNoteVisible} deleteErrors={this.state.deleteErrors} appendTo={this.props.parent}
                    onYes={this.btnDeleteYes} onNo={this.DeleteCancel} deleteNote='' />
                <div className='p-grid'>
                    <div className="p-lg-6 p-sm-12"></div>
                    <div className="p-lg-6 p-sm-12">

                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12 p-xl-8 p-lg-8 p-md-12 p-sm-12">
                        <Card style={{ minHeight: '170px' }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtCode = el}
                                        type="text"
                                        id="id"
                                        keyfilter="pnum"
                                        value={this.state.dataObject.id || ''}
                                        autofocus={true}
                                        autocomplete="off"
                                        onChange={this.handleUserInput}
                                        caption={$.strings.vacations.code}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                    <Calendar
                                        id="requestDate"
                                        innerRef={(el) => this.dtpRequestDate = el}
                                        value={this.state.dataObject.requestDate}
                                        caption={$.strings.vacations.requestDate}
                                        onChange={this.handleUserInput}
                                        tooltip={$.strings.vacations.requestDate}
                                        readOnlyInput={true}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-6 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtEmpCode = el}
                                        type="text"
                                        id="empCode"
                                        keyfilter="pnum"
                                        value={this.state.dataObject.empCode || ''}
                                        autofocus={true}
                                        autocomplete="off"
                                        caption={$.strings.vacations.empCode}
                                        isRequired={true}
                                        onChange={this.handleUserInput}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-8 p-lg-8 p-md-6 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtEmpName = el}
                                        type="text"
                                        id="empName"
                                        maxLength="70"
                                        value={this.state.dataObject.empName || ''}
                                        autofocus={true}
                                        caption={$.strings.vacations.empName}
                                        onChange={this.handleUserInput}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                                    />
                                </div>
                            </div>
                        </Card>
                        <Card>
                            <div className="p-grid">
                                <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                    <Dropdown
                                        id="vacType"
                                        innerRef={e => this.drpVacType = e}
                                        value={this.state.dataObject.vacType || ''}
                                        options={this.state.vacTypesList}
                                        caption={$.strings.vacations.vacType}
                                        onChange={this.handleVacation}
                                        formErrors={this.state.formErrors}
                                        optionLabel="name"
                                        placeholder={$.strings.vacations.selectVacType}
                                        isRequired={true}
                                        tooltip={$.strings.vacations.memoType}
                                        tooltipOptions={{ position: 'top' }}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-4 p-lg-6 p-md-6 p-sm-12">
                                    {
                                        this.state.dataObject.vacType && this.state.dataObject.vacType.id + '' === '8'
                                            ?
                                            <Dropdown
                                                id="paidVacType"
                                                innerRef={e => this.drpPaidVacType = e}
                                                value={this.state.dataObject.paidVacType || ''}
                                                options={this.state.paidVacTypeList}
                                                caption={$.strings.vacations.paidVacType}
                                                onChange={this.handlePaidVacationSubType}
                                                formErrors={this.state.formErrors}
                                                optionLabel="name"
                                                placeholder={$.strings.vacations.selectPaidVacType}
                                                isRequired={true}
                                                tooltip={$.strings.vacations.memoType}
                                                tooltipOptions={{ position: 'top' }}
                                                disabled={this.state.dataObject.id > 0 ? true : false}
                                            /> :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Calendar
                                        id="fromDate"
                                        innerRef={(el) => this.dtpFromDate = el}
                                        ref={(el) => this.dtpFromDate = el}
                                        value={this.state.dataObject.fromDate}
                                        caption={$.strings.vacations.fromDate}
                                        onChange={this.handleFromDate}
                                        tooltip={$.strings.vacations.fromDate}
                                        readOnlyInput={true}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Calendar
                                        id="toDate"
                                        innerRef={(el) => this.dtpToDate = el}
                                        ref={(el) => this.dtpToDate = el}
                                        value={this.state.dataObject.toDate}
                                        caption={$.strings.vacations.toDate}
                                        onChange={this.handleToDate}
                                        tooltip={$.strings.vacations.toDate}
                                        readOnlyInput={true}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                                <div className="p-col-12 p-xl-4 p-lg-4 p-md-4 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtDaysNo = el}
                                        type="text"
                                        id="daysNo"
                                        keyfilter="pnum"
                                        value={this.state.dataObject.daysNo || ''}
                                        autofocus={true}
                                        autocomplete="off"
                                        onChange={this.handleUserInput}
                                        caption={$.strings.vacations.daysNo}
                                        readOnly={true}
                                        disabled
                                        isRequired={true}
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                        formErrors={this.state.formErrors}

                                    />
                                </div>
                            </div>
                            {this.altEmpRequired === "1" &&
                                <div className="p-grid">
                                    <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <Dropdown
                                            id="altEmp"
                                            innerRef={e => this.drpAltEmp = e}
                                            value={this.state.dataObject.altEmp || ''}
                                            options={this.state.altEmpList}
                                            showClear={true}
                                            caption={$.strings.vacations.altEmp}
                                            onChange={this.handleUserInput}
                                            formErrors={this.state.formErrors}
                                            optionLabel="name"
                                            placeholder={$.strings.vacations.selectAltEmp}
                                            filter={true}
                                            filterBy="code,name"
                                            filterMatchMode="contains"
                                            isRequired={true}
                                            tooltip={$.strings.vacations.altEmp}
                                            tooltipOptions={{ position: 'top' }}
                                            disabled={this.state.dataObject.id > 0 ? true : false}
                                        />
                                    </div>
                                    <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <Dropdown
                                            id="altEmp2"
                                            innerRef={e => this.drpAltEmp2 = e}
                                            value={this.state.dataObject.altEmp2 || ''}
                                            options={this.state.altEmp2List}
                                            showClear={true}
                                            caption={$.strings.vacations.altEmp2}
                                            onChange={this.handleUserInput}
                                            formErrors={this.state.formErrors}
                                            optionLabel="name"
                                            placeholder={$.strings.vacations.selectAltEmp2}
                                            filter={true}
                                            filterBy="code,name"
                                            filterMatchMode="contains"
                                            isRequired={true}
                                            tooltip={$.strings.vacations.altEmp2}
                                            tooltipOptions={{ position: 'top' }}
                                            disabled={this.state.dataObject.id > 0 ? true : false}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="p-grid">
                                <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtAddress = el}
                                        type="text"
                                        id="address"
                                        maxLength="50"
                                        value={this.state.dataObject.address || ''}
                                        autofocus={true}
                                        autocomplete="off"
                                        caption={$.strings.vacations.address}
                                        isRequired={this.addressAndPhoneRequired + "" === "1" ? true : false}
                                        formErrors={this.state.formErrors}
                                        onChange={this.handleUserInput}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                                <div className="p-col-6 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtPhone = el}
                                        type="text"
                                        id="phone"
                                        maxLength="70"
                                        value={this.state.dataObject.phone || ''}
                                        autofocus={true}
                                        caption={$.strings.vacations.phone}
                                        onChange={this.handleUserInput}
                                        isRequired={this.addressAndPhoneRequired + "" === "1" ? true : false}
                                        formErrors={this.state.formErrors}
                                        style={{ width: '100%' }}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <InputTextArea
                                        innerRef={ref => this.txtNotes = ref}
                                        type="text"
                                        id="notes"
                                        maxLength="100"
                                        value={this.state.dataObject.notes}
                                        caption={$.strings.vacations.notes}
                                        onChange={this.handleUserInput}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        disabled={this.state.dataObject.id > 0 ? true : false}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <div >
                                        <label for="file-upload" className={styles.customFileUpload}>
                                            {$.strings.vacations.uploadFile}
                                        </label>
                                        <FileUpload chooseLabel={$.strings.vacations.uploadFile} name="file-upload-prime" url=""
                                            onSelect={this.handleChangePrime} auto maxFileSize={5000000} disabled={this.state.dataObject.id > 0}
                                            invalidFileSizeMessageSummary={$.strings.fileSizeError}
                                            invalidFileSizeMessageDetail={""} />
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="p-col-12 p-lg-4 p-sm-12">
                        <Card title={$.strings.vacations.vacBalances} style={{ minHeight: '170px' }}>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtCurrentBalance = el}
                                        type="text"
                                        id="currentBalance"
                                        maxLength="70"
                                        value={this.state.dataObject.currentBalance === 'null' ? '' : this.state.dataObject.currentBalance}
                                        caption={$.strings.vacations.currentBalance}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtYearBalance = el}
                                        type="text"
                                        id="yearBalance"
                                        maxLength="70"
                                        value={this.state.dataObject.yearBalance === 'null' ? '' : this.state.dataObject.yearBalance}
                                        caption={$.strings.vacations.yearBalance}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className="p-grid">
                                <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                    <Input
                                        innerRef={(el) => this.txtOverTimeBalance = el}
                                        type="text"
                                        id="overTimeBalance"
                                        maxLength="70"
                                        value={this.state.dataObject.overTimeBalance === 'null' || this.state.dataObject.overTimeBalance === undefined ? '' : this.state.dataObject.overTimeBalance}
                                        caption={$.strings.vacations.overTimeBalance}
                                        readOnly={true}
                                        disabled
                                        style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                                    />
                                </div>
                            </div>
                        </Card>
                        {
                            this.state.dataObject.id && this.state.dataObject.absentCode.length > 0 > 0
                                ?
                                <Card title={$.strings.vacations.actualVacation} style={{ minHeight: '170px' }}>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                            <Calendar
                                                id="actualFromDate"
                                                innerRef={(el) => this.dtpActualFromDate = el}
                                                // value={this.state.dataObject.actualFromDate}
                                                value={this.state.dataObject.actualFromDate && this.state.dataObject.actualFromDate.getFullYear() > 1900 ? this.state.dataObject.actualFromDate : ''}
                                                caption={$.strings.vacations.actualFromDate}
                                                onChange={this.handleUserInput}
                                                tooltip={$.strings.vacations.actualFromDate}
                                                readOnlyInput={true}
                                                readOnly={true}
                                                disabled
                                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                            />
                                        </div>
                                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                            <Calendar
                                                id="actualToDate"
                                                innerRef={(el) => this.dtpactualToDate = el}
                                                value={this.state.dataObject.actualToDate && this.state.dataObject.actualToDate.getFullYear() > 1900 ? this.state.dataObject.actualToDate : ''}
                                                caption={$.strings.vacations.actualToDate}
                                                onChange={this.handleUserInput}
                                                tooltip={$.strings.vacations.actualToDate}
                                                readOnlyInput={true}
                                                readOnly={true}
                                                disabled
                                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="p-grid">
                                        <div className="p-col-12 p-xl-12 p-lg-12 p-md-12 p-sm-12">
                                            <Input
                                                innerRef={(el) => this.txtActualDays = el}
                                                type="text"
                                                id="actualDays"
                                                maxLength="70"
                                                value={this.state.dataObject.actualDays || ''}
                                                autofocus={true}
                                                caption={$.strings.vacations.actualDays}
                                                onChange={this.handleUserInput}
                                                readOnly={true}
                                                disabled
                                                style={{ color: 'black', backgroundColor: '#dbdbdb', fontWeight: 'bold', fontSize: '13px', width: '100%' }}
                                            />
                                        </div>
                                    </div>
                                </Card>
                                :
                                null
                        }
                    </div>
                </div>
                <ScrollTop />
                <WFTrans ref={e => this.wfTransDlg = e}></WFTrans>
                <DMVacDecision ref={e => this.dlgDMVacDecision = e} onSuccess={this.onDMAccept} />
                <VacEmpDetails ref={e => this.dlgVacEmpDetails = e} />
                <Attachments ref={e => this.dlgAttachments = e}></Attachments>
            </div >
        );
    }

    getPaidVacationsTypes = async () => {
        let dataTemp = []
        let obj = {
            type: 4
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    getVacationsBalances = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.dataObject.empCode,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetVacationBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }
    getVacationDaysCount = async () => {
        let daysCount = this.state.dataObject.daysNo;
        this.setState({ loading: true })
        let obj = {
            empCode: this.state.dataObject.empCode,
            reason: this.state.dataObject.vacType.id,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            lang_id: ''
        }
        
        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlGetVacationDaysCount, obj, null, null, 0);
        console.log(res)
        if (res.status === 200) {
            if (res.data && res.data.success) {
                daysCount = res.data.daysCount;
                if (res.data && res.data.errorText) {
                    Util.showErrorMsg(this.messages, res.data.errorText);
                }
            }
        }

        this.setState({ dataObject: { ...this.state.dataObject, daysNo: daysCount }, loading: false })
    }
    getOverTimeBalance = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode && empCode.length > 0 ? empCode : this.state.dataObject.empCode,
            isEncrypted: !empCode ? false : true
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetOverTimeBalances, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    getAltEmployees = async (vacationType, vacationSubType) => {
        let dataTemp = []
        let obj = {
            vacType: vacationType ? vacationType : this.state.dataObject.vacType.id,
            vacationSubType: vacationSubType ? vacationSubType : (this.state.dataObject.paidVacType ? this.state.dataObject.paidVacType.pos : ''),
            empcode: this.state.dataObject.empCode
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetSubstituteEmployees, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    getEmployeeInfo = async (empCode) => {
        let dataTemp = []
        let obj = {
            empCode: empCode,
            isHr: Util.checkISHR(),
            userId: localStorage.getItem('useridHR')
        }
        let res = await http.getJsonAxios(http.actions.Employee.urlGetEmployeeInfo, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.EmployeeInfoObj;
        }
        return dataTemp
    }

    showVacationInfo = async (id) => {
        let dataTemp = []
        let obj = {
            id: id
        }
        let res = await http.getJsonAxios(http.actions.HolidaysReq.urlGetHolidayReq, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data;
        }
        return dataTemp
    }

    loadData = (data) => {

        // if (!Util.checkISHR() && !Util.checkCanViewRequest(data.empNo)) {
        //     Util.goTo('/Access');
        //     return;
        // }
        let vacType = this.state.vacTypesList.filter(element => element.id + "" === data.transType + "")
        if (vacType && vacType.length > 0)
            vacType = vacType[0]

        let paidVacType = this.state.paidVacTypeList.filter(element => element.pos + "" === data.leaveType + "")
        if (paidVacType && paidVacType.length > 0)
            paidVacType = paidVacType[0]

        let requestDate = new Date(data.insDate)
        let fromDate = new Date(data.fromDate);
        let toDate = new Date(data.toDate);

        let actualFromDate = ''
        if (data.actualFromDate)
            actualFromDate = new Date(data.actualFromDate)

        let actualToDate = ''
        if (data.actualToDate)
            actualToDate = new Date(data.actualToDate);

        let actualDays = ''
        if (data.actualDays)
            actualDays = data.actualDays;

        let currentBalance = ''
        if (data.currentBalance)
            currentBalance = data.currentBalance
        let yearBalance = ''
        if (data.yearBalance)
            yearBalance = data.yearBalance

        this.setState({
            mode: 'view',
            isDesktop: this.updatePredicate(),
            dataObject: {
                ...this.state.dataObject,
                id: data.serial,
                absentCode: data.absentCode,
                empCode: data.empNo,
                empName: data.empName,
                requestDate: requestDate,
                vacType: vacType,
                paidVacType: paidVacType,
                fromDate: fromDate,
                toDate: toDate,
                daysNo: data.dayCaption,
                address: data.address,
                phone: data.phone,
                notes: data.notes,

                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: data.AttachValue,

                currentBalance: currentBalance,
                yearBalance: yearBalance,

                actualFromDate: actualFromDate ? actualFromDate : '',
                actualToDate: actualToDate ? actualToDate : '',
                actualDays: actualDays ? actualDays : '',

                showAlteEmp: data.alternativeEmp && data.alternativeEmp.trim().length > 0 ? true : false,
                showAlteEmp2: data.alternativeEmp2 && data.alternativeEmp2.trim().length > 0 ? true : false

            }
            , loading: false
        })
    }

    handleChange = (event) => {
        if (event.target.files.length > 0) {
            let _uploadedFile = URL.createObjectURL(event.target.files[0])
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.target.files[0].size > 4 * 1024 * 1024) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                document.getElementById('file-upload').value = ''
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.target.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }
    handleChangePrime = (event) => {
        if (event.files.length > 0) {
            let _uploadedFile = event.files[0];
            let _isUpdatedFile = this.state.dataObject.file_id > 0 ? true : false
            if (event.files[0].size > 5 * 1000 * 1000) {
                Util.showErrorMsg(this.messages, $.strings.fileSizeError);
                return
            }
            this.setState({
                dataObject: {
                    ...this.state.dataObject, uploadedFile: _uploadedFile, uploadedFileBytes: null,
                    uploadedFile2: event.files[0], isUpdatedFile: _isUpdatedFile
                }
            }, () => this.setSubscriberImgSrc())
        }
    }

    setSubscriberImgSrc = () => {
        // if (this.state.dataObject.photo_id > 0 && this.state.dataObject.imgBytes !== null && this.state.dataObject.imgBytes.length > 0) {
        //     return `data:image/jpeg;base64,${this.state.dataObject.imgBytes}`
        // }
        // else {
        //     return this.state.dataObject.img
        // }
        alert($.strings.fileUploaded)
    }

    wfTransDetails = async () => {
        let id = Util.encryptStringToHexa(this.state.dataObject.id);
        await this.wfTransDlg.show(1, id)
    }
    onShowAttachment = async () => {
        await this.dlgAttachments.show(1, this.state.dataObject.id)
    }

    onPrint = async () => {
        this.printVacationForm = await Util.GetSettingById(209);
        switch (this.printVacationForm) {
            case "0":
                Util.goToNewTab('../../../PrintVacations/' + this.props.match.params.id);
                break;
            case "1":
                Util.goToNewTab('../../../PrintVacations2/' + this.props.match.params.id);
                break;
            case "2":
                Util.goToNewTab('../../../PrintVacations3/' + this.props.match.params.id);
                break;
            default: {
                Util.goToNewTab('../../../PrintVacations/' + this.props.match.params.id);
                break;
            }
        }
    }

    checkSickVacationPercent = async (fromDate, toDate, empCode, vacDaysCount) => {
        let objResult = { result: false, errorText: '' }
        let obj = {
            fromDate: fromDate,
            toDate: toDate,
            empCode: empCode,
            vacDaysCount: vacDaysCount
        }
        let res = await http.postMultipartAxios(http.actions.FingerPrint.urlCheckSickVacationPercent, obj, null, null, 0);
        if (res.status === 200) {
            objResult = res.data;
        }
        return objResult
    }

    onSave = async (checkSickVacationPercent, checkVacationEmpMax) => {
        if (!this.validateField()) {
            this.setState({ loading: false })
            return false;
        }
        this.messages.clear();
        if (checkSickVacationPercent && this.state.dataObject.vacType.id + "" === "6") {
            // فحص نسب الاجازة المرضية
            let objResult = await this.checkSickVacationPercent(this.state.dataObject.fromDate, this.state.dataObject.toDate, this.state.dataObject.empCode, this.state.dataObject.daysNo);
            console.log(objResult)
            if (objResult.result && objResult.allowRequest) {
                this.popupHasCalled();
                this.setState({ loading: false, confirmBoxVisible: true }, () => {
                    this.confirmBox.show(
                        async () => {
                            this.popupHasClosed();
                            let res = await this.onSave(false, true);
                            this.setState({ confirmBoxVisible: false });
                            return res;
                        },
                        () => {
                            this.popupHasClosed();
                            this.setState({ confirmBoxVisible: false });
                        }, undefined,
                        objResult.errorText
                    );
                })
                return;
            }
            else if (objResult.result && !objResult.allowRequest) {
                Util.showErrorMsg(this.messages, objResult.errorText);
                return;
            }
        }
        this.setState({ loading: true })
        let files = [] // ملفات المرفقات
        let file = this.state.dataObject.uploadedFile2

        let obj = {
            serial: !this.state.dataObject.id ? 0 : this.state.dataObject.id,
            empcode: this.state.dataObject.empCode,
            transType: this.state.dataObject.vacType.id,
            transTypeText: this.state.dataObject.vacType.name,
            leaveType: this.state.dataObject.paidVacType ? this.state.dataObject.paidVacType.pos : 0,
            notes: this.state.dataObject.notes,
            alternativeEmp: this.state.dataObject.altEmp ? this.state.dataObject.altEmp.code : "",
            alternativeEmp2: this.state.dataObject.altEmp2 ? this.state.dataObject.altEmp2.code : "",
            address: this.state.dataObject.address,
            phone: this.state.dataObject.phone,
            empNo: this.state.dataObject.empCode,
            fromDate: this.state.dataObject.fromDate,
            toDate: this.state.dataObject.toDate,
            userCode: localStorage.getItem("useridHR"),
            checkVacationRequestPeriod: true,
            checkVacationEmpMax: checkVacationEmpMax
        }

        let res = await http.postMultipartAxios(http.actions.HolidaysReq.urlPostHolidayReq, obj, file, files, 0);
        if (res.status === 200) {
            if (res.data && res.data.success) {

                if (res.data.errorText)
                    Util.showSuccessMsg(this.messages, res.data.errorText);
                else
                    Util.showSuccessMsg(this.messages);
                this.resetFields()
                this.hash = this.hashState()
                this.setState({ loading: false })
                return true
            }
            else {

                let obj = res.data;
                if (obj.objOtherData && !obj.objOtherData.success) {
                    if (!obj.objOtherData.isForDirectManager) {
                        if (obj.objOtherData.actionId === 1) {
                            this.setState({ loading: false })
                            Util.confirmDialog(obj.objOtherData.errorText, 'pi pi-question-circle',
                                async () => { await this.onSave(false, false); },
                                () => { }
                            );
                            return;
                        }
                        else {
                            Util.showErrorMsg(this.messages, obj.objOtherData.errorText);
                            this.setState({ loading: false })
                            return false
                        }
                    }
                    else {
                        this.setState({ loading: false });
                        await this.dlgDMVacDecision.show(obj.objOtherData.errorText);
                        return;
                    }
                }
                else {
                    if (res.data.errorText !== "") {
                        Util.showErrorMsg(this.messages, res.data.errorText);
                        this.setState({ loading: false })
                        return false
                    }
                }
            }
        }
        if (res.data.errorText !== "") {
            Util.showErrorMsg(this.messages, res.data.errorText);
            this.setState({ loading: false })
            return false
        }
        else {
            Util.showErrorMsg(this.messages);
            this.setState({ loading: false })
            return false
        }
    }

    onDMAccept = async (type) => {
        switch (type) {
            case "yes":
                await this.onSave(false, false);
                break;
            case "details":
                await this.dlgVacEmpDetails.show(this.state.dataObject.empCode, this.state.dataObject.fromDate, this.state.dataObject.toDate)
                break;
            default:
                break;
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                ...this.state.dataObject,
                id: 0,
                absentCode: '',
                requestDate: new Date(),
                vacType: { id: 7, name: $.strings.vacations.annualVac },
                paidVacType: undefined,
                fromDate: new Date(),
                toDate: new Date(),
                daysNo: '1',
                address: '',
                phone: '',
                notes: '',
                uploadedFile: null,
                uploadedFile2: null,
                uploadedFileBytes: null,
                file_id: 0,
                status: 1,

                showAlteEmp: false,
                showAlteEmp2: false
            },
            formErrors: { empCode: '', empName: '', vacType: '', paidVacType: '', fromDate: '', toDate: '', notes: '' },
        }
            //, () => document.getElementById('file-upload').value = ''
        )
    }
}