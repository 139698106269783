import React from "react";
import styles from './SystemSettings.module.scss';
import DataGrid from '../../Components/DataGridView/DataGridView';
import Card from "../../Components/Card";
import SiteMap from "../../Components/SiteMap";
import ProgressSpinner from "../../Components/ProgressSpinner";
import Util from "../../Util";
import Input from '../../Components/Input';
import Spinner from '../../Components/Spinner';
import Dropdown from "../../Components/Dropdown";
import AlertBox from "../../Components/AlertBox";
import ButtonsBarLookupList from '../../Components/ButtonsBarLookupList';
import http from '../../Api/http';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ScrollTop from '../ScrollButton';
import { Fieldset } from 'primereact/fieldset';
import Checkbox from "../../Components/Checkbox";
import VacDaysSettings from "./VacDaysSettings";
import Messages from "../../Components/Messages";
import ImagePicker from "../../Components/ImagePicker/ImagePicker";
import RadioButton from "../../Components/RadioButton";
import { async } from "regenerator-runtime";

const $ = window.$;

export default class SystemSettings extends React.Component {
    constructor(props) {
        super(props);
        this.vacationsPrintFormsList = [
            { id: 0, name: $.strings.systemSettings.form1 },
            { id: 1, name: $.strings.systemSettings.form2 },
            { id: 2, name: $.strings.systemSettings.form3 }
        ];
        this.holidayList = [
            { id: 6, name: $.strings.weekDays.friday },
            { id: 5, name: $.strings.weekDays.saturday },
            { id: 4, name: $.strings.weekDays.sunday },
            { id: 3, name: $.strings.weekDays.monday },
            { id: 2, name: $.strings.weekDays.tuesday },
            { id: 1, name: $.strings.weekDays.wednesday },
            { id: 0, name: $.strings.weekDays.thursday },
        ]
        this.holidayList2 = [
            { id: -1, name: $.strings.systemSettings.none },
            { id: 6, name: $.strings.weekDays.friday },
            { id: 5, name: $.strings.weekDays.saturday },
            { id: 4, name: $.strings.weekDays.sunday },
            { id: 3, name: $.strings.weekDays.monday },
            { id: 2, name: $.strings.weekDays.tuesday },
            { id: 1, name: $.strings.weekDays.wednesday },
            { id: 0, name: $.strings.weekDays.thursday },
        ]
        this.annualVacationBalanceList = [
            { id: 1, name: $.strings.systemSettings.currentBalance },
            { id: 2, name: $.strings.systemSettings.annualBalance },
            { id: 3, name: $.strings.systemSettings.always },
        ]
        this.hijriDateList = [
            { id: 0, name: $.strings.systemSettings.notUsed },
            { id: 1, name: $.strings.systemSettings.hijry },
            { id: 2, name: $.strings.systemSettings.umAlqura },
        ]
        this.specialHandleList = [
            { id: 0, name: $.strings.systemSettings.nothing },
            { id: 1, name: $.strings.systemSettings.specialOverTime },
            { id: 2, name: $.strings.systemSettings.specialLeave },
        ]
        this.overTimeProcessList = [
            { id: 0, name: $.strings.systemSettings.afterPeriod },
            { id: 1, name: $.strings.systemSettings.afterWorkHours },
        ]
        this.overTimeProcessAfterList = []
        this.minutePercentList = []
        this.overTimePercentMorningList = [
            { id: "=1", name: $.strings.systemSettings.none },
        ]
        this.paperTypesList = [
            { id: 0, name: 'Custom' },
            { id: 1, name: 'Letter' },
            { id: 8, name: 'A3' },
            { id: 9, name: 'A4' },
            { id: 11, name: 'A5' },
        ]
        this.fontNameList = [
            { id: 0, name: "Simplified Arabic" },
            { id: 1, name: "Simplified Arabic Backslanted" },
            { id: 2, name: "Simplified Arabic Fixed" },
            { id: 3, name: "Andalus" },
            { id: 4, name: "Arabic Transparent" },
            { id: 5, name: "Traditional Arabic" }
        ]
        this.fontSizeList = [
            { id: 8, name: "8" },
            { id: 9, name: "9" },
            { id: 10, name: "10" },
            { id: 11, name: "11" },
            { id: 12, name: "12" },
            { id: 13, name: "13" },
            { id: 14, name: "14" },
            { id: 15, name: "15" },
            { id: 16, name: "16" },
            { id: 17, name: "17" },
            { id: 18, name: "18" },
            { id: 19, name: "19" },
            { id: 20, name: "20" },
        ]
        this.linesList = [
            { id: 0, name: "بلا" },
            { id: 1, name: "----------------------" },
            { id: 2, name: "===========" }
        ]
        this.bordersList = [
            { id: 0, name: $.strings.systemSettings.none }, // no border
            { id: 1, name: $.strings.systemSettings.background }, // border with gray background
            { id: 2, name: $.strings.systemSettings.withoutBackground }] // border without background
        this.state = {
            isDesktop: window.innerWidth > 1250,
            formErrors: {},
            dataObject: {
                coName: undefined,
                coEnName: undefined,
                address: undefined,
                enAddress: undefined,
                decimalsCount: undefined,
                mainCurr: undefined,
                workHours: undefined,
                coCode: undefined,
                empCodeLen: undefined,
                rawatExist: false,
                hijriDate: undefined,
                formate: '',
                evaluationDirectorEmail: '',
                showRejectedRequests: false,
                showTimesRequetsReports: false,
                showAlterEmpRequetsReports: false,
                sendEmailDelegatedEmp: false,
                sendEmailToDMWhenHRHAndleRequest: false,
                showOnlyApprovedSalarySlip: false,
                showMyStaffOnlyInDelegation: false,
                deductUnpaidVacationFromEndServicesBalance: false,
                calculteEmpEndOfServicesAccordingToLaborLaw: false,
                showAttendanceAndUnpaidLoansInSalarySlip: false,
                showDataDirectlyInReports: false,
                showRetiredEmployeesInStaff: false,
                leaveAndNoFingerPrintAllwedWithVacation: false,
                allowingAnnualVacationRequestAccordingToTheBalance: 1,
                hideUnpaidVacation: false,

                firstHoliday: undefined,
                secondHoliday: undefined,
                specialHandle: undefined,
                printVacationForm: undefined,
                calcAllHolidayFromVacation: undefined,
                calcWeekHolidayFromVacation: undefined,
                calcFormalHolidayFromVacation: undefined,
                discountVacationWithoutPayfromVacations: undefined,
                docsRequiredInSikVacRequest: undefined,
                denyRequestForRejectedPeriod: undefined,
                addressRequired: undefined,
                lCDays: undefined,
                pringingIn: undefined,
                vacBefore: undefined,
                preventRequest: undefined,
                retroactivly: undefined,
                annualVacationsList: [
                    { id: 140, YearsCount: 1, AnnualDaysCount: 0 },
                    { id: 142, YearsCount: 5, AnnualDaysCount: 0 },
                    { id: 144, YearsCount: 10, AnnualDaysCount: 0 },
                    { id: 146, YearsCount: 0, AnnualDaysCount: 0 },
                    { id: 148, YearsCount: 0, AnnualDaysCount: 0 },
                ],
                sickVacationsList: [
                    { id: 150, DaysCount: 1, DiscountPercent: 0 },
                    { id: 152, DaysCount: 5, DiscountPercent: 0 },
                    { id: 154, DaysCount: 10, DiscountPercent: 0 },
                    { id: 156, DaysCount: 0, DiscountPercent: 0 },
                ],


                discountAllowedPeriodFromOverTime: undefined,
                noOverTimeForWorkHoursEmp: undefined,
                overTimePercent: undefined,
                overTimePercentInHoliday: undefined,
                overTimeProcess: undefined,
                overTimeProcessAfter: undefined,
                minutePercent: undefined,
                overTimePercentMorning: undefined,

                ignoreLeaves: undefined,
                beforeIn: undefined,
                afterIn: undefined,
                beforeOut: undefined,
                afterOut: undefined,

                failedLoginCount: undefined,
                changePwdEvery: undefined,
                editShiftFrom: undefined,
                editShiftTo: undefined,
                editShiftPrevMonth: undefined,
                allowedLeavesCount: undefined,
                allowedLeavesCountInDay: 1,
                leavesHoursCount: undefined,
                altEmpRequired: undefined,
                printSaveBox: undefined,
                specialLeaveTypeInHrApprove: undefined,


                arabicImageBase64: undefined,
                englishImageBase64: undefined,

                arabicImageFile: undefined,
                englishImageFile: undefined,

                headerType: undefined, // 1: Text, 2: image; RSetup ID: 226
                arabicImage: undefined, // RSetup ID: 227
                englishImage: undefined, // RSetup ID: 228
                printVatReg: undefined, // RSetup ID: 229
                flipPageLandscap: undefined,// RSetup ID: 230
                paperType: undefined, // RSetup ID: 231

                // طباعة اسم وعنوان الشركة
                printCoNameReports: undefined,// RSetup ID: 232 في التقارير
                printCoNameVouchers: undefined, // RSetup ID: 233 في السندات

                // 1: حسب وتجهة التطبيق
                // 2: باللغتين
                coNameAddressLang: undefined, // RSetup ID: 234, 

                // الهوامش
                rightMargin: undefined, // RSetup ID: 235
                leftMargin: undefined, // RSetup ID: 236
                topMargin: undefined, // RSetup ID: 237
                bottomMargin: undefined, // RSetup ID: 238

                // هوامش الخلايا
                verMargin: undefined, // RSetup ID: 239
                horMargin: undefined, // RSetup ID: 240

                // انواع الخطوط
                coFontName: undefined, // RSetup ID: 241
                coFontSize: undefined, // RSetup ID: 242
                addressFontName: undefined, // RSetup ID: 243
                addressFontSize: undefined, // RSetup ID: 244
                vatRegFontName: undefined, // RSetup ID: 245
                vatRegFontSize: undefined, // RSetup ID: 246
                dataFontName: undefined, // RSetup ID: 247
                dataFontSize: undefined, // RSetup ID: 248

                // التسطير
                line: undefined, // RSetup ID: 249
                // اطار محيط
                border: undefined, // RSetup ID: 250
                arabicImageChanged: false,
                englishImageChanged: false,


                SessionTimoutMinutes: 20,
            }
        }
    }

    componentDidMount = async () => {
        if (!$.license.Att_Module) {
            Util.goTo('/License');
            return;
        }
        if (!Util.checkISHR()) {
            Util.goTo('/Access');
            return;
        }
        for (var i = 0; i <= 300; i++) {
            this.overTimeProcessAfterList.push({ id: i + "", name: i + "" });
            this.minutePercentList.push({ id: i + "", name: i + "" });
            this.overTimePercentMorningList.push({ id: i + "", name: i + "" });
        }

        let defaultSpecialLeaveTypeInHrApprove = undefined;
        this.leavesTypes = await this.getMultiFlByType(22);
        if (this.leavesTypes) {
            defaultSpecialLeaveTypeInHrApprove = this.leavesTypes.find(e => e.pos + "" === "12");
        }


        let _isDesktop = window.innerWidth > 1250;
        this.setState({
            dataObject: { ...this.state.dataObject, specialLeaveTypeInHrApprove: defaultSpecialLeaveTypeInHrApprove, },
            isDesktop: _isDesktop, loading: false
        }, async () => await this.getData())

    }

    handleUserInput = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ dataObject: { ...this.state.dataObject, [name]: value } });
    }

    render = () => {
        const gridStyle = { maxHeight: '320px' }
        let siteMapItems = [
            { label: $.strings.links.staticFilesAndSettings },
            { label: $.strings.systemSettings.title }
        ];
        const siteMapHome = { icon: 'pi pi-home', url: "/" }

        return <div ref="divMain" >
            {
                this.state.confirmBoxVisible &&
                <AlertBox ref={e => this.confirmBox = e} header={$.strings.appName} defaultButton={Util.defaultButton.No} />
            }
            {
                this.state.alertBoxVisible &&
                <AlertBox ref={e => this.alertBox = e} header={$.strings.appName} yesCaption={$.strings.ok} />
            }
            <ProgressSpinner loading={this.state.loading} />
            <Card>
                <SiteMap model={siteMapItems} home={siteMapHome} />
            </Card>
            <Card>
                <ButtonsBarLookupList onRefresh={this.getData} onSave={this.onSave} />
                <Messages innerRef={(el) => this.messages = el} />
            </Card>
            <VacDaysSettings vacType="annual" ref={e => this.dlgAnnualVacationSettings = e} onSuccess={this.annaulVacationEdit} />
            <VacDaysSettings vacType="sick" ref={e => this.dlgSickVacationSettings = e} onSuccess={this.sickVacationEdit} />
            <Accordion multiple activeIndex={[-1]}>
                <AccordionTab header={$.strings.systemSettings.generalSettings}>
                    <Fieldset legend={$.strings.systemSettings.companySettings}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="coName"
                                    value={this.state.dataObject.coName}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.coName}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="coEnName"
                                    value={this.state.dataObject.coEnName}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.coEnName}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="address"
                                    value={this.state.dataObject.address}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.companyAddress}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="enAddress"
                                    value={this.state.dataObject.enAddress}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.companyEnglishAddress}
                                />
                            </div>
                        </div>
                    </Fieldset>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="decimalsCount"
                                value={this.state.dataObject.decimalsCount}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.decimalDigitsCount}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="mainCurr"
                                value={this.state.dataObject.mainCurr}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.mainCurrency}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="workHours"
                                value={this.state.dataObject.workHours}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.workHours}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="coCode"
                                value={this.state.dataObject.coCode}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.companyNo}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="empCodeLen"
                                value={this.state.dataObject.empCodeLen}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.empCardNoLength}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="rawatExist"
                                caption={$.strings.systemSettings.hrSystemFound}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, rawatExist: e.checked } })}
                                checked={this.state.dataObject.rawatExist} />
                        </div>
                    </div>
                    <Fieldset legend={$.strings.systemSettings.hijryDate}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="hijriDate"
                                    value={this.state.dataObject.hijriDate}
                                    options={this.hijriDateList}
                                    caption={$.strings.systemSettings.hijryDate}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="formate"
                                    value={this.state.dataObject.formate}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.dateFormat}
                                />
                            </div>
                        </div>
                    </Fieldset>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="evaluationDirectorEmail"
                                value={this.state.dataObject.evaluationDirectorEmail}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.emailOfEvaluationManager}
                                formErrors={this.state.formErrors}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showRejectedRequests"
                                caption={$.strings.systemSettings.showCanceledRequest}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showRejectedRequests: e.checked } })}
                                checked={this.state.dataObject.showRejectedRequests} />
                        </div>
                        {/* /// */}
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showTimesRequetsReports"
                                caption={$.strings.systemSettings.showTimeColumnsInRequestsReport}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showTimesRequetsReports: e.checked } })}
                                checked={this.state.dataObject.showTimesRequetsReports} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showAlterEmpRequetsReports"
                                caption={$.strings.systemSettings.showSubstitiuteColumnsInRequestsReport}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showAlterEmpRequetsReports: e.checked } })}
                                checked={this.state.dataObject.showAlterEmpRequetsReports} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="sendEmailDelegatedEmp"
                                caption={$.strings.systemSettings.sendEmailToDelegatedEmployee}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sendEmailDelegatedEmp: e.checked } })}
                                checked={this.state.dataObject.sendEmailDelegatedEmp} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="sendEmailToDMWhenHRHAndleRequest"
                                caption={$.strings.systemSettings.sendEmailToDirectManagerInRequestApproval}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, sendEmailToDMWhenHRHAndleRequest: e.checked } })}
                                checked={this.state.dataObject.sendEmailToDMWhenHRHAndleRequest} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showOnlyApprovedSalarySlip"
                                caption={$.strings.systemSettings.showOnlyApprovedSalarySlip}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showOnlyApprovedSalarySlip: e.checked } })}
                                checked={this.state.dataObject.showOnlyApprovedSalarySlip} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showMyStaffOnlyInDelegation"
                                caption={$.strings.systemSettings.showMyStaffOnlyInDelegation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showMyStaffOnlyInDelegation: e.checked } })}
                                checked={this.state.dataObject.showMyStaffOnlyInDelegation} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showAttendanceAndUnpaidLoansInSalarySlip"
                                caption={$.strings.systemSettings.showAttendanceAndUnpaidLoansInSalarySlip}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showAttendanceAndUnpaidLoansInSalarySlip: e.checked } })}
                                checked={this.state.dataObject.showAttendanceAndUnpaidLoansInSalarySlip} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showDataDirectlyInReports"
                                caption={$.strings.systemSettings.showDataDirectlyInReports}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showDataDirectlyInReports: e.checked } })}
                                checked={this.state.dataObject.showDataDirectlyInReports} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="showRetiredEmployeesInStaff"
                                caption={$.strings.systemSettings.showRetiredEmployeesInStaff}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, showRetiredEmployeesInStaff: e.checked } })}
                                checked={this.state.dataObject.showRetiredEmployeesInStaff} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="deductUnpaidVacationFromEndServicesBalance"
                                caption={$.strings.systemSettings.deductUnpaidVacationFromEndServicesBalance}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, deductUnpaidVacationFromEndServicesBalance: e.checked } })}
                                checked={this.state.dataObject.deductUnpaidVacationFromEndServicesBalance} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="calculteEmpEndOfServicesAccordingToLaborLaw"
                                caption={$.strings.systemSettings.calculteEmpEndOfServicesAccordingToLaborLaw}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, calculteEmpEndOfServicesAccordingToLaborLaw: e.checked } })}
                                checked={this.state.dataObject.calculteEmpEndOfServicesAccordingToLaborLaw} />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.systemSettings.vacationsSettings}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="firstHoliday"
                                value={this.state.dataObject.firstHoliday}
                                options={this.holidayList}
                                caption={$.strings.systemSettings.firstHoliday}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="secondHoliday"
                                value={this.state.dataObject.secondHoliday}
                                options={this.holidayList2}
                                caption={$.strings.systemSettings.secondHoliday}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="specialHandle"
                                value={this.state.dataObject.specialHandle}
                                options={this.specialHandleList}
                                caption={$.strings.systemSettings.specialHandle}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="printVacationForm"
                                value={this.state.dataObject.printVacationForm}
                                options={this.vacationsPrintFormsList}
                                caption={$.strings.systemSettings.printVacationForm}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="calcAllHolidayFromVacation"
                                caption={$.strings.systemSettings.calcAllHolidayFromVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, calcAllHolidayFromVacation: e.checked } })}
                                checked={this.state.dataObject.calcAllHolidayFromVacation} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="calcWeekHolidayFromVacation"
                                caption={$.strings.systemSettings.calcWeekHolidayFromVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, calcWeekHolidayFromVacation: e.checked } })}
                                checked={this.state.dataObject.calcWeekHolidayFromVacation} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="calcFormalHolidayFromVacation"
                                caption={$.strings.systemSettings.calcFormalHolidayFromVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, calcFormalHolidayFromVacation: e.checked } })}
                                checked={this.state.dataObject.calcFormalHolidayFromVacation} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="discountVacationWithoutPayfromVacations"
                                caption={$.strings.systemSettings.discountVacationWithoutPayfromVacations}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, discountVacationWithoutPayfromVacations: e.checked } })}
                                checked={this.state.dataObject.discountVacationWithoutPayfromVacations} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="docsRequiredInSikVacRequest"
                                caption={$.strings.systemSettings.docsRequiredInSikVacRequest}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, docsRequiredInSikVacRequest: e.checked } })}
                                checked={this.state.dataObject.docsRequiredInSikVacRequest} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="denyRequestForRejectedPeriod"
                                caption={$.strings.systemSettings.denyRequestForRejectedPeriod}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, denyRequestForRejectedPeriod: e.checked } })}
                                checked={this.state.dataObject.denyRequestForRejectedPeriod} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="addressRequired"
                                caption={$.strings.systemSettings.addressRequired}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, addressRequired: e.checked } })}
                                checked={this.state.dataObject.addressRequired} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="lCDays"
                                keyfilter="pnum"
                                value={this.state.dataObject.lCDays}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.lCDays}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="pringingIn"
                                keyfilter="pnum"
                                value={this.state.dataObject.pringingIn}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.pringingIn}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="vacBefore"
                                keyfilter="pnum"
                                value={this.state.dataObject.vacBefore}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.vacBefore}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Checkbox id="preventRequest"
                                caption={$.strings.systemSettings.preventRequest}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, preventRequest: e.checked } })}
                                checked={this.state.dataObject.preventRequest} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Checkbox id="retroactivly"
                                caption={$.strings.systemSettings.retroactivly}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, retroactivly: e.checked } })}
                                checked={this.state.dataObject.retroactivly} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="leaveAndNoFingerPrintAllwedWithVacation"
                                caption={$.strings.systemSettings.leaveAndNoFingerPrintAllwedWithVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, leaveAndNoFingerPrintAllwedWithVacation: e.checked } })}
                                checked={this.state.dataObject.leaveAndNoFingerPrintAllwedWithVacation} />
                        </div>
                        <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                            <Dropdown
                                id="allowingAnnualVacationRequestAccordingToTheBalance"
                                value={this.state.dataObject.allowingAnnualVacationRequestAccordingToTheBalance}
                                options={this.annualVacationBalanceList}
                                caption={$.strings.systemSettings.allowingAnnualVacationRequestAccordingToTheBalance}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="hideUnpaidVacation"
                                caption={$.strings.systemSettings.hideUnpaidVacation}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, hideUnpaidVacation: e.checked } })}
                                checked={this.state.dataObject.hideUnpaidVacation} />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <span className={styles.captionStyle}>{$.strings.systemSettings.annualVacations}</span>
                            <DataGrid
                                ref={e => this.gridAnnualVacations = e}
                                style={gridStyle}
                                isReport={false}
                                showContextMenu={false}
                                theme="default-light"
                                idProperty="id"
                                scheme={this.getScheme()}
                                dataSource={this.state.dataObject.annualVacationsList}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <span className={styles.captionStyle}>{$.strings.systemSettings.sickVacations}</span>
                            <DataGrid
                                ref={e => this.gridSickVacations = e}
                                style={gridStyle}
                                isReport={false}
                                showContextMenu={false}
                                theme="default-light"
                                idProperty="id"
                                scheme={this.getSchemeSick()}
                                dataSource={this.state.dataObject.sickVacationsList}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.systemSettings.overTimeSettings}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Checkbox id="discountAllowedPeriodFromOverTime"
                                caption={$.strings.systemSettings.discountAllowedPeriodFromOverTime}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, discountAllowedPeriodFromOverTime: e.checked } })}
                                checked={this.state.dataObject.discountAllowedPeriodFromOverTime} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Checkbox id="noOverTimeForWorkHoursEmp"
                                caption={$.strings.systemSettings.noOverTimeForWorkHoursEmp}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, noOverTimeForWorkHoursEmp: e.checked } })}
                                checked={this.state.dataObject.noOverTimeForWorkHoursEmp} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="overTimePercent"
                                keyfilter="pnum"
                                value={this.state.dataObject.overTimePercent}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.overTimePercent}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="overTimePercentInHoliday"
                                keyfilter="pnum"
                                value={this.state.dataObject.overTimePercentInHoliday}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.overTimePercentInHoliday}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="overTimeProcess"
                                value={this.state.dataObject.overTimeProcess}
                                options={this.overTimeProcessList}
                                caption={$.strings.systemSettings.overTimeProcess}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="overTimeProcessAfter"
                                value={this.state.dataObject.overTimeProcessAfter}
                                options={this.overTimeProcessAfterList}
                                caption={$.strings.systemSettings.overTimeProcessAfter}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="minutePercent"
                                value={this.state.dataObject.minutePercent}
                                options={this.minutePercentList}
                                caption={$.strings.systemSettings.minutePercent}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Dropdown
                                id="overTimePercentMorning"
                                value={this.state.dataObject.overTimePercentMorning}
                                options={this.overTimePercentMorningList}
                                caption={$.strings.systemSettings.overTimePercentMorning}
                                onChange={this.handleUserInput}
                                optionLabel="name"
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.systemSettings.leavesSettings}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="ignoreLeaves"
                                keyfilter="pnum"
                                value={this.state.dataObject.ignoreLeaves}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.ignoreLeaves}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="allowedLeavesCount"
                                keyfilter="pnum"
                                value={this.state.dataObject.allowedLeavesCount}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.allowedLeavesCount}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="leavesHoursCount"
                                keyfilter="pnum"
                                value={this.state.dataObject.leavesHoursCount}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.leavesHoursCount}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="allowedLeavesCountInDay"
                                keyfilter="pnum"
                                value={this.state.dataObject.allowedLeavesCountInDay}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.allowedLeavesCountInDay}
                            />
                        </div>
                    </div>
                    <Fieldset legend={$.strings.systemSettings.allowancePeriod}>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="beforeIn"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.beforeIn}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.beforeIn}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="afterIn"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.afterIn}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.afterIn}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="beforeOut"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.beforeOut}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.beforeOut}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Input
                                    id="afterOut"
                                    keyfilter="pnum"
                                    value={this.state.dataObject.afterOut}
                                    autofocus={true}
                                    autocomplete="off"
                                    onChange={this.handleUserInput}
                                    caption={$.strings.systemSettings.afterOut}
                                />
                            </div>
                            <div className="p-col-12 p-lg-6 p-sm-12">
                                <Dropdown
                                    id="specialLeaveTypeInHrApprove"
                                    value={this.state.dataObject.specialLeaveTypeInHrApprove}
                                    options={this.leavesTypes}
                                    caption={$.strings.systemSettings.specialLeaveTypeInHrApprove}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                    optionLabelLang2="ename"
                                    filter
                                    filterBy="name,ename"
                                />
                            </div>
                        </div>
                    </Fieldset>
                </AccordionTab>
                <AccordionTab header={$.strings.systemSettings.accountSettings}>
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="failedLoginCount"
                                keyfilter="pnum"
                                value={this.state.dataObject.failedLoginCount}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.failedLoginCount}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="changePwdEvery"
                                keyfilter="pnum"
                                value={this.state.dataObject.changePwdEvery}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.changePwdEvery}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="editShiftFrom"
                                keyfilter="pnum"
                                value={this.state.dataObject.editShiftFrom}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.editShiftFrom}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="editShiftTo"
                                keyfilter="pnum"
                                value={this.state.dataObject.editShiftTo}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.editShiftTo}
                            />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="editShiftPrevMonth"
                                caption={$.strings.systemSettings.editShiftPrevMonth}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, editShiftPrevMonth: e.checked } })}
                                checked={this.state.dataObject.editShiftPrevMonth} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="altEmpRequired"
                                caption={$.strings.systemSettings.altEmpRequired}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, altEmpRequired: e.checked } })}
                                checked={this.state.dataObject.altEmpRequired} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                            <Checkbox id="printSaveBox"
                                caption={$.strings.systemSettings.printSaveBox}
                                onChange={e => this.setState({ dataObject: { ...this.state.dataObject, printSaveBox: e.checked } })}
                                checked={this.state.dataObject.printSaveBox} />
                        </div>
                        <div className="p-col-12 p-lg-6 p-sm-12">
                            <Input
                                id="SessionTimoutMinutes"
                                keyfilter="pint"
                                value={this.state.dataObject.SessionTimoutMinutes}
                                autofocus={true}
                                autocomplete="off"
                                onChange={this.handleUserInput}
                                caption={$.strings.systemSettings.sessionTimoutMinutes}
                            />
                        </div>
                    </div>
                </AccordionTab>
                <AccordionTab header={$.strings.systemSettings.reportPrintSettings}>
                    {/* اسم وعنوان الشركة */}
                    <Card title={$.strings.systemSettings.coNameAddress}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-4 p-lg-12 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <div className="p-grid">
                                    <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                                        <RadioButton
                                            inputId="rbText" value={$.strings.systemSettings.text} name="Status" checked={this.state.dataObject.headerType === "1"}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, headerType: "1" } })}
                                        />
                                    </div>
                                    <div className="p-col-4 p-xl-4 p-lg-4 p-md-4 p-sm-4">
                                        <RadioButton
                                            inputId="rbImage" value={$.strings.systemSettings.image} name="Status" checked={this.state.dataObject.headerType === "2"}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, headerType: "2" } })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                                <ImagePicker
                                    id="227"
                                    alt={$.strings.systemSettings.arabicImage}
                                    title={$.strings.systemSettings.arabicImage}
                                    caption={$.strings.systemSettings.arabicImage}
                                    source={this.state.dataObject.arabicImage}
                                    sourceBytes={this.state.dataObject.arabicImageFile}
                                    onSelectImage={this.onSelectImage}
                                //onRemoveImage={e => this.onRemoveImage("227", true)}
                                />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-6 p-md-12 p-sm-12">
                                <ImagePicker
                                    id="228"
                                    alt={$.strings.systemSettings.englishImage}
                                    title={$.strings.systemSettings.englishImage}
                                    caption={$.strings.systemSettings.englishImage}
                                    source={this.state.dataObject.englishImage}
                                    sourceBytes={this.state.dataObject.englishImageFile}
                                    onSelectImage={this.onSelectImage}
                                //onRemoveImage={e => this.onRemoveImage("228", true)}
                                />
                            </div>
                        </div>
                    </Card>
                    {/*طباعة اسم وعنوان الشركة */}
                    <Card title={$.strings.systemSettings.printCoAddress}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Checkbox id="inReports" caption={$.strings.systemSettings.inReports} checked={this.state.dataObject.printCoNameReports}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, printCoNameReports: e.checked } })} />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Checkbox id="inReports" caption={$.strings.systemSettings.inVouchers} checked={this.state.dataObject.printCoNameVouchers}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, printCoNameVouchers: e.checked } })} />
                            </div>
                            <div className="p-col-12 p-xl-6 p-lg-12 p-md-12 p-sm-12" style={{ alignSelf: 'flex-end' }}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <RadioButton id="rbText"
                                            inputId="coNameAddressLang" value={$.strings.systemSettings.byInterface} name="printCoName"
                                            checked={this.state.dataObject.coNameAddressLang === "1"}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, coNameAddressLang: "1" } })}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <RadioButton id="coNameAddressLang" inputId="rbImage" value={$.strings.systemSettings.twoLanguages} name="printCoName"
                                            checked={this.state.dataObject.coNameAddressLang === "2"}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, coNameAddressLang: "2" } })}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card>
                    {/* خيارات متفرقة أخرى*/}
                    <Card title={$.strings.systemSettings.othersOptions}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-4" style={{ alignSelf: 'flex-end' }}>
                                <Checkbox id="printVatReg" caption={$.strings.systemSettings.printVatReg} checked={this.state.dataObject.printVatReg}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, printVatReg: e.checked } })} />
                            </div>
                            <div className="p-col-12 p-xl-5 p-lg-5 p-md-6 p-sm-8" style={{ alignSelf: 'flex-end' }}>
                                <Checkbox id="flipPageLandscap" caption={$.strings.systemSettings.flipPageLandscap} checked={this.state.dataObject.flipPageLandscap}
                                    onChange={e => this.setState({ dataObject: { ...this.state.dataObject, flipPageLandscap: e.checked } })} />
                            </div>
                            <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                                <Dropdown
                                    id="paperType"
                                    value={this.state.dataObject.paperType}
                                    options={this.paperTypesList}
                                    caption={$.strings.systemSettings.paperType}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    </Card>
                    {/* هوامش الصفحة والخلايا*/}
                    <div className="p-grid">
                        <div className="p-col-12 p-xl-8 p-lg-8 p-md-12 p-sm-12">
                            <Card title={$.strings.systemSettings.pageMargins}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                        <Spinner
                                            id="rightMargin"
                                            value={this.state.dataObject.rightMargin}
                                            caption={$.strings.systemSettings.rightMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, rightMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                        <Spinner
                                            id="leftMargin"
                                            value={this.state.dataObject.leftMargin}
                                            caption={$.strings.systemSettings.leftMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, leftMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                        <Spinner
                                            id="topMargin"
                                            value={this.state.dataObject.topMargin}
                                            caption={$.strings.systemSettings.topMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, topMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-3 p-lg-3 p-md-6 p-sm-12">
                                        <Spinner
                                            id="bottomMargin"
                                            value={this.state.dataObject.bottomMargin}
                                            caption={$.strings.systemSettings.bottomMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, bottomMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="p-col-12 p-xl-4 p-lg-4 p-md-12 p-sm-12">
                            <Card title={$.strings.systemSettings.cellsMargins}>
                                <div className="p-grid">
                                    <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <Spinner
                                            id="verMargin"
                                            value={this.state.dataObject.verMargin}
                                            caption={$.strings.systemSettings.verMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, verMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                    <div className="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12">
                                        <Spinner
                                            id="horMargin"
                                            value={this.state.dataObject.horMargin}
                                            caption={$.strings.systemSettings.horMargin}
                                            onChange={e => this.setState({ dataObject: { ...this.state.dataObject, horMargin: e.value } })}
                                            minFractionDigits={2}
                                            maxFractionDigits={2}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    {/* أنواع الخطوط*/}
                    <Card title={$.strings.systemSettings.fontTypes}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="coFontName"
                                    value={this.state.dataObject.coFontName}
                                    options={this.fontNameList}
                                    caption={$.strings.systemSettings.coFontName}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="coFontSize"
                                    value={this.state.dataObject.coFontSize}
                                    options={this.fontSizeList}
                                    caption={$.strings.systemSettings.coFontSize}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="addressFontName"
                                    value={this.state.dataObject.addressFontName}
                                    options={this.fontNameList}
                                    caption={$.strings.systemSettings.addressFontName}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="addressFontSize"
                                    value={this.state.dataObject.addressFontSize}
                                    options={this.fontSizeList}
                                    caption={$.strings.systemSettings.addressFontSize}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="vatRegFontName"
                                    value={this.state.dataObject.vatRegFontName}
                                    options={this.fontNameList}
                                    caption={$.strings.systemSettings.vatRegFontName}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="vatRegFontSize"
                                    value={this.state.dataObject.vatRegFontSize}
                                    options={this.fontSizeList}
                                    caption={$.strings.systemSettings.vatRegFontSize}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="dataFontName"
                                    value={this.state.dataObject.dataFontName}
                                    options={this.fontNameList}
                                    caption={$.strings.systemSettings.dataFontName}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="dataFontSize"
                                    value={this.state.dataObject.dataFontSize}
                                    options={this.fontSizeList}
                                    caption={$.strings.systemSettings.dataFontSize}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    </Card>
                    <Card title={$.strings.systemSettings.headerSpecialEffects}>
                        <div className="p-grid">
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="line"
                                    value={this.state.dataObject.line}
                                    options={this.linesList}
                                    caption={$.strings.systemSettings.lines}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                            <div className="p-col-12 p-xl-3 p-lg-6 p-md-6 p-sm-12">
                                <Dropdown
                                    id="border"
                                    value={this.state.dataObject.border}
                                    options={this.bordersList}
                                    caption={$.strings.systemSettings.borders}
                                    onChange={this.handleUserInput}
                                    optionLabel="name"
                                />
                            </div>
                        </div>
                    </Card>
                </AccordionTab>
            </Accordion>
            <ScrollTop />
        </div >
    }

    getMultiFlByType = async (type_id) => {
        this.setState({ loading: true })
        let dataTemp = []
        let obj = {
            type: type_id
        }
        let res = await http.getJsonAxios(http.actions.MultiFl.GetMultiFlByType, obj, 0);
        if (res.status === 200) {
            dataTemp = res.data.multiFlList;
        }
        return dataTemp
    }

    popupHasCalled = () => {
        document.removeEventListener("keydown", this.onPageKeyDown);
    };
    popupHasClosed = () => {
        document.addEventListener("keydown", this.onPageKeyDown);
    };

    getData = async () => {
        this.setState({ loading: true })
        let objMain = undefined;
        let annualVacationsList = undefined;
        let sickVacationsList = undefined;
        let obj = {

        }

        let res = await http.getJsonAxios(http.actions.Settings.urlGetSettings, obj, 0);
        if (res.status === 200) {
            if (res.data.success) {
                objMain = res.data;
                annualVacationsList = res.data.annualVacationsList;
                sickVacationsList = res.data.sickVacationsList;
            }
        }

        if (objMain) {
            let hijriDate = this.hijriDateList.find(item => item.id + "" === objMain.hijriDate + "");
            let firstHoliday = this.holidayList.find(item => item.id + "" === objMain.firstHoliday + "");
            let secondHoliday = this.holidayList2.find(item => item.id + "" === objMain.secondHoliday + "");
            let specialHandle = this.specialHandleList.find(item => item.id + "" === objMain.specialHandle + "");
            let printVacationForm = this.vacationsPrintFormsList.find(item => item.id + "" === objMain.printVacationForm + "");
            let overTimeProcess = this.overTimeProcessList.find(item => item.id + "" === objMain.overTimeProcess + "");
            let overTimeProcessAfter = this.overTimeProcessAfterList.find(item => item.id + "" === objMain.overTimeProcessAfter + "");
            let minutePercent = this.minutePercentList.find(item => item.id + "" === objMain.minutePercent + "");
            let overTimePercentMorning = this.overTimePercentMorningList.find(item => item.id + "" === objMain.overTimePercentMorning + "");

            let _specialLeaveTypeInHrApprove = 12;
            if (objMain.specialLeaveTypeInHRApprove !== null && objMain.specialLeaveTypeInHRApprove !== undefined)
                _specialLeaveTypeInHrApprove = objMain.specialLeaveTypeInHRApprove;

            _specialLeaveTypeInHrApprove = this.leavesTypes.find(e => e.pos + "" === _specialLeaveTypeInHrApprove + "");
            let annualVacationBalanceSetting = this.annualVacationBalanceList.find(e => e.id + '' === objMain.allowingAnnualVacationRequestAccordingToTheBalance + '');
            if (!annualVacationBalanceSetting)
                annualVacationBalanceSetting = this.annualVacationBalanceList[0];
            let dataObject = {
                coName: objMain.coName,
                coEnName: objMain.coEnName,
                address: objMain.address,
                enAddress: objMain.enAddress,
                decimalsCount: objMain.decimalsCount,
                mainCurr: objMain.mainCurr,
                workHours: objMain.workHours,
                coCode: objMain.coCode,
                empCodeLen: objMain.empCodeLen,
                rawatExist: objMain.rawatExist,
                hijriDate: hijriDate ? hijriDate : undefined,
                formate: objMain.formate,
                evaluationDirectorEmail: objMain.evaluationDirectorEmail,
                showRejectedRequests: objMain.showRejectedRequests,
                showTimesRequetsReports: objMain.showTimesRequetsReports,
                showAlterEmpRequetsReports: objMain.showAlterEmpRequetsReports,
                sendEmailDelegatedEmp: objMain.sendEmailDelegatedEmp,
                sendEmailToDMWhenHRHAndleRequest: objMain.sendEmailToDMWhenHRHAndleRequest,
                showOnlyApprovedSalarySlip: objMain.showOnlyApprovedSalarySlip,
                showMyStaffOnlyInDelegation: objMain.showMyStaffOnlyInDelegation,
                deductUnpaidVacationFromEndServicesBalance: objMain.deductUnpaidVacationFromEndServicesBalance,
                calculteEmpEndOfServicesAccordingToLaborLaw: objMain.calculteEmpEndOfServicesAccordingToLaborLaw,
                showAttendanceAndUnpaidLoansInSalarySlip: objMain.showAttendanceAndUnpaidLoansInSalarySlip,
                showDataDirectlyInReports: objMain.showDataDirectlyInReports,
                showRetiredEmployeesInStaff: objMain.showRetiredEmployeesInStaff,

                firstHoliday: firstHoliday,
                secondHoliday: secondHoliday,
                specialHandle: specialHandle,
                printVacationForm: printVacationForm,
                calcAllHolidayFromVacation: objMain.calcAllHolidayFromVacation,
                calcWeekHolidayFromVacation: objMain.calcWeekHolidayFromVacation,
                calcFormalHolidayFromVacation: objMain.calcFormalHolidayFromVacation,
                discountVacationWithoutPayfromVacations: objMain.discountVacationWithoutPayfromVacations,
                docsRequiredInSikVacRequest: objMain.docsRequiredInSikVacRequest,
                denyRequestForRejectedPeriod: objMain.denyRequestForRejectedPeriod,
                addressRequired: objMain.addressRequired,
                lCDays: objMain.lCDays,
                pringingIn: objMain.pringingIn,
                vacBefore: objMain.vacBefore,
                preventRequest: objMain.preventRequest,
                retroactivly: objMain.retroactivly,
                leaveAndNoFingerPrintAllwedWithVacation: objMain.leaveAndNoFingerPrintAllwedWithVacation,
                allowingAnnualVacationRequestAccordingToTheBalance: annualVacationBalanceSetting,
                hideUnpaidVacation: objMain.hideUnpaidVacation,
                annualVacationsList: annualVacationsList,
                sickVacationsList: sickVacationsList,

                discountAllowedPeriodFromOverTime: objMain.discountAllowedPeriodFromOverTime,
                noOverTimeForWorkHoursEmp: objMain.noOverTimeForWorkHoursEmp,
                overTimePercent: objMain.overTimePercent,
                overTimePercentInHoliday: objMain.overTimePercentInHoliday,
                overTimeProcess: overTimeProcess,
                overTimeProcessAfter: overTimeProcessAfter,
                minutePercent: minutePercent,
                overTimePercentMorning: overTimePercentMorning,

                ignoreLeaves: objMain.ignoreLeaves,
                beforeIn: objMain.beforeIn,
                afterIn: objMain.afterIn,
                beforeOut: objMain.beforeOut,
                afterOut: objMain.afterOut,

                failedLoginCount: objMain.failedLoginCount,
                changePwdEvery: objMain.changePwdEvery,
                editShiftFrom: objMain.editShiftFrom,
                editShiftTo: objMain.editShiftTo,
                editShiftPrevMonth: objMain.editShiftPrevMonth,
                allowedLeavesCount: objMain.allowedLeavesCount,
                allowedLeavesCountInDay: objMain.allowedLeavesCountInDay,
                leavesHoursCount: objMain.leavesHoursCount,
                altEmpRequired: objMain.altEmpRequired,
                printSaveBox: objMain.printSaveBox,
                specialLeaveTypeInHrApprove: _specialLeaveTypeInHrApprove,

                arabicImageFile: objMain.printSettings.arabicImageByte,
                englishImageFile: objMain.printSettings.englishImageByte,

                headerType: objMain.printSettings.headerType + "", // 1: Text, 2: image; RSetup ID: 226
                arabicImage: objMain.printSettings.arabicImage, // RSetup ID: 227
                englishImage: objMain.printSettings.englishImage, // RSetup ID: 228
                printVatReg: objMain.printSettings.printVatReg, // RSetup ID: 229
                flipPageLandscap: objMain.printSettings.flipPageLandscap,// RSetup ID: 230
                paperType: this.paperTypesList.find(e => e.id + "" === objMain.printSettings.paperType + ""), // RSetup ID: 231

                // طباعة اسم وعنوان الشركة
                printCoNameReports: objMain.printSettings.printCoNameReports,// RSetup ID: 232 في التقارير
                printCoNameVouchers: objMain.printSettings.printCoNameVouchers, // RSetup ID: 233 في السندات

                // 1: حسب وتجهة التطبيق
                // 2: باللغتين
                coNameAddressLang: objMain.printSettings.coNameAddressLang + "", // RSetup ID: 234, 

                // الهوامش
                rightMargin: objMain.printSettings.rightMargin, // RSetup ID: 235
                leftMargin: objMain.printSettings.leftMargin, // RSetup ID: 236
                topMargin: objMain.printSettings.topMargin, // RSetup ID: 237
                bottomMargin: objMain.printSettings.bottomMargin, // RSetup ID: 238

                // هوامش الخلايا
                verMargin: objMain.printSettings.verMargin, // RSetup ID: 239
                horMargin: objMain.printSettings.horMargin, // RSetup ID: 240

                // انواع الخطوط
                coFontName: this.fontNameList.find(e => e.name + "" === objMain.printSettings.coFontName + ""), // RSetup ID: 241
                coFontSize: this.fontSizeList.find(e => e.id + "" === objMain.printSettings.coFontSize + ""), // RSetup ID: 242
                addressFontName: this.fontNameList.find(e => e.name + "" === objMain.printSettings.addressFontName + ""), // RSetup ID: 243
                addressFontSize: this.fontSizeList.find(e => e.id + "" === objMain.printSettings.addressFontSize + ""), // RSetup ID: 244
                vatRegFontName: this.fontNameList.find(e => e.name + "" === objMain.printSettings.vatRegFontName + ""), // RSetup ID: 245
                vatRegFontSize: this.fontSizeList.find(e => e.id + "" === objMain.printSettings.vatRegFontSize + ""), // RSetup ID: 246
                dataFontName: this.fontNameList.find(e => e.name + "" === objMain.printSettings.dataFontName + ""), // RSetup ID: 247
                dataFontSize: this.fontSizeList.find(e => e.id + "" === objMain.printSettings.dataFontSize + ""), // RSetup ID: 248

                // التسطير
                line: this.linesList.find(e => e.id + "" === objMain.printSettings.line + ""), // RSetup ID: 249
                // اطار محيط
                border: this.bordersList.find(e => e.id + "" === objMain.printSettings.border + ""), // RSetup ID: 250

                SessionTimoutMinutes: objMain.SessionTimoutMinutes ? objMain.SessionTimoutMinutes : 0
            }

            this.setState({ dataObject: dataObject, loading: false });
        } else {
            this.setState({ dataObject: {}, loading: false });
        }
    }

    validateField() {
        let fieldValidationErrors = this.state.formErrors;

        // let _target = true;
        // fieldValidationErrors.target = '';
        // if (!this.state.dataObject.target || this.state.dataObject.target.id < 0) {
        //     fieldValidationErrors.target = $.strings.requiredFiled;
        //     _target = false;
        // }

        let _evaluationDirectorEmail = true;
        if (!Util.validateEmail(this.state.dataObject.evaluationDirectorEmail)) {
            fieldValidationErrors.evaluationDirectorEmail = $.strings.requiredFiled;
            _evaluationDirectorEmail = false;
        }


        // let _job = true;
        // if ((this.state.dataObject.target && this.state.dataObject.target.id === 1) &&
        //     (!this.state.dataObject.job || this.state.dataObject.job.id <= 0)) {
        //     fieldValidationErrors.job = $.strings.requiredFiled;
        // }

        // let _section = true;
        // if ((this.state.dataObject.target && this.state.dataObject.target.id === 3) &&
        //     (!this.state.dataObject.section || this.state.dataObject.section.length <= 0)) {
        //     fieldValidationErrors.section = $.strings.requiredFiled;
        //     _section = false;
        // }

        // let _title = true;
        // if (!this.state.dataObject.title || this.state.dataObject.title.length <= 0) {
        //     fieldValidationErrors.title = $.strings.requiredFiled;
        //     _title = false;
        // }

        // let _details = true;
        // let objEditor = this.editor.editorElement.__quill;
        // let editorText = objEditor.getText(0);
        // if (!objEditor || !editorText || editorText.trim().length <= 0) {
        //     fieldValidationErrors.details = $.strings.requiredFiled;
        //     _details = false;
        // }

        // let _endDate = true;
        // if (!this.state.dataObject.endDate || (this.state.dataObject.endDate + "").length <= 0) {
        //     fieldValidationErrors.endDate = $.strings.requiredFiled;
        //     _endDate = false;
        // }

        // let _status = true;
        // if (!this.state.dataObject.status || this.state.dataObject.status.id < 0) {
        //     fieldValidationErrors.status = $.strings.requiredFiled;
        //     _status = false;
        // }

        if (this.state.dataObject.headerType + "" === "2") {
            // صورة
            if (!this.state.dataObject.arabicImage && !this.state.dataObject.arabicImageFile && this.state.dataObject.englishImage && !this.state.dataObject.englishImageFile) {
                Util.showErrorMsg(this.messages, $.strings.systemSettings.arabicImageError)
                return false;
            }
        }

        this.setState({
            formErrors: fieldValidationErrors,
            evaluationDirectorEmailValid: _evaluationDirectorEmail,
            // jobValid: _job,
            // sectionValid: _section,
            // titleValid: _title,
            // detailsValid: _details,
            // endDateValid: _endDate,
            // statusValid: _status
        }, this.validateForm);

        return _evaluationDirectorEmail;
    }
    validateForm() {
        let isFormValid = this.state.evaluationDirectorEmailValid;
        this.setState({ formValid: isFormValid });
        return isFormValid
    }

    onSave = async () => {
        this.messages.clear();
        if (!this.validateField()) {
            return false;
        }

        this.setState({ loading: true })
        let res = await this.saveData(false);
        if (res.status === 200) {
            if (res.data && res.data.success) {
                if (res.data.message)
                    Util.showSuccessMsg(this.messages, res.data.message);
                else
                    Util.showSuccessMsg(this.messages);
                await this.getSettingsList();
                this.setState({ loading: false })
            }
            else {
                let msg = $.strings.operationFailed;
                if (res.data && res.data.message) {
                    msg = res.data;
                }
                Util.showErrorMsg(this.messages, msg);
                this.setState({ loading: false })
            }
        }
        else {
            if (res.data.message !== "") {
                Util.showErrorMsg(this.messages, res.data.message);
                this.setState({ loading: false })
            }
            else {
                Util.showErrorMsg(this.messages);
                this.setState({ loading: false })
            }
        }
    }

    saveData = async () => {
        let files = [];

        let printSettings = {
            headerType: this.state.dataObject.headerType, // 1: Text, 2: image; RSetup ID: 226
            arabicImage: this.state.dataObject.arabicImage, // RSetup ID: 227
            englishImage: this.state.dataObject.englishImage, // RSetup ID: 228
            printVatReg: this.state.dataObject.printVatReg, // RSetup ID: 229
            flipPageLandscap: this.state.dataObject.flipPageLandscap,// RSetup ID: 230
            paperType: this.state.dataObject.paperType ? this.state.dataObject.paperType.id : 9, // RSetup ID: 231

            // طباعة اسم وعنوان الشركة
            printCoNameReports: this.state.dataObject.printCoNameReports,// RSetup ID: 232 في التقارير
            printCoNameVouchers: this.state.dataObject.printCoNameVouchers, // RSetup ID: 233 في السندات

            // 1: حسب وتجهة التطبيق
            // 2: باللغتين
            coNameAddressLang: this.state.dataObject.coNameAddressLang, // RSetup ID: 234, 

            // الهوامش
            rightMargin: this.state.dataObject.rightMargin, // RSetup ID: 235
            leftMargin: this.state.dataObject.leftMargin, // RSetup ID: 236
            topMargin: this.state.dataObject.topMargin, // RSetup ID: 237
            bottomMargin: this.state.dataObject.bottomMargin, // RSetup ID: 238

            // هوامش الخلايا
            verMargin: this.state.dataObject.verMargin, // RSetup ID: 239
            horMargin: this.state.dataObject.horMargin, // RSetup ID: 240

            // انواع الخطوط
            coFontName: this.state.dataObject.coFontName ? this.state.dataObject.coFontName.name : "", // RSetup ID: 241
            coFontSize: this.state.dataObject.coFontSize ? this.state.dataObject.coFontSize.id : 12, // RSetup ID: 242
            addressFontName: this.state.dataObject.addressFontName ? this.state.dataObject.addressFontName.name : "", // RSetup ID: 243
            addressFontSize: this.state.dataObject.addressFontSize ? this.state.dataObject.addressFontSize.id : 12, // RSetup ID: 244
            vatRegFontName: this.state.dataObject.vatRegFontName ? this.state.dataObject.vatRegFontName.name : "", // RSetup ID: 245
            vatRegFontSize: this.state.dataObject.vatRegFontSize ? this.state.dataObject.vatRegFontSize.id : 12, // RSetup ID: 246
            dataFontName: this.state.dataObject.dataFontName ? this.state.dataObject.dataFontName.name : "", // RSetup ID: 247
            dataFontSize: this.state.dataObject.dataFontSize ? this.state.dataObject.dataFontSize.id : 12, // RSetup ID: 248

            // التسطير
            line: this.state.dataObject.line ? this.state.dataObject.line.id : 0, // RSetup ID: 249
            // اطار محيط
            border: this.state.dataObject.border ? this.state.dataObject.border.id : 0, // RSetup ID: 250

            arabicImageChanged: this.state.dataObject.arabicImageChanged,
            englishImageChanged: this.state.dataObject.englishImageChanged,
        }

        let obj = {
            coName: this.state.dataObject.coName,
            coEnName: this.state.dataObject.coEnName,
            address: this.state.dataObject.address,
            enAddress: this.state.dataObject.enAddress,
            decimalsCount: this.state.dataObject.decimalsCount,
            mainCurr: this.state.dataObject.mainCurr,
            workHours: this.state.dataObject.workHours,
            coCode: this.state.dataObject.coCode,
            empCodeLen: this.state.dataObject.empCodeLen,
            rawatExist: this.state.dataObject.rawatExist,
            hijriDate: this.state.dataObject.hijriDate ? this.state.dataObject.hijriDate.id : undefined,
            formate: this.state.dataObject.formate,
            evaluationDirectorEmail: this.state.dataObject.evaluationDirectorEmail,
            showRejectedRequests: this.state.dataObject.showRejectedRequests,
            showTimesRequetsReports: this.state.dataObject.showTimesRequetsReports,
            showAlterEmpRequetsReports: this.state.dataObject.showAlterEmpRequetsReports,
            sendEmailDelegatedEmp: this.state.dataObject.sendEmailDelegatedEmp,
            sendEmailToDMWhenHRHAndleRequest: this.state.dataObject.sendEmailToDMWhenHRHAndleRequest,
            showOnlyApprovedSalarySlip: this.state.dataObject.showOnlyApprovedSalarySlip,
            showMyStaffOnlyInDelegation: this.state.dataObject.showMyStaffOnlyInDelegation,
            deductUnpaidVacationFromEndServicesBalance: this.state.dataObject.deductUnpaidVacationFromEndServicesBalance,
            calculteEmpEndOfServicesAccordingToLaborLaw: this.state.dataObject.calculteEmpEndOfServicesAccordingToLaborLaw,
            showAttendanceAndUnpaidLoansInSalarySlip: this.state.dataObject.showAttendanceAndUnpaidLoansInSalarySlip,
            showDataDirectlyInReports: this.state.dataObject.showDataDirectlyInReports,
            showRetiredEmployeesInStaff: this.state.dataObject.showRetiredEmployeesInStaff,

            firstHoliday: this.state.dataObject.firstHoliday ? this.state.dataObject.firstHoliday.id : undefined,
            secondHoliday: this.state.dataObject.secondHoliday ? this.state.dataObject.secondHoliday.id : undefined,
            specialHandle: this.state.dataObject.specialHandle ? this.state.dataObject.specialHandle.id : undefined,
            printVacationForm: this.state.dataObject.printVacationForm ? this.state.dataObject.printVacationForm.id : undefined,
            calcAllHolidayFromVacation: this.state.dataObject.calcAllHolidayFromVacation,
            calcWeekHolidayFromVacation: this.state.dataObject.calcWeekHolidayFromVacation,
            calcFormalHolidayFromVacation: this.state.dataObject.calcFormalHolidayFromVacation,
            discountVacationWithoutPayfromVacations: this.state.dataObject.discountVacationWithoutPayfromVacations,
            docsRequiredInSikVacRequest: this.state.dataObject.docsRequiredInSikVacRequest,
            denyRequestForRejectedPeriod: this.state.dataObject.denyRequestForRejectedPeriod,
            addressRequired: this.state.dataObject.addressRequired,
            lCDays: this.state.dataObject.lCDays,
            pringingIn: this.state.dataObject.pringingIn,
            vacBefore: this.state.dataObject.vacBefore,
            preventRequest: this.state.dataObject.preventRequest,
            retroactivly: this.state.dataObject.retroactivly,
            leaveAndNoFingerPrintAllwedWithVacation: this.state.dataObject.leaveAndNoFingerPrintAllwedWithVacation,
            hideUnpaidVacation: this.state.dataObject.hideUnpaidVacation ? true : false,
            annualVacationsList: this.state.dataObject.annualVacationsList,
            sickVacationsList: this.state.dataObject.sickVacationsList,

            discountAllowedPeriodFromOverTime: this.state.dataObject.discountAllowedPeriodFromOverTime,
            noOverTimeForWorkHoursEmp: this.state.dataObject.noOverTimeForWorkHoursEmp,
            overTimePercent: this.state.dataObject.overTimePercent,
            overTimePercentInHoliday: this.state.dataObject.overTimePercentInHoliday,
            overTimeProcess: this.state.dataObject.overTimeProcess ? this.state.dataObject.overTimeProcess.id : undefined,
            overTimeProcessAfter: this.state.dataObject.overTimeProcessAfter ? this.state.dataObject.overTimeProcessAfter.id : undefined,
            minutePercent: this.state.dataObject.minutePercent ? this.state.dataObject.minutePercent.id : undefined,
            overTimePercentMorning: this.state.dataObject.overTimePercentMorning ? this.state.dataObject.overTimePercentMorning.id : undefined,

            ignoreLeaves: this.state.dataObject.ignoreLeaves,
            beforeIn: this.state.dataObject.beforeIn,
            afterIn: this.state.dataObject.afterIn,
            beforeOut: this.state.dataObject.beforeOut,
            afterOut: this.state.dataObject.afterOut,

            failedLoginCount: this.state.dataObject.failedLoginCount,
            changePwdEvery: this.state.dataObject.changePwdEvery,
            editShiftFrom: this.state.dataObject.editShiftFrom,
            editShiftTo: this.state.dataObject.editShiftTo,
            editShiftPrevMonth: this.state.dataObject.editShiftPrevMonth,
            allowedLeavesCount: this.state.dataObject.allowedLeavesCount,
            allowedLeavesCountInDay: this.state.dataObject.allowedLeavesCountInDay,
            leavesHoursCount: this.state.dataObject.leavesHoursCount,
            altEmpRequired: this.state.dataObject.altEmpRequired,
            printSaveBox: this.state.dataObject.printSaveBox,
            printSettings: printSettings,
            specialLeaveTypeInHRApprove: this.state.dataObject.specialLeaveTypeInHrApprove ? this.state.dataObject.specialLeaveTypeInHrApprove.pos : 0,
            allowingAnnualVacationRequestAccordingToTheBalance: this.state.dataObject.allowingAnnualVacationRequestAccordingToTheBalance ? this.state.dataObject.allowingAnnualVacationRequestAccordingToTheBalance.id : 1,
            SessionTimoutMinutes: this.state.dataObject.SessionTimoutMinutes ? this.state.dataObject.SessionTimoutMinutes : 0
        }

        let res = await http.postMultipartAxios(http.actions.Settings.urlPostSaveSettings, obj, null, files, 0);

        return res;
    }

    getSettingsList = async () => {
        let obj = {}
        let res = await http.getJsonAxios(http.actions.Settings.urlGetSettingsList, obj, 0);
        if (res.status === 200) {
            localStorage.setItem("settingsListHR", JSON.stringify(res.data));
        }
    }

    onEditAnnualVacClick = (e, ctx) => {
        this.dlgAnnualVacationSettings.onShow(ctx.item.id, ctx.item.YearsCount, ctx.item.AnnualDaysCount);
    }
    annaulVacationEdit = (id, count, value) => {
        let annualVacationsList = this.state.dataObject.annualVacationsList;
        if (annualVacationsList) {
            let index = annualVacationsList.findIndex((obj => obj.id + "" === id + ""));
            if (index >= 0) {
                annualVacationsList[index].YearsCount = count;
                annualVacationsList[index].AnnualDaysCount = value;
                this.setState({ dataObject: { ...this.state.dataObject, annualVacationsList: annualVacationsList } });
            }
        }
    }

    getScheme = () => {
        return {
            name: "annualVacScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'id', header: '##', width: 80, dataType: 'Number', visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'onEditAnnualVacClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditAnnualVacClick(e, ctx)
                },
                {
                    name: 'YearsCount', header: $.strings.systemSettings.yearsCount, width: 150
                },
                {
                    name: 'AnnualDaysCount', header: $.strings.systemSettings.annualDaysCount, width: this.state.isDesktop ? '*' : 290
                }

            ]
        }
    }

    onEditSickVacClick = (e, ctx) => {
        this.dlgSickVacationSettings.onShow(ctx.item.id, ctx.item.DaysCount, ctx.item.DiscountPercent);
    }
    sickVacationEdit = (id, count, value) => {
        let sickVacationsList = this.state.dataObject.sickVacationsList;
        if (sickVacationsList) {
            let index = sickVacationsList.findIndex((obj => obj.id + "" === id + ""));
            if (index >= 0) {
                sickVacationsList[index].DaysCount = count;
                sickVacationsList[index].DiscountPercent = value;
                this.setState({ dataObject: { ...this.state.dataObject, sickVacationsList: sickVacationsList } });
            }
        }
    }

    getSchemeSick = () => {
        return {
            name: "sickVacScheme_Table",
            filter: false,
            showFooter: false,
            sortable: false,
            allowGrouping: false,
            responsiveColumnIndex: 3,
            columns: [
                {
                    name: 'id', header: '##', width: 80, dataType: 'Number', visible: false, visibleInColumnChooser: false
                },
                {
                    name: 'onEditSickVacClick', header: ' ', width: 50, visible: Util.checkISHR(), buttonBody: 'button', align: 'center', title: $.strings.edit,
                    iconType: 'edit', className: 'warning', onClick: (e, ctx) => this.onEditSickVacClick(e, ctx)
                },
                {
                    name: 'DaysCount', header: $.strings.systemSettings.dueDays, width: 150
                },
                {
                    name: 'DiscountPercent', header: $.strings.systemSettings.discountPercent, width: this.state.isDesktop ? '*' : 290
                }

            ]
        }
    }

    onSelectImage = async (id, event) => {
        if (event.target.files.length > 0) {
            let data = this.state.dataObject
            const allowedExtensions = ['jpg', 'png', 'jpeg', 'bmp', 'tiff', 'gif'];
            const fileExtension = event.target.files[0].name.split(".").pop();
            if (!allowedExtensions.includes(fileExtension)) {
                this.popupHasCalled();
                this.setState({ alertBoxVisible: true }, () => {
                    this.alertBox.show((e) => {
                        this.popupHasClosed();
                        this.setState({ alertBoxVisible: false })
                    }, undefined, undefined, $.strings.systemSettings.imagesOnlyError);
                });
                return
            }

            let imgData = URL.createObjectURL(event.target.files[0])
            let base64 = await convertBlobToBase64(event.target.files[0])

            if (id + "" === "227") {
                data.arabicImage = base64;
                data.arabicImageChanged = true
            }
            else {
                data.englishImage = base64;
                data.englishImageChanged = true
            }
            this.setState({ dataObject: data })
        }
    }

    onRemoveImage = (id, showMsg) => {
        if (showMsg) {
            this.popupHasCalled();
            this.setState({ confirmBoxVisible: true }, () => {
                this.confirmBox.show(
                    () => {
                        this.onRemoveImage(id, false);
                        this.popupHasClosed();
                        this.setState({ confirmBoxVisible: false })
                    },
                    () => {
                        this.popupHasClosed();
                        this.setState({ confirmBoxVisible: false });
                    }, undefined,
                    $.strings.systemSettings.removeImageConfirm
                )
            });
            return;
        }
        let data = this.state.dataObject
        if (id + "" === "227") {
            data.arabicImageFile = undefined;
            data.arabicImage = undefined;
        }
        else {
            data.englishImageFile = undefined;
            data.englishImage = undefined;
        }
        this.setState({ dataObject: data })
    }
}

const convertBlobToBase64 = async (blob) => { // blob data
    return await blobToBase64(blob);
}

const blobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});