import axios from "axios";
import Util from "../Util";
import moment from "moment";

const $ = window.$;

const server = window.API_URL.url
const companyId = 1;

const actions =
{
  Login: {
    doLogin: server + '/Login/CheckAuthentication',
    doLoginHR: server + '/Login/CheckAuthentication_HR',
    doLoginCRM: server + '/Login/CheckAuthenticationCRM',
    test: server + '/test/get'
  },
  MultiFl: {
    GetMultiFlByType: server + '/MultiFl/GetMultiFlByType',
    urlGetDeleteMultiFl: server + '/MultiFl/DeleteMultiFl',
    urlPostSaveMultiFl: server + '/MultiFl/SaveMultiFl',
    urlGetMultiFlByTypePos: server + '/MultiFl/GetMultiFlByTypePos',
    urlGetMultiFlRights: server + '/MultiFl/GetMultiFlRights',
    urlPostUpdateMultiFlUsers: server + '/MultiFl/UpdateMultiFlUsers'
  },
  Memos: {
    urlPostSaveMemos: server + '/Memos/SaveMemos',
    urlGetMemos: server + '/Memos/GetMemos',
    urlGetDeleteMemo: server + '/Memos/DeleteMemo',
    urlGetFillEmployeeMemos: server + '/Reports/FillEmployeeMemos'
  },
  FingerPrint: {
    urlPostFingerPrint: server + '/FingerPrintReq/SaveFingerPrintReq',
    urlGetDeleteFingerPrint: server + '/FingerPrintReq/DeleteFingerPrint',
    urlGetFingerPrint: server + '/FingerPrintReq/GetFingerPrint',
    urlGetAttendanceUpdateRpt: server + '/Reports/GetAttendanceUpdateRpt',
    urlGetFPInfoFromEmail: server + '/MailHandle/GetFPInfoFromEmail',
    urlPostSaveFingerPrintFromEmail: server + '/MailHandle/SaveFingerPrintFromEmail',
    urlCheckVacationOrRequestFound: server + '/FingerPrintReq/CheckVacationOrRequestFound',
    urlCheckSickVacationPercent: server + '/HandelRequest/CheckSickVacationPercent'
  },
  HolidaysReq: {
    urlPostHolidayReq: server + '/HolidaysReq/SaveHolidayReq',
    urlGetDeleteHolidayReq: server + '/HolidaysReq/DeleteHolidayReq',
    urlGetHolidayReq: server + '/HolidaysReq/GetHolidayReq',
    urlPostSaveOverTime: server + '/HolidaysReq/SaveOverTime',
    urlPostSaveSpecialLeave: server + '/HolidaysReq/SaveSpecialLeave',
    urlPostSaveWorkLeave: server + '/HolidaysReq/SaveWorkLeave',
    urlPostSaveVacLessDay: server + '/HolidaysReq/SaveVacLessDay',
    urlGetVacationBalances: server + '/HolidaysReq/GetVacationBalances',
    urlGetOverTimeBalances: server + '/HolidaysReq/GetOverTimeBalances',
    urlGetVacInfoFromEmail: server + '/MailHandle/GetVacInfoFromEmail',
    urlGetPaidVacType: server + '/MailHandle/GetPaidVacType',
    urlPostSaveVacationFromEmail: server + '/MailHandle/SaveVacationFromEmail',
    urlGetVacationDaysCount: server + '/HolidaysReq/GetVacationDaysCount',
    urlGetVacationEmpMaxDetails: server + '/HolidaysReq/GetVacationEmpMaxDetails',
    urlGetPrintRequest: server + '/HolidaysReq/PrintRequest',
    urlGetSubstituteEmployees: server + '/HolidaysReq/GetSubstituteEmployees',
  },
  ExpectedVacation: {
    urlPostSaveExpectedVacation: server + '/ExpectedVacation/SaveExpectedVacation',
    urlGetDeleteExpectedVacation: server + '/ExpectedVacation/DeleteExpectedVacation',
    urlGetExpectedVacation: server + '/ExpectedVacation/GetExpectedVacation',
    urlGetExpectedVacationsList: server + '/ExpectedVacation/GetExpectedVacationsList'
  },
  MyRequests: {
    urlGetMyRequests: server + '/MyRequests/GetMyRequests'
  },
  Employee: {
    urlPostChangeEmployeeLanguage: server + '/Employee/ChangeEmployeeLanguage',
    urlPostChangeEmployeePassword: server + '/Employee/ChangeEmployeePassword',
    urlGetSaveEmpSettings: server + '/Employee/SaveEmpSettings',
    urlMustChangePassword: server + '/Employee/ChangeEmployeePassword_Must',
    urlGetEmployeeBranchSection: server + '/Employee/GetEmployeeBranchSection',
    urlGetEmployeeInfo: server + '/Employee/GetEmployeeInfo',
    urlGetEmployees: server + '/Employee/GetEmployees',
    urlGetSalaryInfo: server + '/Employee/GetSalaryInfo',
    urlGetSalaryTrans: server + '/Employee/GetSalaryTrans',
    urlGetEndServicesBalance: server + '/EndService/GetEndServicesBalance',
    urlGetLoans: server + '/Loans/GetLoans',
    urlGetLoanDetails: server + '/Loans/GetLoanDetails',
    urlPostChangePasswordHR: server + '/Employee/ChangePasswordHR',
    urlPostResetPasswordHR: server + '/Employee/ResetPasswordHR',
    urlGetEmployeeData: server + '/Employee/GetEmployeeData',
    urlUpdateEmployeeData: server + '/Employee/UpdateEmployeeData',
    urlChangeEmployeeCode: server + "/Employee/ChangeEmployeeCode",
    urlGetAnnualBalanceAndAge: server + "/Employee/GetAnnualBalanceAndAge",
    urlGetEmpInfoForAttendanceTrans: server + '/Employee/GetEmpInfoForAttendanceTrans',
    urlPostHandleImportEmployeeFromExcel: server + '/Employee/HandleImportEmployeeFromExcel'
  },
  WFTrans: {
    urlGetWFTrans: server + '/WFTrans/GetWFTrans',
    urlGetShowWFTrans: server + '/WFTrans/ShowWFTrans',
    urlGetSaveChangeEvaluator: server + '/WFTrans/SaveChangeEvaluator',
    urlGetShowChangeEvaluator: server + '/WFTrans/ShowChangeEvaluator',
    urlGetAllWFTypes: server + '/WFTrans/GetAllWFTypes',
    urlGetRoles: server + '/WFTrans/GetRoles',
    urlGetEmpsChangesTypes: server + '/WFTrans/GetEmpsChangesTypes',
    urlGetAllWorkFlows: server + '/WFTrans/GetAllWorkFlows',
    urlGetWorkFlowData: server + '/WFTrans/GetWorkFlowData',
    urlSaveWorkFlow: server + '/WFTrans/SaveWorkFlow',
    urlGetFillCmbSubTyps: server + '/WFTrans/FillCmbSubTyps',
    urlGetDeleteWFStep: server + '/WFTrans/DeleteWFStep'
  },
  Shifts: {
    urlGetShifts: server + '/Shifts/GetShifts',
    urlGetShift: server + '/Shifts/GetShift', // general or employee
    urlGetGeneralShift: server + '/Shifts/GetGeneralShift',
    urlGetShiftsSearch: server + '/Shifts/GetShiftsSearch',
    urlPostSaveEmpShift: server + '/Shifts/SaveEmpShift',
    urlPostSaveGeneralShift: server + '/Shifts/SaveGeneralShift',
    urlGetShiftsWeeklyList: server + '/Shifts/ShiftsWeeklyList',
    urlGetShiftsForEmployeeForOneDay: server + '/Shifts/GetShiftsForEmployeeForOneDay',
    urlGetSaveEmpShiftForDay: server + '/Shifts/SaveEmpShiftForDay',
    urlGetShiftsList: server + '/Shifts/GetShiftsList',
    urlGetEmployeesDistributeDM: server + '/Shifts/GetEmployeesDistributeDM',
    urlGetEmpShifts: server + '/Shifts/GetEmpShifts',
    urlPostSaveGeneralDistributeShift: server + '/Shifts/SaveGeneralDistributeShift',
    urlPostSaveShiftDistributeDM: server + '/Shifts/SaveShiftDistributeDM'
  },
  Attachments: {
    urlGetAttachments: server + '/Attachments/GetAttachments',
    urlGetAttachmentFile: server + '/Attachments/GetAttachmentFile'
  },
  Notifications: {
    urlGetStatus: server + '/Notifications/GetStatus',
    urlGetNotificationsTypes: server + '/Notifications/GetNotificationsTypes',
    urlGetNotifications: server + '/Notifications/GetNotifications',
    urlPostHandleNotifications: server + '/Notifications/HandleNotifications',
    urlGetNotificationsType: server + '/Notifications/GetNotificationsType',
    urlGetNotificationsSettings: server + '/Notifications/GetNotificationsSettings',
    urlGetNotificationSettingsByNotTypeId: server + '/Notifications/GetNotificationSettingsByNotTypeId',
    urlPostSaveNotificationSettings: server + '/Notifications/SaveNotificationSettings',
    urlGetNotificationsReport: server + '/Notifications/GetNotificationsReport'
  },
  HandleRequests: {
    urlGetEmployeesRequests: server + '/HandelRequest/GetEmployeesRequests',
    urlGetFillComboWorkFLowTyps: server + '/HandelRequest/FillComboWorkFLowTyps',
    urlGetFillComboSubTypeByType: server + '/HandelRequest/FillComboSubTypeByType',
    urlGetAcceptFromManager: server + '/HandelRequest/AcceptFromManager',
    urlGetRejectFromManager: server + '/HandelRequest/RejectFromManager',
    urlGetAcceptFromHR: server + '/HandelRequest/AcceptFromHR',
    urlGetRejectFromHR: server + '/HandelRequest/RejectFromHR',
    urlGetAcceptFromHRWithOutCheck: server + '/HandelRequest/AcceptFromHRWithoutCheck',
    urlGetIgnoreFromHR: server + '/HandelRequest/IgnoreFromHR',
    urlGetRequestWatched: server + '/HandelRequest/RequestWatched',
    urlGetHandelRequestFromEmail: server + '/MailHandle/HandleRequest',
    urlGetValidateSectionVacationsDM: server + '/HandelRequest/ValidateSectionVacationsDM',
    urlCheckPeriodVacationDays: server + '/HandelRequest/CheckPeriodVacationDays'
  },
  EmpDelegation: {
    urlGetDelegate: server + '/Delegation/GetDelegate',
    urlGetSaveDelegate: server + '/Delegation/SaveDelegate',
    urlGetEmployees: server + '/Delegation/FillCmbEmployees',
    urlGetDelegationsList: server + '/Delegation/GetDelegationsList'
  },
  AttReports: {
    urlGetFillCmbBranches: server + '/AttReports/FillCmbBranches',
    urlGetShifts: server + '/AttReports/GetShifts',
    urlGetEntryAndExitReport: server + '/AttReports/EntryAndExitReport'
  },
  Sections: {
    urlGetSections: server + '/Sections/GetSection',
    urlGetSectionsList: server + '/Sections/GetSectionList',
    urlGetSectionPrev: server + '/Sections/GetSectionPrev',
    urlGetSectionNext: server + '/Sections/GetSectionNext',
    urlGetSectionFirst: server + '/Sections/GetSectionFirst',
    urlGetSectionLast: server + '/Sections/GetSectionLast',
    urlGetSectionsTree: server + '/Sections/GetSectionsAndFather',
    urlGetMaxCode: server + '/Sections/GetMaxCode',
    urlPostSaveSection: server + '/Sections/SaveSection',
    urlDeleteSection: server + '/Sections/DeleteSection'
  },
  TotalEntryAndExitReport: {
    urlGetTotalEntryAndExit: server + '/TotalEntryAndExitReport/TotalEntryAndExit'
  },
  Attendance: {
    urlGetAttendanceReport: server + '/AttendanceReport/GetAttendanceReport'
  },
  WorkAndLeave: {
    urlGetWorkAndLeaveReport: server + '/WorkAndLeave/GetWorkAndLeaveReport',
    urlGetClTransProcessCode_Count: server + '/WorkAndLeave/GetClTransProcessCode_Count',
    urlDeleteTrans: server + '/WorkAndLeave/DeleteTrans'
  },
  RequestsStatus: {
    urlGetRequestsStatus: server + '/RequestsStatus/GetRequestsStatus'
  },
  RequestsReport: {
    urlGetRequestsReport: server + '/AttReports/GetRequestsReport'
  },
  Absence: {
    urlGetAbsenceReport: server + '/Absence/GetAbsenceReport',
    urlSaveAbsenceTrans: server + '/Absence/SaveAbsenceTrans',
    urlGetAbsenceTrans: server + '/Absence/GetAbsenceTrans',
    urlCheckHasAbsence: server + '/Absence/CheckHasAbsence',
    urlDeleteAbsenceTransAttach: server + '/Absence/DeleteAbsenceTransAttach'
  },
  HolidayPersonalRpt: {
    urlGetHolidayPersonalRpt: server + '/Reports/HolidayPersonalRpt',
    urlGetGeneralHolidayRpt: server + '/Reports/GeneralHolidayRpt'
  },
  DailyAttendanceRpt: {
    urlGetDailyAttendanceRpt: server + '/Reports/DailyAttendanceRpt'
  },
  ClockTrans: {
    urlGetClockTrans: server + '/Reports/GetClockTrans',
    urlGetClockTransactionForEdit: server + '/Reports/GetClockTransactionForEdit',
    urlGetDeleteClockTransactions: server + '/Reports/DeleteClockTransactions',
    urlPostSaveClockTransactions: server + '/Reports/SaveClockTransactions'
  },
  News: {
    urlGetNews: server + '/News/GetNews',
    urlGetSeenNews: server + '/News/GetSeenNews',
    urlSaveNews: server + '/News/SaveNews',
    urlUpdateNews: server + '/News/UpdateNews',
    urlGetNewsById: server + '/News/GetNewsById',
    urlDeleteNews: server + '/News/DeleteNews',
    urlSetNewsRead: server + '/News/SetNewsRead'
  },
  Dashboards: {
    urlGetDashboards: server + '/Dashboards/GetDashboards',
    urlGetAbsenseDashboard: server + '/Dashboards/GetAbsenseDashboard',
    urlGetLateDashboard: server + '/Dashboards/GetLateDashboard',
    urlGetEmployeesRequests: server + '/Dashboards/GetEmployeesRequests',
    urlGetNotSignInDashboard: server + '/Dashboards/NotSignInDashboard',
    urlGetNotExitDashboard: server + '/Dashboards/NotExitDashboard',
  },
  LoginsReport: {
    urlGetLogHistory: server + '/Reports/GetLogHistory'
  },
  OrgChart: {
    urlGetSectionsAndFather: server + '/Reports/GetSectionsAndFather'
  },
  Messages: {
    urlGetMessagesList: server + '/Messages/GetMessagesList',
    urlResendMsg: server + '/Messages/ResendMsg',
    urlGetSmsMessages: server + '/Messages/GetSmsMessages',
    urlPostSaveSmsMessages: server + '/Messages/SaveSmsMessages'
  },
  Settings: {
    urlGetSubstituteSettings: server + '/Settings/GetSubstituteSettings',
    urlPostSaveSubstituteSettings: server + '/Settings/SaveOrUpdateSubstituteSettings',
    urlGetSettings: server + '/Settings/GetSettings',
    urlPostSaveSettings: server + '/Settings/SaveSettings',
    urlGetSettingsById: server + '/Settings/GetSettingsById',
    urlGetSettingsByIdMailHandle: server + '/MailHandle/GetSettingsById',
    urlUpdateSettings: server + '/Settings/UpdateSettingsById',
    urlGetSettingsList: server + '/Settings/GetSettingsList',
    urlGetSettingsByIdImage: server + '/Settings/GetSettingsByIdImage',
  },
  Periods: {
    urlGetPeriodsList: server + '/Periods/GetPeriodsList',
    urlPostSavePeriods: server + '/Periods/SavePeriods'
  },
  LateSettings: {
    urlGetMaxSettingId: server + '/LateSettings/GetMaxSettingId',
    urlGetLateSettingsAndDetails: server + '/LateSettings/GetLateSettingsAndDetails',
    urlPostSaveLateSettingsAndDetails: server + '/LateSettings/SaveLateSettingsAndDetails',
    urlGetNextLateSettings: server + '/LateSettings/GetNextLateSettings',
    urlGetPrevLateSettings: server + '/LateSettings/GetPrevLateSettings',
    urlGetLastLateSettings: server + '/LateSettings/GetLastLateSettings',
    urlGetFirstLateSettings: server + '/LateSettings/GetFirstLateSettings',
    urlDeleteLateSettings: server + "/LateSettings/DeleteLateSettings"
  },
  Reports: {
    urlMonthlyDelaysReport: server + '/Reports/MonthlyDelaysReport',
    urlPostSavePenalties: server + '/Reports/SavePenalties',
    urlGetReportList: server + '/Reports/GetReportList',
    urlGetRepLogFile: server + '/Reports/GetRepLogFile',
    urlGetOverTimeBalanceReport: server + '/Reports/OverTimeBalanceReport',
    urlGetSpecialLeaveReport: server + '/Reports/SpecialLeaveReport',
    urlProcessSpecialLeaveReport: server + '/Reports/ProcessSpecialLeaveReport',
    urlSaveSpecialLeave: server + '/Reports/SaveSpecialLeave',
    urlGetEmployeeWorkHoursPercent: server + '/Reports/GetEmployeeWorkHoursPercent',
    urlPostGroupVacationReport: server + '/Reports/GroupVacationReport',
    urlPostSaveGroupVacation: server + '/Reports/SaveGroupVacation',
    urlPostGetSalaryForDays: server + '/Reports/GetSalaryForDays',
    urlPostSaveSalaryForDays: server + '/Reports/SaveSalaryForDays',
    urlPostReCalculateBalances: server + '/Reports/ReCalculateBalances',
    urlPostSaveReCalculateBalances: server + '/Reports/SaveReCalculateBalances',
    urlPostMonthlySummaryReport: server + '/Reports/MonthlySummaryReport'
  },
  Users: {
    urlGetUsers: server + '/Users/GetUsers',
    urlGetUserObjectById: server + '/Users/GetUserObjectById',
    urlGetUsersInfo: server + '/Users/GetUsersInfo',
    urlActiveUser: server + '/Users/ActiveUser',
    urlGetResetUserPassword: server + '/Users/ResetUserPassword',
    urlCheckDuplicateLoginName: server + '/Users/CheckDuplicateLoginName',
    urlGetUserNavigation: server + '/Users/GetUsersNavigation',
    urlGetUserGroups: server + '/Users/GetUserGroups',
    urlGetUserAccess: server + '/Users/GetUserAccess',
    urlSaveUsersAccess: server + '/Users/SaveUserAccess',
    urlCopyUsersAccess: server + '/Users/CopyUserAccessFromUserToUser',
  },
  Roles: {
    urlGetRoles: server + '/Users/GetRoles',
    urlPostSaveRoles: server + '/Users/SaveGroup',
    urlGetRoleNavigation: server + '/Users/GetRoleNavigation',
    urlDeleteRole: server + '/Users/DeleteRoleById',
    urlDeleteUserRole: server + '/Users/DeleteUserRole',
    urlGetSubRoles: server + '/Users/GetSubRoles',
    urlGetCAccessGroups: server + '/Users/GetCAccessGroups',
    urlSaveCAccessGroups: server + '/Users/SaveCAccessGroups',
    urlGetUsers: server + '/Users/GetUsers'
  },
  RequestsAliases: {
    urlGetRequestsAliases: server + '/RequestsAliases/GetRequestsAliases',
    urlSaveRequestsAliases: server + '/RequestsAliases/SaveRequestsAliases'
  }
}
const http = {
  actions: actions,

  postJsonAxios: async function (url, data, pageId) {
    let resp;
    let headers = {
      headers: {
        "Content-Type": "application/Json",
        company_id: companyId,
        page_id: pageId,
        lang_id: localStorage.getItem("HRlang") + "" === "en" ? 2 : 1,
        user_id: localStorage.getItem("useridHR"),
        isEmp: localStorage.getItem("isEmp"),
        isManager: Util.checkISManager(),
        token: localStorage.getItem("isLoggedInHR")
      }
    };
    let result = { status: 0, message: '', data: [] };
    try {
      Date.prototype.toJSON = function () {
        // Get the timezone offset in minutes and convert to milliseconds
        let timezoneOffset = this.getTimezoneOffset() * 60 * 1000;
        timezoneOffset *= -1;
        // Create a new Date object with the timezone offset added to the time
        const newDate = new Date(this.getTime() + timezoneOffset);
        // Return the ISO string representation of the new Date object
        return newDate.toISOString();
      };
      data = JSON.stringify(data);
      resp = await axios.post(url, data, headers)
      result.status = resp.status;
      result.message = "";
      result.data = resp.data;
    }
    catch (err) {
      //alert(err)
      if (err.response && err.response.status === 401) {
        if ($.showSessionExpired === true) {
          $.showSessionExpired = false;
          alert($.strings.sessionExpired)
          Util.logOut();
        }
        return result;
      }
      if (err.response) {
        result.status = err.response.status;
        result.message = err.response.data;
      }
      else {
        result.status = 500;
        result.message = err.message;
      }
    }
    return result;
  },
  postMultipartAxios: async function (url, data, file, files, pageId) {
    let resp;
    let headers = {
      headers: {
        'content-type': 'multipart/form-data',
        company_id: companyId,
        page_id: pageId,
        lang_id: localStorage.getItem("HRlang") + "" === "en" ? 2 : 1,
        user_id: localStorage.getItem("useridHR"),
        isEmp: localStorage.getItem("isEmp"),
        isManager: Util.checkISManager(),
        token: localStorage.getItem("isLoggedInHR")
      }
    };
    let result = { status: 0, message: '', data: [] };
    try {
      Date.prototype.toJSON = function () {
        // Get the timezone offset in minutes and convert to milliseconds
        let timezoneOffset = this.getTimezoneOffset() * 60 * 1000;
        timezoneOffset *= -1;
        // Create a new Date object with the timezone offset added to the time
        const newDate = new Date(this.getTime() + timezoneOffset);
        // Return the ISO string representation of the new Date object
        return newDate.toISOString();
      };

      let form = new FormData();

      form.append('json', JSON.stringify(data))
      if (file)
        form.append('file', file, file.name)
      if (files)
        for (let i = 0; i < files.length; i++)
          form.append('files', files[i], files[i].name)

      resp = await axios.post(url, form, headers)
      result.status = resp.status;
      result.message = "";
      result.data = resp.data;

    }
    catch (err) {
      //alert(err)
      if (err.response && err.response.status === 401) {
        if ($.showSessionExpired === true) {
          $.showSessionExpired = false;
          alert($.strings.sessionExpired)
          Util.logOut();
        }
        return result;
      }
      if (err.response) {
        result.status = err.response.status;
        result.message = err.response.data;
      }
      else {
        result.status = 500;
        result.message = err.message;
      }
    }
    return result;
  },
  getJsonAxios: async function (url, parm, pageId) {
    let resp;
    let result = { status: 0, message: '', data: [] };
    let headers = {
      headers: {
        "Content-Type": "Application/Json",
        company_id: companyId,
        page_id: pageId,
        lang_id: localStorage.getItem("HRlang") + "" === "en" ? 2 : 1,
        user_id: localStorage.getItem("useridHR"),
        isEmp: localStorage.getItem("isEmp"),
        isManager: Util.checkISManager(),
        token: localStorage.getItem("isLoggedInHR")
      }
    };
    if (parm != null) {
      headers = {
        headers: {
          "Content-Type": "Application/Json",
          company_id: companyId,
          page_id: pageId,
          lang_id: localStorage.getItem("HRlang") + "" === "en" ? 2 : 1,
          user_id: localStorage.getItem("useridHR"),
          isEmp: localStorage.getItem("isEmp"),
          isManager: Util.checkISManager(),
          token: localStorage.getItem("isLoggedInHR")
        },
        params:
          parm
      };
    }
    try {
      Date.prototype.toJSON = function () {
        // Get the timezone offset in minutes and convert to milliseconds
        let timezoneOffset = this.getTimezoneOffset() * 60 * 1000;
        timezoneOffset *= -1;
        // Create a new Date object with the timezone offset added to the time
        const newDate = new Date(this.getTime() + timezoneOffset);
        // Return the ISO string representation of the new Date object
        return newDate.toISOString();
      };

      resp = await axios.get(url, headers);
      result.status = resp.status;
      result.message = "";
      result.data = resp.data;
    }
    catch (err) {
      //alert(err)
      if (err.response && err.response.status === 401) {
        if ($.showSessionExpired === true) {
          $.showSessionExpired = false;
          alert($.strings.sessionExpired)
          Util.logOut();
        }
        return result;
      }
      if (err.response) {
        result.status = err.response.status;
        result.message = err.response.data;
      }
      else {
        result.status = 500;
        result.message = err.message;
      }
    }
    return result;
  }
};
export default http