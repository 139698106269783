import React from "react";
import Input from "../../../Components/Input";
import ButtonsBarLookup from '../../../Components/ButtonsBarLookup'
import Messages from '../../../Components/Messages';
import Http from '../../../Api/http';
import ProgressSpinner from '../../../Components/ProgressSpinner';
import DeleteNoteDialog from '../../../Components/DeleteNoteDialog';
import Util from '../../../Util';
import Card from "../../../Components/Card";
import Dialog from "../../../Components/Dialog";

import SCC from '../../ScreenCommonCode'
import { async } from "regenerator-runtime";
import RadioButton from "../../../Components/RadioButton/index";
import { Fieldset } from 'primereact/fieldset';

const $ = window.$;
const stringHash = require('@sindresorhus/string-hash');

export default class GeneralAdd extends React.Component {

    constructor(props) {
        super(props);
        this.hash = null
        this.state = {
            loading: true,
            pageInfo: this.setPageInfo(),
            formErrors: {},
            deleteErrors: { deleteNote: '' },
            dataObject: {
                pos: -1,
                stat: 0,
                users: 0,
                userInsert: localStorage.getItem("useridHR"),
                userUpdate: localStorage.getItem("useridHR"),
                code: '',
                name: '',
                eName: '',
                type: this.props.lookupType,
                classId: false,
                symbol: '',
                value: 0
            }
        }
    }

    componentDidMount = async () => {
        let _isDesktop = window.innerWidth > 1250
        this.setState({ loading: false, isDesktop: _isDesktop });
    }

    onNameBlur = (e) => {
        if (!this.state.dataObject.eName)
            this.setState({ dataObject: { ...this.state.dataObject, eName: e.target.value } }, e.target.selectAll);
    }

    validateFields() {
        let result = true;
        let fieldsValidationErrors = this.state.formErrors;

        fieldsValidationErrors.name = "";
        if (!this.state.dataObject.name || this.state.dataObject.name <= 0) {
            fieldsValidationErrors.name = $.strings.requiredFiled;
            result = false;
        }
        fieldsValidationErrors.eName = "";
        if (!this.state.dataObject.eName || this.state.dataObject.eName <= 0) {
            fieldsValidationErrors.eName = $.strings.requiredFiled;
            result = false;
        }

        return result;
    }

    render() {

        return (
            <div>
                <ProgressSpinner loading={this.state.loading} />
                <Dialog header={this.state.pageInfo.title} onHide={this.onClose} modal
                    visible={this.state.visibleDialog} style={{ width: this.state.isDesktop ? '30vw' : '80vw', direction: $.strings.dir }}>

                    {SCC.getScreenHeader_Buttons(this, true)}
                    <ProgressSpinner loading={this.state.loading} />
                    <div className="p-grid">
                        <div className="p-col-12 p-lg-12 p-sm-12">
                            <Messages innerRef={(el) => this.messages = el} />
                        </div>
                    </div>
                    <Card>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    innerRef={(el) => this.txtName = el}
                                    type="text"
                                    id="name"
                                    maxLength="50"
                                    value={this.state.dataObject.name}
                                    autofocus={true}
                                    caption={$.strings.lookups.name}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    onBlur={this.onNameBlur}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-12 p-sm-12">
                                <Input
                                    type="text"
                                    id="eName"
                                    maxLength="50"
                                    value={this.state.dataObject.eName || ''}
                                    caption={$.strings.lookups.eName}
                                    onChange={(e) => SCC.handleUserInput(this, e)}
                                    isRequired={true}
                                    formErrors={this.state.formErrors}
                                />
                            </div>
                        </div>
                        {
                            (this.props.lookupType + "" === "4" || this.props.lookupType + "" === "22") &&
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6 p-sm-12">
                                    <Input
                                        type="text"
                                        id="symbol"
                                        maxLength="1"
                                        value={this.state.dataObject.symbol || ''}
                                        caption={$.strings.lookups.symbol}
                                        onChange={(e) => SCC.handleUserInput(this, e)}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        onBlur={this.onOtherNameBlur}
                                    />
                                </div>
                            </div>
                        }
                        {
                            (this.props.lookupType + "" === "4") &&
                            <div className="p-grid">
                                <div className="p-col-12 p-lg-6 p-sm-12">
                                    <Input
                                        type="int"
                                        id="stat"
                                        maxLength="1"
                                        value={this.state.dataObject.stat || ''}
                                        caption={$.strings.lookups.daysNo}
                                        onChange={(e) => SCC.handleUserInput(this, e)}
                                        isRequired={true}
                                        formErrors={this.state.formErrors}
                                        onBlur={this.onOtherNameBlur}
                                    />
                                </div>
                            </div>
                        }
                        {
                            (this.props.lookupType + "" === "4" || this.props.lookupType + "" === "29" || this.props.lookupType + "" === "26") &&
                            <Fieldset legend={$.strings.lookups.showInWeb}>
                                <div className="p-grid">
                                    <div className="p-col-6 p-lg-6 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                                        <RadioButton
                                            inputId="rbYes" value={$.strings.yes} name="finalStepAction" checked={this.state.dataObject.classId}
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, classId: true } })}

                                        ></RadioButton>
                                    </div>
                                    <div className="p-col-6 p-lg-6 p-sm-6" style={{ alignSelf: 'flex-end' }}>
                                        <RadioButton
                                            inputId="rbNo" value={$.strings.no} name="finalStepAction" checked={!this.state.dataObject.classId}
                                            onChange={(e) => this.setState({ dataObject: { ...this.state.dataObject, classId: false } })}

                                        ></RadioButton>
                                    </div>
                                </div>
                            </Fieldset>
                        }
                    </Card>
                </Dialog>
            </div>
        );
    }

    onSave = async (e) => {
        this.save(e, true, false);
    };

    onSaveThenClose = async (e) => {
        this.save(e, true, true);
    };

    save = async (e, fromSave, closeAfterSave) => {
        //waiting progress
        this.setState({ loading: true });

        //validate
        if (!this.validateFields()) {
            this.setState({ loading: false, showErrors: true });
            return false;
        }

        let isSucceeded = true;
        let result = await this.sendData();

        if (result.status === 200) {
            if (result.data.success) {
                if (closeAfterSave) {
                    if (this.props.onAddSuccess) {
                        this.props.onAddSuccess(true);
                    }
                    this.setState({ visibleDialog: false, loading: false });
                } else {
                    Util.showSuccessMsg(this.messages);
                    if (fromSave) {
                        this.resetFields();
                    }
                    this.hash = Util.hashState(this.state.dataObject);
                }
            }
            else {
                isSucceeded = false;
                let msg = $.strings.operationFailed;
                if (result.data.errorText && result.data.errorText.length > 0) {
                    msg = result.data.errorText;
                    Util.showErrorMsg(this.messages, msg, false);
                }
            }
        } else {
            isSucceeded = false;
            let msg = $.strings.operationFailed;
            if (result.errorText && result.errorText.length > 0) {
                msg = result.errorText;
                Util.showErrorMsg(this.messages, msg, false);
            }
        }
        this.setState({ loading: false });
        return isSucceeded;
    };

    sendData = async () => {
        let user = 1;
        if (localStorage.getItem("useridHR") + "" !== "0") {
            user = Math.pow(2, parseInt(localStorage.getItem("useridHR"))) + Math.pow(2, 0);
        }
        
        let obj = {
            Pos: this.state.dataObject.pos,
            Stat: this.props.lookupType + "" === "4" ? this.state.dataObject.stat : 0,
            Users: user,
            UserInsert: parseInt(localStorage.getItem("useridHR")),
            UserUpdate: parseInt(localStorage.getItem("useridHR")),
            Code: ' ',
            Name: this.state.dataObject.name,
            EName: this.state.dataObject.eName,
            Type: this.props.lookupType,
            Class: this.state.dataObject.classId === true ? '1' : '0',
            Symbol: this.state.dataObject.symbol,
            Value: this.state.dataObject.value
        }

        let result
        let res = await Http.postMultipartAxios(Http.actions.MultiFl.urlPostSaveMultiFl, obj, 0);
        result = res;
        return result;
    };

    onDelete = async (e) => {
        if (this.state.dataObject.pos === undefined || this.state.dataObject.pos < 0) {
            this.setState({ loading: false })
            return false
        }
        Util.confirmDialog($.strings.lookups.deleteConfirm, 'pi pi-question-circle',
            async () => await this.doDelete(e),
            () => { }
        );
        return false
    }

    doDelete = async (e) => {
        this.setState({ loading: true })
        let obj = {
            type: this.props.lookupType,
            pos: this.state.dataObject.pos,
        }

        let res = await Http.getJsonAxios(Http.actions.MultiFl.urlGetDeleteMultiFl, obj, this.state.pageInfo.pageId);

        if (res.status === 200 && res.data.success) {
            Util.showSuccessMsg(this.messages)
            this.resetFields()
        }
        else {
            let msg = $.strings.operationFailed;
            if (res.data && res.data.errorText)
                msg = res.data.errorText;

            Util.showErrorMsg(this.messages, msg);
            this.setState({ loading: false })
        }
    }

    resetFields = () => {
        this.setState({
            dataObject: {
                pos: -1,
                stat: 0,
                users: 0,
                userInsert: localStorage.getItem("useridHR"),
                userUpdate: localStorage.getItem("useridHR"),
                Code: '',
                name: '',
                eName: '',
                type: this.props.lookupType,
                class: false,
                symbol: '',
                value: 0
            }
        }, () => { this.txtName.focus(); this.hash = Util.hashState(this.state.dataObject); });
    }

    show = async (pos) => {
        if (pos == null || pos === undefined || pos === -1) {
            // insert
            this.setState({
                visibleDialog: true,
                formErrors: {},
                dataObject: {
                    pos: -1,
                    stat: 0,
                    users: 0,
                    userInsert: localStorage.getItem("useridHR"),
                    userUpdate: localStorage.getItem("useridHR"),
                    code: '',
                    name: '',
                    eName: '',
                    type: this.props.lookupType,
                    class: false,
                    symbol: '',
                    value: 0
                }
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            })
        }
        else {
            // update
            await this.navigate(Util.navigationEnum.GETIT, false, pos);
            //this.setState({ formErrors: {}, visibleDialog: true });
        }
    }

    onNew = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSave(e, false),
                () => this.onNew(e, false)
            );
            return;
        }
        this.messages.clear();
        this.resetFields()
    }

    onClose = (e, checkChanging) => {
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged, 'pi pi-question-circle',
                async () => await this.onSaveThenClose(e, false),
                () => this.onClose(e, false)
            );
            return;
        }
        let doRefresh = checkChanging ? false : true // if fields changed (Yes, or no) do refresh, else nothing
        if (this.props.onAddClose) {
            this.props.onAddClose(doRefresh);
        }
        this.setState({ visibleDialog: false }, () => this.hash = Util.hashState(this.state.dataObject))
    }

    navigate = async (navigationType, checkChanging, specificId) => {
        //check fields changing
        if (checkChanging && this.hash !== Util.hashState(this.state.dataObject)) {
            Util.confirmDialog($.strings.dataChanged,
                'pi pi-question-circle',
                (e) => this.save(e, false, false),
                (e) => {
                    switch (navigationType) {
                        case Util.navigationEnum.PREVIOUS:
                            this.onPrevious(e, false);
                            break;
                        case Util.navigationEnum.NEXT:
                            this.onNext(e, false);
                            break;
                        case Util.navigationEnum.FIRST:
                            this.onFirst(e, false);
                            break;
                        case Util.navigationEnum.LAST:
                            this.onLast(e, false);
                            break;
                        default: {
                            break;
                        }
                    }
                });
            return;
        }
        this.setState({ loading: true });
        //determind the id
        let id = 0;
        if (
            navigationType === Util.navigationEnum.PREVIOUS ||
            navigationType === Util.navigationEnum.NEXT
        ) {
            if (this.state.dataObject.pos)
                id = this.state.dataObject.pos;
            else {
                if (navigationType === Util.navigationEnum.PREVIOUS)
                    navigationType = Util.navigationEnum.FIRST;
                else navigationType = Util.navigationEnum.LAST;
            }
        }
        if (navigationType === Util.navigationEnum.LAST) id = Util.integ.maxValue;
        if (navigationType === Util.navigationEnum.GETIT) id = specificId;

        let param = {
            type: parseInt(this.props.lookupType),
            pos: id,
        };

        let url = "";
        switch (navigationType) {
            case Util.navigationEnum.PREVIOUS:
                //url = Http.actions.MultiFl.urlGetPrevious;
                break;
            case Util.navigationEnum.NEXT:
                //url = Http.actions.Tasks.urlGetNext;
                break;
            case Util.navigationEnum.FIRST:
                //url = Http.actions.Tasks.urlGetFirst;
                break;
            case Util.navigationEnum.LAST:
                //url = Http.actions.Tasks.urlGetLast;
                break;
            case Util.navigationEnum.GETIT:
            default: {
                url = Http.actions.MultiFl.urlGetMultiFlByTypePos;
                break;
            }
        }

        let res = await Http.getJsonAxios(url, param, 0);
        let result = false;
        if (res.status === 200) {
            if (res.data.success) {
                result = true;
                if (res.data.multiFlList === null) {
                    if (navigationType === Util.navigationEnum.PREVIOUS)
                        Util.showInfoMsg(this.messages, $.strings.recordsBeginning);
                    if (navigationType === Util.navigationEnum.NEXT)
                        Util.showInfoMsg(this.messages, $.strings.recordsEnds);
                    this.setState({ loading: false });
                } else {

                    let obj;
                    if (res.data.dataObject && res.data.dataObject.length > 0) {
                        obj = res.data.dataObject[0];
                    }
                    this.display(obj);
                }
            }
        }
        if (!result) {
            Util.showInfoMsg(this.messages, $.strings.operationFailed);
            this.setState({ loading: false });
        }
    }
    display = (data) => {
        if (!data) {
            return;
        }
        if (this.messages)
            this.messages.clear();

        this.setState(
            {
                dataObject: {
                    ...this.state.dataObject,
                    pos: data.Pos,
                    stat: data.Stat,
                    users: data.Users,
                    userInsert: data.UserInsert,
                    userUpdate: data.UserUpdate,
                    code: data.Code,
                    name: data.Name,
                    eName: data.EName,
                    type: this.props.lookupType,
                    classId: data.Class + "" === "1" ? true : false,
                    symbol: data.Symbol,
                    value: data.Value
                },
                formErrors: {},
                visibleDialog: true,
                loading: false
            }, () => {
                this.hash = Util.hashState(this.state.dataObject)
                setTimeout(() => {
                    this.txtName.focus()
                }, 10);
            }
        );
    };

    setPageInfo = () => {
        let pageId
        let title
        switch (this.props.lookupType) {
            case "3": { // Jobs
                title = $.strings.lookups.jobsTitle;
                break
            }
            case "4": { // PaidVacTypes
                title = $.strings.lookups.paidVacTypesTitle;
                break
            }
            case "7": { // Branches
                title = $.strings.lookups.branchesTitle;
                break
            }
            case "8": { // Departments
                title = $.strings.lookups.departmentsTitle;
                break
            }
            case "11": { // ClockList
                title = $.strings.lookups.clockTitle;
                break
            }
            case "12": { // EmployeesClass
                title = $.strings.lookups.empClassificationsTitle;
                break
            }
            case "22": { // ShiftTransTypes
                title = $.strings.lookups.shiftTransTypesTitle;
                break
            }
            case "23": { // sectionsClass
                title = $.strings.lookups.sectionsClassTitle;
                break
            }
            case "25": { // Classifications
                title = $.strings.lookups.classificationTitle;
                break
            }
            case "26": { // InternalMemosTypes
                title = $.strings.lookups.internalMemosTypesTitle;
                break
            }
            case "29": { // Documents
                title = $.strings.lookups.documentsTitle;
                break
            }
            case "30": { // ShiftPeriodsTypes
                title = $.strings.lookups.shiftPeriodsTypesTitle;
                break
            }
            default: {
                title = "";
                break
            }
        }

        return { pageId: pageId, title: title };
    };
}